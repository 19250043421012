export class CodeClassLanguage {
    _codeId;
    _languageGuid;
    _languageId;
    _languageName;
    _text;
    _code;


    constructor(codeId, languageGuid, languageId, languageName, text, code) {
        if (typeof codeId["codeId"] !== 'undefined' || codeId["codeId"] !== null) {
            this._codeId = codeId["codeId"];
            this._languageGuid = codeId["languageGuid"];
            this._languageId = codeId["languageId"];
            this._languageName = codeId["languageName"];
            this._text = codeId["text"];
            this._code = codeId["code"];
        } else {
            this._codeId = codeId;
            this._languageGuid = languageGuid;
            this._languageId = languageId;
            this._languageName = languageName;
            this._text = text;
            this._code = code;
        }
    }

    get code() {
        return this._code;
    }

    get codeId() {
        return this._codeId;
    }

    get languageGuid() {
        return this._languageGuid;
    }

    get languageId() {
        return this._languageId;
    }

    get languageName() {
        return this._languageName;
    }

    get text() {
        return this._text;
    }
}