import React from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useMsal} from "@azure/msal-react";
import {useTranslation} from "react-i18next";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment-timezone/moment-timezone-utils";
import {
    DeleteCounts,
    GetCountsByFlowId,
    GetFlow,
    GetSite,
    IsCountDeletionAllowed
} from "../../utils/NastaApiClient";
import {Table, TableRow, TableCell, TableHead, TableBody, TableContainer, Tooltip, IconButton} from "@mui/material";

import './DeleteCountDialog.css';
import alertTriangle from '../../icons/alert-triangle_fill.svg';
import {Flow} from "../../dataClasses/Flow";
import Count from "../../dataClasses/Count";
import {Counting} from "../../dataClasses/Counting/Counting";
import ModalComponent from "../../widgets/ModalComponent/ModalComponent";
import Button from "@mui/material/Button";
import UploadingNotification from "../../widgets/UploadingNotification/UploadingNotification";

const withRouter = WrappedComponent => props => {
    const params = useParams();
    const {instance} = useMsal();
    const navigate = useNavigate();
    const {t} = useTranslation('deleteCount', 'common')

    return (
        <WrappedComponent
            t={t}
            {...props}
            {...{instance, /* other injected props */}}
            params={params}
            navigate={navigate}
        />
    );
};

class DeleteCountDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: moment(new Date()),
            endDate: moment(new Date()).tz(Intl.DateTimeFormat().resolvedOptions().timeZone),
            currentSite: undefined,
            currentFlow: undefined,

            currentCounts: undefined,
            currentDestinationId: undefined,
            /**
             *
             * @type {Counting[]}
             */
            countingsInSameDestination: null,
            /**
             *
             * @type {Counting[]}
             */
            countingsInSameDestinationAndTime: [],
            hasCountings: null,
            paginationOffset: 0,
            siteIsNull: false,
            countsInDateRange: [],
            deleteDialogOpen: false,
            isSubmitting: false,
            errors: [],
            deleteCompleteDialogOpen: false,
            deleteFailedDialogOpen: false,
            showErrorModal:false,
            loaded:false
        }

        this.genErrorTable=this.genErrorTable.bind(this);
    }

    async componentDidMount() {

        /**
         *
         * @type {Flow}
         */
        let currentFlow;


        /**
         *
         * @type {Count[]}
         */
        let currentCounts;
        if (typeof this.props.currentFlow !== 'undefined') {

            currentFlow = this.props.currentFlow;
        } else {
            if (typeof this.props.params.id !== 'undefined') {

                await GetFlow(parseInt(this.props.params.id)).then(async f => {
                    currentFlow = new Flow(f.data);
                    this.setState({currentFlow: currentFlow})

                    if (typeof this.props.currentCounts !== 'undefined') {
                        currentCounts = this.props.currentCounts;

                    } else {

                        if (typeof this.props.params.id !== 'undefined') {
                            await GetCountsByFlowId(currentFlow.flowId).then(counts => {
                                currentCounts = counts.map(c => {
                                    let tmpCount = new Count(c);
                                    tmpCount.periodBeginTime = moment(tmpCount.periodBeginTime);
                                    tmpCount.periodEndTime = moment(tmpCount.periodEndTime);
                                    return tmpCount
                                });
                            })
                        } else {
                            throw new Error("No currentCounts prop provided to DeleteCountDialog");
                        }
                    }


                })
            } else {
                throw new Error("No currentFlow prop provided to DeleteCountDialog");
            }
        }


        let tmpCurrentSite;

        if (currentFlow.site === null) {
            this.setState({siteIsNull: true})
        } else {

            await GetSite(currentFlow.site.siteId).then(async sites => {
                tmpCurrentSite = sites.data;

                this.setState({
                    currentSite: tmpCurrentSite,
                    currentDestinationId: (tmpCurrentSite.siteDestination.length>0?tmpCurrentSite.siteDestination[0].destination.destinationId:-1)
                }, async () => {

                    let countingsInSameDestination;
                    this.setState({
                        countingsInSameDestination: countingsInSameDestination,
                        currentCounts: currentCounts,
                        paginationOffset: 0,
                        loaded:true
                    });
                })
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {startDate, endDate, countingsInSameDestination} = this.state;


        if (startDate !== null && endDate !== null && countingsInSameDestination !== null
            && (!startDate.isSame(prevState.startDate) || !endDate.isSame(prevState.endDate))
        ) {
            /**
             *
             *
             * @type {Count[]}
             */
            let currentCounts = this.state.currentCounts;
            let countsInDateRange = [];

            currentCounts.forEach(count => {

                if (count.periodBeginTime.isSameOrAfter(this.state.startDate) && count.periodEndTime.isSameOrBefore(this.state.endDate)) {
                    countsInDateRange.push(count.countId);
                }
            });

            let hasCountings = false;
            let countingsInTime = [];

            this.setState({
                hasCountings: hasCountings,
                countingsInSameDestinationAndTime: countingsInTime,
                paginationOffset: 0,
                countsInDateRange: countsInDateRange
            })
        }
    }

    setPagination(page) {
        this.setState({paginationOffset: page})
    }

    async removeCounts() {


        if (this.state.countsInDateRange !== null && this.state.countsInDateRange.length > 0) {
            let result = await DeleteCounts(this.state.countsInDateRange);

            if (result.status === 204) {
                this.setState({deleteCompleteDialogOpen: true});

            } else {
                this.setState({deleteFailedDialogOpen: true});
            }
            this.setState({isSubmitting: false});
        }


    }

    genErrorTable(){
        let {t} = this.props;
        return (

            <TableContainer sx={{maxHeight:440}}>


            <Table stickyHeader>

                <TableHead>

                    <TableRow>
                        <TableCell>
                            <b>
                                {t('id')}
                            </b>
                        </TableCell>
                        <TableCell color={"main"}>
                            <b>
                                {t('start_time')}
                            </b>
                        </TableCell>
                        <TableCell color={"main"}>
                            <b>
                                {t('end_time')}
                            </b>
                        </TableCell>
                    </TableRow>


                </TableHead>
                <TableBody>

                    {this.state.countingsInSameDestinationAndTime.slice(
                        this.state.paginationOffset * 50,
                        (this.state.paginationOffset * 50) + 50)
                        .map(cc => {

                            return (<>
                                    <TableRow key={"countingsInSameDestination-"+cc.destinationId}>
                                        {typeof cc.destinationId!=='undefined' ?

                                            <TableCell>

                                                <Link to={"/counting/list/"+cc.destinationId}>
                                                    {cc.destinationId}
                                                </Link>

                                            </TableCell>
                                            :
                                            <TableCell>

                                                {cc.destinationId}
                                            </TableCell>
                                        }

                                        <TableCell color={"main"}>
                                            {cc.countingBeginTime && cc.countingBeginTime.format(process.env.REACT_APP_NASTA_DATE_FORMAT)}
                                        </TableCell>
                                        <TableCell color={"main"}>
                                            {cc.countingEndTime && cc.countingEndTime.format(process.env.REACT_APP_NASTA_DATE_FORMAT)}
                                        </TableCell>
                                    </TableRow>
                                </>
                            )
                        })}
                </TableBody>
            </Table>
            </TableContainer>
        )
    }


    render() {


        let {startDate, hasCountings, siteIsNull} = this.state;

        let {t} = this.props;

        return (<>

                <UploadingNotification showModal={this.state.loaded===false} />
                <UploadingNotification showModal={this.state.isSubmitting} errors={this.state.errors} type={"upload"}/>

                <ModalComponent
                    showModal={this.state.deleteCompleteDialogOpen}
                    title={t('delete_successful_title')}
                    content={t('delete_successful_content')+" "+this.state.countsInDateRange?.length+ " "+t('counts')}
                    modalType={"errorModal"}
                    onModalContinue={() => {
                        this.setState({deleteCompleteDialogOpen: false})
                        this.props.navigate(-1);
                    }}
                />

                <ModalComponent
                    showModal={this.state.deleteFailedDialogOpen}
                    title={t('delete_failed_title')}
                    content={t('delete_failed_content')}
                    modalType={"errorModal"}
                    onModalContinue={() => {
                        this.setState({deleteFailedDialogOpen: false})
                    }}
                />
                <ModalComponent
                    showModal={this.state.deleteDialogOpen}
                    title={t('delete_confirmation_title')}
                    content={t('delete_confirmation_content')+" "+ this.state.countsInDateRange?.length +" "+t('counts')}
                    modalType={"submitModal"}
                    onModalCancel={() => {
                        this.setState({deleteDialogOpen: false})
                    }}
                    onModalContinue={async () => {

                        this.setState({isSubmitting: true}, async () => {
                            let result = await this.removeCounts();
                            this.setState({deleteDialogOpen: false, isSubmitting: false})
                        })


                    }}

                />
                {siteIsNull ? <>
                    <ModalComponent title={t("site_is_null_title")}
                                    showModal={siteIsNull}
                                    modalType={"errorModal"}
                                    content={t("site_is_null_content")}
                                    onModalCancel={() => this.props.navigate(-1)}
                                    onModalContinue={() => this.props.navigate(-1)}

                    />
                </> : <>

                    <p>{t('select_period_from_which_to_delete_flow_counts_start')+" "}<b>{this.state.currentFlow?.flowName}</b>{" "+t('select_period_from_which_to_delete_flow_counts_end')}</p>


                    <div>
                    <label htmlFor={"startTime"}>{t('start_time')}</label><br/>
                    <LocalizationProvider locale={"fi-FI"} dateAdapter={AdapterMoment}>
                        <DateTimePicker ampm={false}
                                        id={"startTime"} format={process.env.REACT_APP_NASTA_DATE_FORMAT}
                                        onChange={(date) => {

                                            if(date.isValid()){
                                                this.setState({startDate: date})
                                            }

                                        }}

                                        slotProps={{
                                            textField:{
                                                inputProps:{
                                                    "id":"startTime"
                                                }
                                            }
                                        }}


                        />

                    </LocalizationProvider>

                    </div>

                    <div>
                    <label htmlFor={"startTime"}>{t('end_time')}</label><br/>
                    <LocalizationProvider locale={"fi-FI"} dateAdapter={AdapterMoment}>
                        <DateTimePicker ampm={false}


                                        id={"endDate"} format={process.env.REACT_APP_NASTA_DATE_FORMAT}
                                        onChange={(date) => {
                                            if(date.isValid()){
                                                this.setState({endDate: date})
                                            }
                                        }}
                                        value={this.state.endDate !== null ? this.state.endDate : moment(new Date()).tz(Intl.DateTimeFormat().resolvedOptions().timeZone)}

                                        slotProps={{
                                            textField:{
                                                inputProps:{
                                                    "id":"endTime"
                                                }
                                            }
                                        }}
                        />

                    </LocalizationProvider>
                    </div>

                    <ModalComponent showModal={this.state.showErrorModal}
                                    title={t("count_used_in_countings")}
                                    content={this.genErrorTable()}
                                    modalType={"submitModal"}
                                    onModalContinue={() => {
                                        this.setState({showErrorModal: false});
                                    }}
                                    onModalCancel={() => {
                                        this.setState({showErrorModal: false})
                                    }}
                    />

                    {
                        this.state.countingsInSameDestinationAndTime.length>0 &&
                        <>



                            <Tooltip title={t('count_used_in_countings')}>
                                <IconButton onClick={()=>this.setState({showErrorModal: true})}>
                                    <img alt={""} src={alertTriangle}
                                         className={"dialog-warning-color"}/>

                                </IconButton>
                            </Tooltip>
                        </>

                    }
                    {
                        <Button size={"small"} variant={"contained"} disabled={localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser" || localStorage.getItem("user-role") === "Nasta.Recorder" ? false : true} className={"float-right"}
                                onClick={async () => {
                                    const response = await IsCountDeletionAllowed(this.state.currentFlow?.flowId, this.state.startDate.toISOString(), this.state.endDate.toISOString());
                                    if(response.data.length>0){
                                        this.setState({countingsInSameDestinationAndTime:response.data.map(destination => {return {
                                            destinationId: destination,
                                            countingBeginTime: this.state.startDate,
                                            countingEndTime: this.state.endDate
                                        }
                                        }), showErrorModal:true});
                                    }else{
                                        this.setState({deleteDialogOpen: true})
                                    }
                                }}>

                            <img style={{filter: "invert(100%)", height: "1.5vh"}}
                                 src="/img/icon/JHT design system/bin_fill.svg"
                                 alt={t("common:delete_button")}/>&nbsp;

                            {t('common:delete_button')}</Button>
                    }
                </>}
            </>
        )
    }
}

export default withRouter(DeleteCountDialog)