import React, { useState } from "react";
import {withTranslation} from "react-i18next";
import "./DestinationToolbar.css"
import Tooltip from '@mui/material/Tooltip';
import Button from "@mui/material/Button";

class DestinationToolbar extends React.Component{
    constructor(props) {
        super(props);
        this.state={}
    }

    render(){
        let {t}=this.props;
        return (
            <>
                <div className="button-container">
                    <Tooltip placement={"top"} title={ this.props.t("without_dependencies_button_tooltip")}>

                    <Button size={"small"}  variant={"contained"}
                        className=" buttons"
                        onClick={() => {this.props.addPlain()}}
                        data-tooltip-id={"tooltip-"+ this.props.t("without_dependencies_button_tooltip")}
                        data-tooltip-html={this.props.t("without_dependencies_button_tooltip")}
                        data-tooltip-delay-hide={1000}>
                            {t("without_dependencies_button")}
                    </Button>
                    </Tooltip>
                    {/*<Tooltip style={{zIndex:50000, fontSize: 'small'}} noArrow  id={"tooltip-"+ this.props.t("without_dependencies_button_tooltip")} />*/}
                </div>
            </>
        )
    }
}
export default withTranslation("destinationToolbar")(DestinationToolbar)