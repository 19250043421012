import { createSlice } from '@reduxjs/toolkit'

export const placeSelector = createSlice({
  name: 'placeSelector',
  initialState: {
    maskedDestinations: [],
    pageLoadIndicatorVisible: false,
  },
  reducers: {
 
    updateMaskedDestinations: (state, action) => {
        state.maskedDestinations = action.payload;
    },
    changePageLoadIndicatorStatus: (state, action) => {
      state.pageLoadIndicatorVisible = action.payload;
  },
  },
})

// Action creators are generated for each case reducer function
export const {updateMaskedDestinations, changePageLoadIndicatorStatus} = placeSelector.actions

export default placeSelector.reducer