import React from "react";
import i18next from "i18next";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useMsal} from "@azure/msal-react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import { useSelector, useDispatch, useStore} from 'react-redux';
import * as applicationCommonsSelector from "./ApplicationCommonsSelector";

const withProps = WrappedComponent => props => {
    const {instance} = useMsal();
    const {t} = useTranslation('common');
    const location = useLocation();
    const dispatch = useDispatch();
    const storeHelper = useStore();

    return (
        <WrappedComponent
            t={t}
            {...props}
            {...{instance, location/* other injected props */}}
            dispatch={dispatch}
            storeHelper={storeHelper}
        />
    );
};
 class LanguageSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false
        }
        this.changeLang = this.changeLang.bind(this);
    }

    componentDidMount() {
        if(i18next.language !== this.props.storeHelper.getState().applicationCommonsSelector.currentLanguage) {
            i18next.changeLanguage(this.props.storeHelper.getState().applicationCommonsSelector.currentLanguage);
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(i18next.language !== this.props.storeHelper.getState().applicationCommonsSelector.currentLanguage) {
            i18next.changeLanguage(this.props.storeHelper.getState().applicationCommonsSelector.currentLanguage);
        }
    }

    changeLang(event) {
        i18next.changeLanguage(event.target.value);
        this.props.dispatch(applicationCommonsSelector.changeLanguage(event.target.value));
    }

    render() {
        let {t} = this.props;
        return (
            <>
                <FormControl>
                    <InputLabel htmlFor="demo-simple-select-label">{t('language')}</InputLabel>
                    <Select
                        size={"small"}
                        sx={{backgroundColor:"primary"}}
                        value={this.props.storeHelper.getState().applicationCommonsSelector.currentLanguage}

                        inputProps={{
                            "id":"demo-simple-select-label"
                        }}

                        onChange={this.changeLang}
                    >
                        <MenuItem aria-label={"Suomi"} id={"fi"} value={"fi"}>Suomi</MenuItem>
                        <MenuItem aria-label={"Svenska"} id={"se"} value={"se"}>Svenska</MenuItem>
                        <MenuItem aria-label={"English"} id={"en"} value={"en"}>English</MenuItem>
                    </Select>
                </FormControl>
            </>
        )
    }
}

export default withProps(LanguageSelector)