import React from "react";
import {withTranslation} from "react-i18next";


import './ValidatedInputField.css';
import {IconButton, TextField, Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";


class ValidatedInputField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hasBeenSelected: false,
            isValid: (this.checkValidity(this.props.value)),
            cursorPos:{ref: React.createRef(), start: 0, end: 0}
        }

        const requiredFields = ["fieldName", "tooltip", "required", "type", "fieldId", "validatorFunction", "minLength", "maxLength", "name", "value", "validationMode"];
        let missingFields = requiredFields.filter(x => typeof props[x] === 'undefined');
        if (missingFields.length > 0) {
            throw new Error("ValidatedInputField missing required fields [ " + missingFields + " ] of [ " + requiredFields+" ]");
        }

        const validationModes=["always", "onSelect", "never"];
        if(!validationModes.includes(this.props.validationMode)){
            throw new Error("Invalid validationMode "+this.props.validationMode+", valid validationModes are [ "+validationModes+" ]");
        }

        this.checkValidity=this.checkValidity.bind(this);

    }

    componentDidMount() {
        if(typeof this.props.onFieldValidityChanged !== 'undefined'){
            this.props.onFieldValidityChanged(this.state.isValid);
        }

        let isValid=this.checkValidity(this.props.value);
        if(isValid){
            if(typeof this.props.onFieldValidityChanged!=='undefined'){
                this.props.onFieldValidityChanged(isValid);
            }

            this.setState({isValid:isValid})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.aha===1){
            this.state.cursorPos.ref.current.setSelectionRange(this.state.cursorPos.start,this.state.cursorPos.start);
            setTimeout(()=>{this.setState({aha:0})}, 50)
        }

    }


    onFieldValueChanged(event, validatorFunction) {

        const caretStart = event.target.selectionStart;
        const caretEnd = event.target.selectionEnd;



        this.setState({isValid: (this.props.validationMode==='never'?true:this.checkValidity(event.target.value))});

        if (typeof this.props.onFieldValueChanged !== 'undefined') {
            let v = event.target.value;

            this.props.onFieldValueChanged(v.trim().length!==0 ? event.target.value.replace(/ +(?= )/g,''): '');


            if (typeof this.props.onFieldValidityChanged !== 'undefined') {
                this.props.onFieldValidityChanged((this.props.validationMode==='never'?true:this.checkValidity(event.target.value)));

            } else {
                console.warn("onFieldValidityChanged not set, not updating parent of validity status...")
            }

        } else {
            console.warn("onFieldValueChanged not set, not updating parent component of field value...")
        }

        this.setState({cursorPos: {ref:this.state.cursorPos.ref, start: caretStart, end:caretEnd}, aha:1});
    }

    checkValidity(value){
        let {minLength, maxLength, validatorFunction} = this.props;
        let isValid=false;
        if (value?.toString().length >= minLength && value?.toString().length <= maxLength) {
            isValid = validatorFunction(value);
        }
        return isValid;
    }


    render() {
        let {t} = this.props;
        let {
            required,
            fieldName,
            tooltip,
            type,
            fieldId,
            validatorFunction,
            maxLength,
            name,
            value,
            validationMode,
            validationErrorMessage,
        } = this.props;

        let showAsInvalid=true;
        switch(validationMode){
            case "always":
                showAsInvalid=!this.state.isValid;
                break;
            case "onSelect":
                if(this.state.hasBeenSelected){
                    showAsInvalid=!this.state.isValid;
                }else{
                    showAsInvalid=false;
                }
                break;
            case "never":
                showAsInvalid=false;
                break;
            default:
                console.log('Default case in switch statement reached in ValidatedInputField.js, this should not happen...');
                break;
        }
        return (
            <>
                <div className="form-group">
                    <label  htmlFor={fieldId}>{fieldName + (required ? ' *' : ' ')}

                        {(typeof tooltip!=='undefined' && t(tooltip)!==null && t(tooltip)!=='') &&

                        <Tooltip title={t(tooltip)}>
                            <IconButton>
                                <InfoIcon/>

                            </IconButton>
                        </Tooltip>
                        }
                    </label>


                    <TextField
                        required={this.props.required}
                        error={showAsInvalid}
                        multiline={this.props.multiline}
                        helperText={showAsInvalid? validationErrorMessage : ''}
                        fullWidth
                        hiddenLabel
                        variant={"outlined"}
                        size={"small"}

                        name={name}
                        type={type}
                        id={fieldId}


                        aria-invalid={showAsInvalid}
                        disabled={(typeof this.props.disabled !== 'undefined'?this.props.disabled:false)}
                        onSelect={() => this.setState({hasBeenSelected: true})}
                        onChange={(e) => this.onFieldValueChanged(e, validatorFunction)}
                        value={value}

                        inputProps={{"aria-required":this.props.required, maxLength: maxLength, ref: this.state.cursorPos.ref}}
                    />
                </div>
            </>
        )
    }
}

export default withTranslation("validatedInputField", 'common')(ValidatedInputField)