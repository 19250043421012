import React from "react";
import {withTranslation} from "react-i18next";
import './MultiChoiceSearchDropdown.css';
import {log} from "ol/console";
import Stack from "@mui/material/Stack";
import {Autocomplete, Chip, IconButton, TextField, Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export function validateChoice(validationTarget) {
    const requiredValues = ["id", "name", "title"];
    let missingValues = [];


    if (typeof validationTarget === 'undefined') {
        return false;
    }

    for (let requiredValue of requiredValues) {
        if (typeof validationTarget[requiredValue] === 'undefined'
            || validationTarget[requiredValue] === null
            || validationTarget[requiredValue].length < 1) {
            missingValues.push(requiredValue);
        }
    }
    if (missingValues.length > 0) {
        throw new Error("Choice object lacks propert" + (missingValues.length > 1 ? "ies " : "y ") + missingValues + ", has: " + JSON.stringify(validationTarget));
    }


    if (isNaN(validationTarget.id)) {
        throw new Error("provided id \"" + validationTarget.id.toString() + "\" not applicable to integer")
    }

    if (typeof validationTarget.name !== 'string') {
        throw new Error("MultiChoiceSearchDropdown item name not a string");
    }

    if (typeof validationTarget.title !== 'string') {
        throw new Error("MultiChoiceSearchDropdown item title not a string");
    }
    return true
}

class MultiChoiceSearchDropdown extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            isValid: false,
            hasBeenSelected: false,
            currentSearchValue: '',
            lastTargetedValue: "",
            choices: [],
            isShowing: false,
            maxChoices: 0,
            minChoices: 0,
            choicesCleared: null,
            minChoicesFixed: false
        }

        if (typeof this.props.required === 'undefined') {
            throw new Error("MultiChoiceSearchDropdown missing field [required]")
        }

        if (typeof props.maxChoices !== 'undefined' && props.maxChoices !== null) {
            this.state["maxChoices"] = props.maxChoices;
        }
        if (typeof props.inputData === 'undefined') {
            throw new Error("Required field inputData is undefined")
        }
        for (let choice of props.inputData) {
            if (choice.name === null) choice.name = "unkonwn";
            validateChoice(choice);
        }

        if (typeof this.props.validationMode === 'undefined') {
            throw new Error("Required field validationMode is undefined");
        }

        this.setChoicesByDefaultSelected = this.setChoicesByDefaultSelected.bind(this);
        this.checkKeyPressed = this.checkKeyPressed.bind(this);
        this.clearChoices = this.clearChoices.bind(this);
    }

    componentDidMount() {
        this.setChoicesByDefaultSelected();
    }

    clearChoices() {
        console.log('Add clear choices functionality');
    }


    setChoicesByDefaultSelected() {
        if (typeof this.props.defaultSelected !== 'undefined' && this.props.defaultSelected !== null) {
            let tmpChoices = [];
            for (let choice of this.props.inputData) {

                for (let defaultSelectName of this.props.defaultSelected) {
                    if (isNaN(defaultSelectName)) {
                        if (typeof defaultSelectName !== 'undefined') {
                            if (choice.id === defaultSelectName.id) {
                                tmpChoices.push(choice);
                            }
                        }

                    }

                    if (!isNaN(defaultSelectName) && choice.name === defaultSelectName) {

                        tmpChoices.push(choice);
                    }
                }

            }
            this.setState({choices: tmpChoices});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let shouldUpdate = false;

        if(typeof this.props.minChoicesFixed!=='undefined' && this.props.minChoicesFixed!==this.state.minChoicesFixed){
            this.setState({minChoicesFixed:this.props.minChoicesFixed})

        }
        if (typeof this.props.choicesCleared !== 'undefined' && this.props.choicesCleared !== null) {

            if (this.state.choicesCleared !== this.props.choicesCleared) {
                this.setState({choicesCleared: this.props.choicesCleared, choices: []}, () => {
                    this.props.onChoicesChange(this.state.choices);
                });
            }

        }

        if (typeof this.props.choices !== 'undefined') {

            let choices = this.props.choices;


            if (!isNaN(choices)) {
                if (typeof choices.length !== 'undefined' && choices.length === 0 && this.state.choices.length !== 0) {
                    shouldUpdate = true;
                } else if (choices === 0 && this.state.choices.length > 0) {
                    this.setState({choices: []});
                    this.sendChoices([]);
                }
            } else {
                if (choices.length !== this.state.choices.length) {
                    shouldUpdate = true;
                } else {
                    for (const choice of this.props.choices) {
                        if (!this.state.choices.find(x => x.id === choice.id)) {
                            shouldUpdate = true;
                            break;
                        }
                    }
                }
            }
        }


        if (shouldUpdate) {
            this.setState({choices: this.props.choices});

        }

        const {minChoices, maxChoices} = this.props;
        const cLen = this.state.choices.length;
        const isValid = (cLen >= minChoices && cLen <= maxChoices);
        if (prevState.isValid !== isValid) {
            this.setState({isValid: isValid})
            this.sendValidity(isValid);
        }


    }


    checkKeyPressed(event) {

        if (event.key === "Enter") {
            this.autofillFormSelectHandler(this.props.inputData.find(x => parseInt(x.id) === event.target.value))
            document.removeEventListener("keyup", this.checkKeyPressed);
        }
        if (event.key === "Escape") {
            this.setState({isShowing: false})
            document.removeEventListener("keyup", this.checkKeyPressed);
        }

        if (event.key === "Tab") {
            let max = Math.max.apply(Math, this.props.inputData.map(function (o) {
                return o.id
            }));
            if (max === parseInt(this.state.lastTargetedValue)) {
                this.setState({isShowing: false})
                document.removeEventListener("keyup", this.checkKeyPressed);
            }
        }


        if (event.shiftKey && event.key === "Tab") {
            let min = Math.min.apply(Math, this.props.inputData.map(function (o) {
                return o.id
            }));
            if (min === parseInt(this.state.lastTargetedValue) || this.state.lastTargetedValue.length === 0) {
                this.setState({isShowing: false})
                document.removeEventListener("keydown", this.checkKeyPressed);
                document.removeEventListener("keyup", this.checkKeyPressed);
            }

        }

        this.setState({lastTargetedValue: event.target.value}, () => {
            console.log(this.state.lastTargetedValue)
        });
    }

    sendChoices(choices) {
        if (typeof this.props.onChoicesChange === 'undefined') {
            console.warn("onChoicesChange is undefined, not updating parent object with MultiChoiceSearchDropdown data");
        } else {
            this.props.onChoicesChange(choices);
        }
    }

    sendValidity(newValidity) {
        if (typeof this.props.onValidityChange === 'undefined') {
            console.warn("onValidityChange is undefined, not updating parent object with MultiChoiceSearchDropdown data");
        } else {
            this.props.onValidityChange(newValidity);
        }

    }


    render() {
        const {t, tooltip} = this.props;

        let showAsInvalid = true;
        switch (this.props.validationMode) {
            case "always":
                showAsInvalid = !this.state.isValid;
                break;
            case "onSelect":
                if (this.state.hasBeenSelected) {
                    showAsInvalid = !this.state.isValid;
                } else {
                    showAsInvalid = false;
                }
                break;
            case "never":
                showAsInvalid = false;
                break;
            default:
                console.warn("Invalid validationMode property on MultiChoiceSearchDropdown component")
                break;
        }

        return (<>
                <Stack>

                    <label className="form-label"
                           htmlFor={this.props.title}> {this.props.title + (this.props.required ? ' *' : '')}

                        {(typeof tooltip!=='undefined' && t(tooltip)!==null && t(tooltip)!=='') &&
                        <Tooltip title={t(tooltip)}>
                            <IconButton>
                                <InfoIcon/>

                            </IconButton>
                        </Tooltip>
                        }
                    </label>

                    <Autocomplete multiple

                                  id={this.props.title}
                                  options={this.props.inputData}
                                  getOptionDisabled={(option) => this.state.choices.length >= this.props.maxChoices || typeof option.inUse !== 'undefined' ? option.inUse ? false : true : false}
                                  getOptionLabel={(option) => option?.title + " " + option?.name}
                                  value={this.state.choices}

                                  limitTags={this.state.choices.length}

                                  isOptionEqualToValue={(o, v) => v.id === o.id}
                                  defaultValue={typeof this.props.defaultSelected !== 'undefined' && !this.props.defaultSelected.some(x => typeof x === 'undefined' || typeof x === 'boolean') ? this.props.defaultSelected : []}
                                  onChange={(e, v, r, d) => {

                                      let tmpChoices = v;

                                      if(this.state.minChoicesFixed===true){
                                          if (v.length<this.props.minChoices) {
                                              tmpChoices = this.state.choices.slice(0, this.props.minChoices);
                                          }
                                      }


                                      if(typeof this.props.disabledChoices!=='undefined'){
                                          if(this.props.disabledChoices.includes(parseInt(d.option.id))){
                                              tmpChoices.push(d.option);
                                          }
                                      }


                                      this.setState({choices: tmpChoices}, () => {
                                          this.sendChoices(this.state.choices);
                                      });


                                  }}

                                  renderTags={(tagValue, getTagProps) =>
                                      tagValue.map((option, index) => {
                                          return (
                                              <Chip
                                                  label={option.title + " " + option.name}
                                                  {...getTagProps({index})}
                                                  disabled={
                                                  (typeof this.props.disabledChoices!=='undefined' && this.props.disabledChoices.includes(parseInt(option.id)))
                                                      ?true
                                                  :this.props.disabled
                                                      ? this.props.disabled
                                                      : this.state.minChoicesFixed===true
                                                          ?(index < this.props.minChoices && this.state.choices.length===this.props.minChoices)
                                                          :false

                                              }
                                              />
                                          )
                                      })
                                  }
                                  disabled={typeof this.props.active !== 'undefined' ? !this.props.active : this.props.disabled}
                                  disableClearable={(typeof this.props.disabledChoices!=='undefined'?this.props.disabledChoices.length>0:false)}
                                  renderInput={(params) => (
                                      <TextField
                                          hiddenLabel
                                          {...params}
                                          variant="outlined"

                                          required={this.props.required}
                                          aria-invalid={!this.state.isValid}

                                      />
                                  )}

                    />

                </Stack>
                {/**this.props.active ? active : active **/}

            </>
        )
    }
}

export default withTranslation("multiChoiceDropdown")(MultiChoiceSearchDropdown)