import React from "react";
import {useTranslation} from "react-i18next";
import {
    Box,
    Modal,
    Button,
    Typography,
    useTheme,
   Divider,
} from "@mui/material";
import {useNavigate, Link} from "react-router-dom";

const withRouter = WrappedComponent => props => {

    const {t} = useTranslation('errorDialog', 'common');
    const navigate = useNavigate();

    const theme = useTheme();
    return (
        <WrappedComponent
            t={t}
            {...props}
            theme={theme}
            navigate={navigate}
        />
    );
};


class CountingTimePeriodErrorDialog extends React.Component {
    selectedModalType = undefined;

    constructor(props) {
        super(props);
        this.state = {
            message: props.message,
            ruleId: props.ruleId
        }
    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.message !== this.props.message){
            this.setState({message: this.props.message});
        }
        if(prevProps.ruleId !== this.props.ruleId){
            this.setState({ruleId: this.props.ruleId});
        }
    }

    render() {
        const {t} = this.props
        return (
            <>

                <Modal
                    open={this.props.showModal}
                    onClose={() => this.props.onModalCancel()}
                    aria-labelledby={this.props.title}
                    aria-describedby={this.props.content}>
                    <Box className={"modal-box-container"} style={{width:'1000px'}}>

                        <Typography variant={"h6"}>{t("errorDialog:title")}</Typography>

                        <Divider/>

                        <Typography variant="h5" gutterBottom>
                            {this.state.message} 
                        </Typography>
                        {t("errorDialog:countingRule_text")}
                        {typeof this.state.ruleId !== 'undefined' ?
                                <Link to={"/countingRule/view/" + this.state.ruleId}>
                                    <td className="c-hand"> {this.state.ruleId}</td>
                                </Link> : <td></td>
                            } 
                        <br/>
                            
                        <Button size={"small"} variant={"contained"} className="float-right"
                                onClick={() => this.props.onModalCancel()}>{t('errorDialog:close_button_text')}
                        </Button>
                    </Box>
                </Modal>


            </>
        )
    }
}

export default withRouter(CountingTimePeriodErrorDialog)