import React from "react";
import {withTranslation} from "react-i18next";
import {Box, Modal, Button} from "@mui/material";

import './UploadingNotification.css';
import {PulseLoader} from "react-spinners";

class UploadingNotification extends React.Component {
    selectedModalType = undefined;

    constructor(props) {
        super(props);
        this.state = {
            manuallyClosed: false,
            errors: []
        }
    }

    componentDidMount() {
        if (typeof this.props.errors !== 'undefined') {
            if (Array.isArray(this.props.errors)) {
                this.setState({errors: this.props.errors})
            }else{
                console.warn("UploadingNotification property errors not an array");
            }
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (typeof this.props.errors !== 'undefined') {

            if (Array.isArray(this.props.errors)) {
                for (const error of this.props.errors) {
                    if (this.state.errors.some(x => x !== error)) {
                        this.setState({errors: this.props.errors})
                        break;
                    }
                }
            } else {
                console.warn("UploadingNotification property errors not an array");
            }

        }
    }

    render() {
        const {t} = this.props;
        const {manuallyClosed, errors} = this.state;

        let hasErrors = (errors.length > 0)


        return (
            <>

                <Modal
                    open={(manuallyClosed === false && !hasErrors ? this.props.showModal : !manuallyClosed)}
                    onClose={() => typeof this.props.onModalCancel !== 'undefined' ? this.props.onModalCancel() : () => {
                    }}
                    aria-labelledby={this.props.title}
                    aria-describedby={this.props.content}>
                    <Box textAlign={"center"}
                         className={"modal-box" + (hasErrors ? '' : '-transparent') + "-container"}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                            { !hasErrors && <>


                            <label style={{color: (hasErrors ? "" : "white")}}>
                                {this.props.type === "upload" ? t("uploading") : t("UploadingNotification:downloading")}
                            </label>&nbsp;&nbsp;
                                <PulseLoader speedMultiplier={0.5} color={(hasErrors ? "" : "white")}/>

                            </>}
                        </div>

                        {hasErrors &&

                            <ul>
                                {errors.map(e => {
                                    return (
                                        <li>
                                            {e}
                                        </li>
                                    )

                                })}

                            </ul>

                        }


                        {(this.props.type !== "upload" || hasErrors) &&
                            <Button sx={{marginTop: "2em"}} size={"small"} variant={"contained"} className=""
                                    onClick={() => {
                                        typeof this.props.onModalCancel !== 'undefined'
                                            ? this.props.onModalCancel()
                                            : this.setState({manuallyClosed: true})

                                    }}>{t('common:cancel')}
                            </Button>
                        }

                    </Box>
                </Modal>


            </>
        )
    }
}

export default withTranslation("UploadingNotification")(UploadingNotification)