import React from "react";
import {useTranslation} from "react-i18next";

const withRouter = WrappedComponent => props => {
    const {t} = useTranslation('multiViewHandler', 'common')

    return (
        <WrappedComponent
            t={t}
            {...props}

        />
    );
};

class TranslatedTitleHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let {t}=this.props;
        return (
            <div className="header-grid">
                <h1 className="page-name-cell">{t(this.props.pageHeader)}</h1>
            </div>
        )
    }
}

export default withRouter(TranslatedTitleHeader)