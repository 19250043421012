import React from "react";
import {useMsal} from "@azure/msal-react";
import {useTranslation} from "react-i18next";

import './SearchBox.css';
import {Link, useLocation} from "react-router-dom";
import moment from "moment-timezone/moment-timezone-utils";
import {getCodeText} from "../../utils/CodeHelpers";
import {useStore, useDispatch} from "react-redux";
import {IconButton, InputAdornment, TextField, Button} from "@mui/material";

import * as destinationsFilterSelector from '../../mapViewOl/destinationsFilterSelector';
import * as sitesFilterSelector from '../../mapViewOl/siteFilterSelector';

import eraser from '../../icons/Property 1=Line.svg';



const withProps = WrappedComponent => props => {
    const {instance} = useMsal();
    const {t} = useTranslation('searchBox', 'common');
    const storeHelper = useStore();
    const dispatch = useDispatch();
    const location = useLocation();

    return (
        <WrappedComponent
            t={t}
            {...props}
            storeHelper={storeHelper}
            location={location}
            dispatch={dispatch}
            {...{instance, /* other injected props */}}

        />
    );
};

export function validateItem(validationTarget, searchableTextFields) {
    let missingValues = [];


    if (typeof validationTarget === 'undefined') {
        return false;
    }

    for (let requiredValue of searchableTextFields) {
        if (typeof validationTarget[requiredValue] === 'undefined') {
            missingValues.push(requiredValue);
        }
    }
    /*    if (missingValues.length > 0) {
            throw new Error("SearchBox Item object lacks propert" + (missingValues.length > 1 ? "ies " : "y ") + missingValues + ", has: " + JSON.stringify(validationTarget));
        }*/

    return true
}

class SearchBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentSearchValue: null,
            matchingValues: {},
            hasBeenSelected: false,
            dateStore: {},
            matchingValuesLength:null,
            isSearching:false
        }

        this.GeneratedMatchingValues = this.GeneratedMatchingValues.bind(this);
        this.updateCurrentSearchValue = this.updateCurrentSearchValue.bind(this);
        this.checkKeyPressed = this.checkKeyPressed.bind(this);
        this.searcheroo=this.searcheroo.bind(this);
    }


    componentDidMount() {
        if (typeof this.props.items === 'undefined') {
            console.warn("No items given to SearchBox, will not yield results");
        } else {
            if (this.props.items.length === 0) {
                console.warn("Provided property items for SearchBox is empty, will not yield results");
            }
        }

        if (typeof this.props.initialSearchValue !== 'undefined') {
            this.setState({currentSearchValue: this.props.initialSearchValue})
        }


        let it = this.props.items;
        let da = this.props.dateAttrFields

        let tmpDateStore = {};
        if (typeof this.props.dateAttrFields !== 'undefined' && typeof this.props.items !== 'undefined') {
            for (let i = 0; i < this.props.items.length; i++) {
                tmpDateStore[it[i][this.props.idAttrField]] = {}

                for (let j = 0; j < da.length; j++) {
                    if (typeof it[i][da[j]] !== 'undefined') {
                        tmpDateStore[it[i][this.props.idAttrField]][da[j]] = moment(it[i][da[j]])
                            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                            .format(process.env.REACT_APP_NASTA_DATE_FORMAT).toString();

                    }
                }
            }
        }

        this.setState({dateStore: tmpDateStore})
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {

            if(!this.state.isSearching && this.state.currentSearchValue!==prevState.currentSearchValue ){
                this.setState({isSearching:true}, ()=>{
                    setTimeout(async ()=>{

                        await this.searcheroo();
                        this.setState({isSearching:false})
                    }, 1000)

                })

            }

        }


        async searcheroo(){
            let matchingValuesLength=Object.keys(this.state.matchingValues).length;
            let tmpMatchValues = {};
            let tmpSearchableFields = [];

            if (typeof this.props.baseLinkUrl === 'undefined') {
                console.warn("SearchBox baseLinkUrl not defined, can't generate links");
            }
            if (typeof this.props.idAttrField === 'undefined') {
                console.warn("SearchBox idAttrField not defined, can't generate links");
            }
            if (typeof this.props.searchableFields === 'undefined') {
                console.warn("SearchBox searchableFields not set, will not yield results")
            } else {
                tmpSearchableFields = this.props.searchableFields;
            }
            if (typeof this.props.items !== 'undefined') {
                /*            for (const item of this.props.items) {
                                validateItem(item, tmpSearchableFields);
                            }*/

                if (this.state.currentSearchValue !== null && this.state.currentSearchValue.length > 0) {
                    for (const item of this.props.items) {
                        for (const searchableField of tmpSearchableFields) {
                            let isDate = false;
                            let isCustomRule = false;
                            let isIndirectAttr = false;
                            let isCombinedField = false;

                            if (typeof item[searchableField] !== 'undefined') {
                                let dataCorrectedSearchField = searchableField;
                                if (item[searchableField] !== null) {
                                    if(typeof this.props.combinedFields !== 'undefined'){


                                        for (let i = 0; i < this.props.combinedFields.length; i++) {

                                            if(searchableField === this.props.combinedFields[i][0]){

                                                for (const combinedField of this.props.combinedFields[i]) {
                                                    dataCorrectedSearchField+=item[combinedField].toString()
                                                }
                                                isCombinedField=true;
                                                break;
                                            }

                                        }
                                    }
                                    if (typeof this.props.customSearchRuleFields !== 'undefined' ) {


                                        for (let i = 0; i < this.props.customSearchRuleFields.length; i++) {
                                            if (searchableField === this.props.customSearchRuleFields[i][0]) {
                                                // [val, testFuncReturningTrueValue]
                                                dataCorrectedSearchField = this.props.customSearchRuleFields[i][1](item[this.props.customSearchRuleFields[i][0]]);

                                                isCustomRule = true;
                                                break;

                                            }
                                        }


                                    }

                                }

                                if (typeof this.props.indirectAttrFields !== 'undefined') {


                                    for (let i = 0; i < this.props.indirectAttrFields.length; i++) {
                                        if (searchableField === this.props.indirectAttrFields[i][2]) {

                                            dataCorrectedSearchField = item[this.props.indirectAttrFields[i][0]] + " " + getCodeText((Number.isInteger(this.props.indirectAttrFields[i][1]) ? this.props.indirectAttrFields[i][1] : item[this.props.indirectAttrFields[i][1]]), item[this.props.indirectAttrFields[i][0]], this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses)
                                            isIndirectAttr = true;
                                        }

                                    }


                                }

                                if (typeof this.props.dateAttrFields !== 'undefined' && !isCustomRule && !isIndirectAttr && !isCombinedField) {


                                    if (this.props.dateAttrFields.includes(searchableField) && Object.keys(item).includes(searchableField)) {
                                        isDate = true;


                                        // let dateItem =  this.state.matchingValues.find(x => x.id === item[this.props.idAttrField] && this.props.dateAttrFields.some(y=>y===x.matchingField));

                                        let dateItem = this.state.matchingValues[item[this.props.idAttrField]]
                                        if (typeof dateItem !== 'undefined' && this.props.dateAttrFields.some(y=>y===dateItem.matchingField)) {
                                            dataCorrectedSearchField = dateItem.matchValue


                                        } else {

                                            if (typeof this.state.dateStore[item[this.props.idAttrField]][searchableField] !== 'undefined') {

                                                dataCorrectedSearchField = this.state.dateStore[item[this.props.idAttrField]][searchableField]

                                            } else {

                                                dataCorrectedSearchField = moment(item[searchableField])
                                                    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                                                    .format(process.env.REACT_APP_NASTA_DATE_FORMAT).toString();
                                            }
                                        }


                                    }
                                }


                                if (isDate===false && !isCustomRule && !isIndirectAttr && !isCombinedField) {
                                    if (item[searchableField] === null) {
                                        dataCorrectedSearchField = "";
                                    } else {
                                        dataCorrectedSearchField = item[searchableField].toString();
                                    }
                                }




                                let matchStartIndex = dataCorrectedSearchField.toLowerCase().indexOf(this.state.currentSearchValue.toLowerCase());
                                if (matchStartIndex !== -1) {
                                    let tmpMatch = {
                                        id: item[this.props.idAttrField],
                                        item: item,
                                        titleAttrs: (typeof this.props.titleAttrs !== 'undefined' ? this.props.titleAttrs : []),
                                        matchingField: searchableField,
                                        matchStart: matchStartIndex,
                                        matchEnd: matchStartIndex + this.state.currentSearchValue.length,
                                        matchValue: dataCorrectedSearchField,
                                        isDate: isDate
                                    }
                                    tmpMatchValues[item[this.props.idAttrField]]=tmpMatch;
                                }
                            }
                        }
                    }

                }
            }


            if (matchingValuesLength !== Object.keys(tmpMatchValues).length) {

                this.setState({matchingValues: tmpMatchValues}, ()=>{
                    if (typeof this.props.onResultsChanged !== 'undefined') {


                        this.props.onResultsChanged(Object.values(tmpMatchValues))
                    }
                });



            } else {


                for (const [key,value] of Object.entries(this.state.matchingValues)) {
                    if(tmpMatchValues[key].matchEnd!==value.matchEnd){
                        this.setState({matchingValues: tmpMatchValues}, ()=>{
                            if (typeof this.props.onResultsChanged !== 'undefined') {

                                this.props.onResultsChanged(Object.values(tmpMatchValues));

                            }
                        });
                        break;
                    }

                }
                /*
                                for (let i = 0; i < this.state.matchingValues.length; i++) {

                                    if (tmpMatchValues[i].matchEnd !== this.state.matchingValues[i].matchEnd) {

                                        this.setState({matchingValues: tmpMatchValues});
                                        if (typeof this.props.onResultsChanged !== 'undefined') {

                                            this.props.onResultsChanged(tmpMatchValues)
                                        }
                                    }
                                }
                */

            }

            /*            if (typeof this.props.sortByAttr !== 'undefined') {
                            tmpMatchValues.sort((x, y) => x[this.props.sortByAttr] > y[this.props.sortByAttr] ? -1 : 1)
                        }*/

        }

        clearSelections(){
            if(this.props.location.pathname.startsWith('/destination')){
                if(!this.props.storeHelper.getState().destinationsFilterSelector.destinationFunder.selectAll)this.props.dispatch(destinationsFilterSelector.changeDestinationFunderCodeSelectAll());
                if(!this.props.storeHelper.getState().destinationsFilterSelector.destinationLandUseUnitFilter.selectAll)this.props.dispatch(destinationsFilterSelector.changeDestinationLandUseUnitCodeSelectAll());
                if(!this.props.storeHelper.getState().destinationsFilterSelector.destinationOwner.selectAll)this.props.dispatch(destinationsFilterSelector.changeDestinationOwnerCodeSelectAll());
                if(!this.props.storeHelper.getState().destinationsFilterSelector.destinationType.selectAll)this.props.dispatch(destinationsFilterSelector.changeDestinationTypeSelectAll());
                if(this.props.storeHelper.getState().destinationsFilterSelector.destination.notInUse) this.props.dispatch(destinationsFilterSelector.changeDestinationSelection('notInUse'));
                if(!this.props.storeHelper.getState().destinationsFilterSelector.destination.inUse) this.props.dispatch(destinationsFilterSelector.changeDestinationSelection('inUse'));
                this.props.reload !== undefined && this.props.reload(); 
            }
            if(this.props.location.pathname.startsWith('/site')){
                if(this.props.storeHelper.getState().sitesFilterSelector.site.notSpecified)this.props.dispatch(sitesFilterSelector.changeSiteSelection('notSpecified'));
                if(this.props.storeHelper.getState().sitesFilterSelector.site.planned)this.props.dispatch(sitesFilterSelector.changeSiteSelection('planned'));
                if(this.props.storeHelper.getState().sitesFilterSelector.site.underConstruction)this.props.dispatch(sitesFilterSelector.changeSiteSelection('underConstruction'));
                if(!this.props.storeHelper.getState().sitesFilterSelector.site.inUse)this.props.dispatch(sitesFilterSelector.changeSiteSelection('inUse'));
                if(this.props.storeHelper.getState().sitesFilterSelector.site.discarded)this.props.dispatch(sitesFilterSelector.changeSiteSelection('discarded'));
                this.props.reload !== undefined && this.props.reload();
            }
        }

        checkKeyPressed(event)
        {

            if (event.key === "Enter") {
                this.setState({hasBeenSelected: false})
                document.removeEventListener("keyup", this.checkKeyPressed);
            }
            if (event.key === "Escape") {
                this.setState({hasBeenSelected: false})
                document.removeEventListener("keyup", this.checkKeyPressed);
            }
        }

        updateCurrentSearchValue(event)
        {
            this.setState({currentSearchValue: event.target.value});

            if (typeof this.props.onSearchValueChanged !== 'undefined') {
                this.props.onSearchValueChanged(event.target.value);
            }
        }

        GeneratedMatchingValues()
        {

            let tmpMatchingValues = [];


            let cutoffCounter=0;
            for (const x of Object.values(this.state.matchingValues)) {

                let totalTitle = "";
                for (const titleAttr of x.titleAttrs) {
                    totalTitle = totalTitle + x.item[titleAttr] + " "
                }


                let tmpMatchingFieldValue = (<>
                        {x.matchValue.length > 35 ? (x.matchStart - 14 < 0 ? "" : "...") + x.matchValue.slice(x.matchStart - 14 < 0 ? 0 : x.matchStart - 14, x.matchStart) : x.matchValue.slice(0, x.matchStart)}
                        <b>{x.matchValue.slice(x.matchStart, x.matchEnd)}</b>
                        {x.matchValue.length > 35 ? x.matchValue.slice(x.matchEnd, x.matchEnd + 14 - Math.floor((x.matchEnd - x.matchStart) / 2)) + "..." : x.matchValue.slice(x.matchEnd, x.item[x.matchingField.length])}
                    </>
                )
                tmpMatchingValues.push(<>
                    <div key={x.id + ":" + x.matchingField} className="menu-item">
                        <Link to={this.props.baseLinkUrl + "/" + x.id}>
                            <div className="tile tile-centered">
                                <div className="tile-icon">
                                    {totalTitle.length > 35 ? totalTitle.slice(0, 34) + "..." : totalTitle}
                                </div>
                                <div className="tile-content">

                                    <span>[{x.matchingField}]</span>: {tmpMatchingFieldValue}
                                </div>
                            </div>
                        </Link>
                    </div>
                </>)
                if(cutoffCounter===50){
                    break;
                }
                cutoffCounter++;
            }

            return tmpMatchingValues
        }

        render()
        {
            let {t} = this.props;
            let isDisabled = false;

            if (typeof this.props.disabled !== 'undefined') {
                isDisabled = this.props.disabled;
            }

            return (
                <>
                    {this.state.hasBeenSelected && <div className={"coverAll"} onClick={() => {
                        this.setState({hasBeenSelected: false})
                    }}>
                    </div>}
                    <div className="titleDiv">
                        <p style={{textAlign: "center"}}>{typeof this.props.title !== 'undefined' ? this.props.title : t("map_effect_notification")}</p>
                      {/* <Button className="btn btn-primary" onClick={() => this.clearSelections()}><img 
                                                                src={eraser}
                                                                alt={t("common:button_edit")}/
                                                                ></Button>*/}
                    </div>
                    <div className="form-autocomplete">
                        <div className="form-autocomplete-input form-input">
                            <TextField fullWidth
                                        hiddenLabel
                                       variant={"outlined"}
                                       size={"small"}

                                   aria-label={t("search_box")}
                                   role={"searchbox"}
                                   value={this.state.currentSearchValue}

                                   onChange={e => this.updateCurrentSearchValue(e)}

                                   disabled={isDisabled}
                                   onSelect={() => this.setState({hasBeenSelected: true})}
                                   onFocus={() => {
                                        document.removeEventListener("keyup", this.checkKeyPressed);
                                        document.removeEventListener("keydown", this.checkKeyPressed);
                                        document.addEventListener("keydown", this.checkKeyPressed);
                                   }}
                                   inputProps={{
                                       "aria-label":t("search_box"),
                                       'role':'searchbox'
                                   }}
                                       InputProps={{endAdornment:(
                                               <InputAdornment>
                                                   <IconButton aria-label={t("search_button")}  onClick={() => this.setState({hasBeenSelected: false})}>
                                                       <i alt={""} className={"icon icon-lg icon-search"}/>
                                                   </IconButton>
                                               </InputAdornment>
                                           )}}
                            />


                        </div>

                        {isDisabled || this.state.hasBeenSelected === false ?
                            <></>
                            : Object.values(this.state.matchingValues).length>0 &&
                                <div className="menu">
                                    <this.GeneratedMatchingValues/>
                                </div>


                        }


                    </div>
                </>
            )
        }
    }

    export
    default

    withProps(SearchBox)