export default class Coordinate{
    _coordinateGuid;
    _coordinateId;
    _createdBy;
    _createdTime;
    _e_Coordinate;
    _eco_X_Coordinate;
    _eco_Y_Coordinate;
    _modifiedBy;
    _modifiedTime;
    _n_Coordinate;
    _point_EcoVision;
    _point_Euref_Fin;
    _point_WGS_84;
    _x_Coordinate;
    _y_Coordinate;


    constructor(coordinateGuid, coordinateId, createdBy, createdTime, e_Coordinate, eco_X_Coordinate, eco_Y_Coordinate, modifiedBy, modifiedTime, n_Coordinate, point_EcoVision, point_Euref_Fin, point_WGS_84, x_Coordinate, y_Coordinate) {

        if(typeof coordinateGuid["coordinateGuid"] !== 'undefined' || coordinateGuid["coordinateGuid"] !==null){
            this._coordinateGuid = coordinateGuid["coordinateGuid"];
            this._coordinateId = coordinateGuid["coordinateId"];
            this._createdBy = coordinateGuid["createdBy"];
            this._createdTime = coordinateGuid["createdTime"];
            this._e_Coordinate = coordinateGuid["e_Coordinate"];
            this._eco_X_Coordinate = coordinateGuid["eco_X_Coordinate"];
            this._eco_Y_Coordinate = coordinateGuid["eco_Y_Coordinate"];
            this._modifiedBy = coordinateGuid["modifiedBy"];
            this._modifiedTime = coordinateGuid["modifiedTime"];
            this._n_Coordinate = coordinateGuid["n_Coordinate"];
            this._point_EcoVision = coordinateGuid["point_EcoVision"];
            this._point_Euref_Fin = coordinateGuid["point_Euref_Fin"];
            this._point_WGS_84 = coordinateGuid["point_WGS_84"];
            this._x_Coordinate = coordinateGuid["x_Coordinate"];
            this._y_Coordinate = coordinateGuid["y_Coordinate"];
        }else{

            this._coordinateGuid = coordinateGuid;
            this._coordinateId = coordinateId;
            this._createdBy = createdBy;
            this._createdTime = createdTime;
            this._e_Coordinate = e_Coordinate;
            this._eco_X_Coordinate = eco_X_Coordinate;
            this._eco_Y_Coordinate = eco_Y_Coordinate;
            this._modifiedBy = modifiedBy;
            this._modifiedTime = modifiedTime;
            this._n_Coordinate = n_Coordinate;
            this._point_EcoVision = point_EcoVision;
            this._point_Euref_Fin = point_Euref_Fin;
            this._point_WGS_84 = point_WGS_84;
            this._x_Coordinate = x_Coordinate;
            this._y_Coordinate = y_Coordinate;
        }


    }


    get coordinateGuid() {
        return this._coordinateGuid;
    }

    get coordinateId() {
        return this._coordinateId;
    }

    get createdBy() {
        return this._createdBy;
    }

    get createdTime() {
        return this._createdTime;
    }

    get e_Coordinate() {
        return this._e_Coordinate;
    }

    get eco_X_Coordinate() {
        return this._eco_X_Coordinate;
    }

    get eco_Y_Coordinate() {
        return this._eco_Y_Coordinate;
    }

    get modifiedBy() {
        return this._modifiedBy;
    }

    get modifiedTime() {
        return this._modifiedTime;
    }

    get n_Coordinate() {
        return this._n_Coordinate;
    }

    get point_EcoVision() {
        return this._point_EcoVision;
    }

    get point_Euref_Fin() {
        return this._point_Euref_Fin;
    }

    get point_WGS_84() {
        return this._point_WGS_84;
    }

    get x_Coordinate() {
        return this._x_Coordinate;
    }

    get y_Coordinate() {
        return this._y_Coordinate;
    }
}