import { createSlice } from '@reduxjs/toolkit'

export const mapLayerSelector = createSlice({
  name: 'mapLayerSelector',
  initialState: {
    layers:{
        countiesLayerVisibility: false,
        destinationLayerVisibility: false,
        entitiesLayerVisibility: false,
        landUseUnitLayerFillVisibility: false,
        landUseUnitLayerStrokeVisibility: false,
        municipalitiesLayerVisibility: false,
        parkDistrictsLayerVisibility: false,
        regionalUnitsLayerVisibility: false,
        siteLayerVisibility: false,
        showMapManager: false,
        isLayerSelectionOpen: false,
    },
  },
  reducers: {
    changeCountiesLayerVisibility: (state) => {
        state.layers.countiesLayerVisibility = !state.layers.countiesLayerVisibility
    },
    changeDestinationLayerVisibility: (state) => {
        state.layers.destinationLayerVisibility = !state.layers.destinationLayerVisibility
    },
    changeEntitiesLayerVisibility: (state) => {
      state.layers.entitiesLayerVisibility = !state.layers.entitiesLayerVisibility
    },
    changeLandUseUnitLayerFillVisibility: (state) => {
        state.layers.landUseUnitLayerFillVisibility = !state.layers.landUseUnitLayerFillVisibility
    },
    changeLandUseUnitLayerStrokeVisibility: (state) => {
        state.layers.landUseUnitLayerStrokeVisibility = !state.layers.landUseUnitLayerStrokeVisibility
    },
    changeMunicipalitiesLayerVisibility: (state) => {
        state.layers.municipalitiesLayerVisibility = !state.layers.municipalitiesLayerVisibility
    },
    changeParkDistrictsLayerVisibility: (state) => {
        state.layers.parkDistrictsLayerVisibility = !state.layers.parkDistrictsLayerVisibility
    },
    changeRegionalUnitsLayerVisibility: (state) => {
        state.layers.regionalUnitsLayerVisibility = !state.layers.regionalUnitsLayerVisibility
    },
    changeSiteLayerVisibility: (state) => {
        state.layers.siteLayerVisibility = !state.layers.siteLayerVisibility
    },
    changeShowMapManager: (state) => {
        state.layers.showMapManager = !state.layers.showMapManager
    },
    changeOpenLayerSelection: (state) => {
        state.layers.isLayerSelectionOpen = !state.layers.isLayerSelectionOpen
    },
  },
})

// Action creators are generated for each case reducer function
export const { changeCountiesLayerVisibility, changeDestinationLayerVisibility, changeEntitiesLayerVisibility, 
    changeLandUseUnitLayerFillVisibility, changeLandUseUnitLayerStrokeVisibility, changeMunicipalitiesLayerVisibility, 
    changeParkDistrictsLayerVisibility, changeRegionalUnitsLayerVisibility, changeSiteLayerVisibility,
    changeShowMapManager, changeOpenLayerSelection} = mapLayerSelector.actions

export default mapLayerSelector.reducer