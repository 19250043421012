import React from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useMsal} from "@azure/msal-react";
import {useTranslation} from "react-i18next";
import moment from "moment-timezone/moment-timezone-utils";
import {GetDestination, GetDestinations, GetSite, GetSites} from "../utils/NastaApiClient";
import {Destination} from "../dataClasses/Destination/Destination";
import SearchBox from "../widgets/SearchBox/SearchBox";
import {Site} from "../dataClasses/Site/Site";
import Pagination from "../widgets/Pagination/Pagination";

const withRouter = WrappedComponent => props => {
    const params = useParams();
    const {instance} = useMsal();
    const navigate = useNavigate();
    const {t} = useTranslation('searchList', 'common')

    return (
        <WrappedComponent
            t={t}
            {...props}
            {...{instance, /* other injected props */}}
            params={params}
            navigate={navigate}
        />
    );
};


class SearchList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            paginationOffset:0,
            itemList: null,
            searchableFields: null,
            titleAttr:null,
            filteredList: []
        }

        this.genRows = this.genRows.bind(this);
        this.updateList = this.updateList.bind(this);
    }


    componentDidMount() {

        if (typeof this.props.params.type === 'undefined') {
            console.warn("Property param 'type' not set in SearchList, cannot list items");
        } else {

            switch (this.props.params.type) {
                case "destinations":
                    GetDestinations().then(destinations => {

                        this.setState({
                            titleAttr:"destinationName",
                            itemList: (destinations.map(x => new Destination(x))),
                            searchableFields: ["description",
                                "destinationId",
                                "destinationType",
                                "destinationOwner",
                                "customerServiceType",
                                "destinationRegion",
                                "destinationName",
                                "customerServiceName",
                                "createdBy",
                                "createdTime",
                                "modifiedBy",
                                "modifiedTime"
                            ]
                        });
                    })
                    break;
                case "sites":

                    GetSites().then(sites => {
                        console.log(sites)
                        this.setState({
                            titleAttr:"siteName",
                            itemList: (sites.data.map(x => new Site(x))), searchableFields: ["siteId",
                                "siteName",
                                "createdBy",
                                "modifiedBy",
                                "responsibleName",
                                "description",
                                "createdTime",
                                "modifiedTime"
                            ]
                        })
                    })
                    break;

                case "flows":
                    break;

                case "counts":
                    break;
            }
        }

        if (typeof this.props.params.searchWord === 'undefined') {
            console.warn("Property param 'searchWord' not set in SearchList, cannot list items");
        }
    }

    genRows() {
        let {t} = this.props;
        let type = this.props.params.type;

        return (<>

            <tr>
                <th>{t('title')}</th>
                <th>{t('value1')}</th>
                <th>{t('value2')}</th>
            </tr>
        </>)
    }

    updateList(newList) {
        let tmpNames=[];
        let tmpList=[];
        for (const newListElement of newList) {
            if(!tmpNames.includes(newListElement.item[this.state.titleAttr])){
                tmpNames.push(newListElement.item[this.state.titleAttr]);
                tmpList.push(newListElement);
            }
        }

        let tmpOffset=this.state.paginationOffset;
        if(tmpList.length<this.state.paginationOffset*25){
            tmpOffset=tmpList.length/25;
        }
        this.setState({filteredList: tmpList, paginationOffset:tmpOffset});
    }

    render() {
        let {t} = this.props;

        let sliceOfFilteredList=this.state.filteredList;
        sliceOfFilteredList=sliceOfFilteredList.slice(this.state.paginationOffset*25,(this.state.paginationOffset*25)+25);
        return (
            <>
                <SearchBox
                    titleAttr={this.state.titleAttr}
                    searchableFields={this.state.searchableFields}
                    items={(this.state.itemList === null ? [] : this.state.itemList)}
                    initialSearchValue={this.props.params?.searchWord}
                    disabled={false}
                    onResultsChanged={this.updateList}
                />
                <table className="table table-striped table-hover">
                    <thead>
                    <tr>

                        <th>{t('title_attr')}</th>
                        <th>{t('match_value')}</th>
                        <th>{t('value2')}</th>
                    </tr>
                    </thead>
                    <tbody>


                        {
                            sliceOfFilteredList.map(x=>{
                            return (
                                <tr>
                                    <td>{x.item[x.titleAttr]}</td>
                                    <td>{x.item[x.matchingField]}</td>
                                    <td>{t('value2')}</td>
                                </tr>

                            )
                        })
                        }



                    </tbody>

                </table>
                <Pagination
                    currentPageNumber={this.state.paginationOffset+1}
                    divisor={25}
                    totalItemCount={this.state.filteredList.length}
                    onPageSelected={(x)=>this.setState({paginationOffset:x})}
                />
            </>
        )
    }
}

export default withRouter(SearchList)