import React from "react";
import MultiChoiceSearchDropdown from "../widgets/MultiChoiceSearchDropdown/MultiChoiceSearchDropdown";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import {getCodeText} from '../utils/CodeHelpers' 
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider} from "@mui/x-date-pickers";
import moment from "moment-timezone";
import 'moment/locale/fi'

import './AddCounting.css';
import ValidatedInputField from "../widgets/ValidatedInputField/ValidatedInputField";
import {useMsal} from "@azure/msal-react";
import {useNavigate, useParams, Link} from "react-router-dom";
import {AxiosError} from "axios";
import {
    GetCodeClasses,
    DeleteCounting
} from '../utils/NastaApiClient';
import FormController from "../widgets/FormController/FormController";
import * as countingDropdownDataSelector from './CountingDropdownDataSelector';
import { Tooltip } from 'react-tooltip';

import { useSelector, useDispatch, useStore} from 'react-redux';
import * as codesSelector from '../mapViewOl/codesSelector';
import CountingDialog from "../widgets/CountingDialog/CountingDialog";
import UploadingNotification from "../widgets/UploadingNotification/UploadingNotification";
import { Typography } from "@mui/material";


const withRouter = WrappedComponent => props => {
    const params = useParams();
    const {instance} = useMsal();
    const navigate = useNavigate();
    const {t} = useTranslation('removeCounting', 'common');
    const codes = useSelector((state) => state.codes);
    const dropdownCommonSelections = useSelector((state) => state.common);
    const dispatch = useDispatch();
    const storeHelper = useStore();

    return (
        <WrappedComponent
            t={t}
            {...props}
            {...{instance, /* other injected props */}}
            params={params}
            navigate={navigate}
            dispatch={dispatch}
            storeHelper={storeHelper}
            codes={codes}
            dropdownCommonSelections={dropdownCommonSelections}
        />
    );
};

class RemoveCounting extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitAttempted: false,
            showSubmitModal: false,
            showErrorModal: false,
            choices: [],
            viewMode: this.props.additionalAttributes.countingState,
            allValid:false,
            showSubmitAlert: false,
            submitAlertMessage: '',
            dateErrorMessage: "",
            countingBeginTime: new Date().setHours(0,0,0,0),
            countingEndTime: new Date().setHours(0,0,0,0),
            dateValid: false,
            countingRuleId: -1,
            dialogOpen: false,
            currentMessage: '',
            currentTitle: '',
            countingLoaded: false,
            userTypeCodeId: undefined,
            userTypeCodeClassId: -1
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkDateValidity = this.checkDateValidity.bind(this);
        this.GenQuickMultiSearchDropdown = this.GenQuickMultiSearchDropdown.bind(this);
    }

  

    validateString(x) {
        if (typeof x === 'string' || x instanceof String) {
            if (x.length < 1) {
                throw new Error("Field length too low (0): " + x)
            }
            return x
        } else {
            throw new TypeError("invalid field value for: " + x + " (string required)")
        }
    }

    validateBoolean(x) {
        if (typeof x == "boolean") {
            return x
        } else {
            throw new TypeError("invalid field value for: " + x + " (boolean required)")
        }
    }

    validateInteger(x) {
        if (Number.isInteger(x)) {
            return x
        } else {
            try {
                return parseInt(x);
            } catch (e) {
                throw new Error("Field type not applicable integer");
            }
        }
    }

    async componentDidMount() {
        
        try {
            if(!this.props.storeHelper.getState().codesSelector.codes.codeClassesLoaded){
                let response = await GetCodeClasses();

                if (response instanceof AxiosError) {
                    this.setState({error: response});
                    this.props.dispatch(codesSelector.changeCodeClassesLoaded(false));
                } else {
                    this.props.dispatch(codesSelector.fillCodeClasses(response));
                    this.props.dispatch(codesSelector.changeCodeClassesLoaded(true));
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    getSelectedItemText(countingRule){

        if(countingRule !== undefined){
        let conName = countingRule.countingRuleName + " , " + getCodeText(countingRule.userTypeCodeClassId, countingRule.userTypeCodeId, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses)
            + " , " + getCodeText(countingRule.frequencyCodeClassId, countingRule.frequencyCodeId, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses)
            + " , " + countingRule.countingRuleStart + " - " + countingRule.countingRuleEnd; 

        return [{id:  countingRule.countingRuleId,
            name: conName,
            title:  countingRule.countingRuleId,
            icon: "chip"}];
        }

        return [];
    }

    async handleSubmit(event) {

        this.setState({showSubmitAlert: false})

            if(new Date(this.state.countingBeginTime) < new Date(this.state.countingEndTime)){
             
                let submitThis = {
                    destinationId: this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestination.destinationId,
                    countingStart: this.formatTime(this.state.countingBeginTime),
                    countingEnd: this.formatTime(this.state.countingEndTime),
                    userTypeCodeId: this.state.userTypeCodeId,
                    userTypeCodeClassId: this.state.userTypeCodeClassId
                };

                let response = await DeleteCounting(submitThis);

                if (response instanceof AxiosError) {

                    if(response.response.data === "Nothing_deleted"){
                        this.setState({submitAttempted: false, currentMessage: this.props.t("removeCounting:error_message_nothing_to_delete"),  currentTitle: this.props.t("removeCounting:error_title"), dialogOpen: true});
                    }
                    if(response.response.data === "Ready_flagged"){
                        this.setState({submitAttempted: false, currentMessage: this.props.t("removeCounting:error_message_flagged"),  currentTitle: this.props.t("removeCounting:error_title"), dialogOpen: true});
                    }
                    if(response.response.data !== "Nothing_deleted" && response.response.data !== "Ready_flagged"){
                        this.setState({submitAttempted: false, currentMessage: this.props.t("common:submit_error_title"),  currentTitle: this.props.t("common:submit_error_content") + response.message, dialogOpen: true});
                    }
                    this.setState({submitAttempted: false});
                } else {
                    this.setState({submitAttempted: false, currentMessage: this.props.t("removeCounting:success_message") + response.data + this.props.t("removeCounting:success_message_quantity"),  currentTitle: this.props.t("removeCounting:success_title"), dialogOpen: true});
                }
            }
         
    }

    

    formatTime(dateString) {
        let date = moment.tz(dateString, Intl.DateTimeFormat().resolvedOptions().timeZone);
        date.seconds(0);
        return date.format('YYYY-MM-DDTHH:mm:ss');
    }



    checkDateValidity() {


            let valid = false;
            let end = moment(this.state.countingEndTime);
            let begin = moment(this.state.countingBeginTime);


            let msg = "";
            this.setState({
                dateValid: true
            });
    }

    GenQuickMultiSearchDropdown(props) {
        let codeClass = props.storeHelper.getState().codesSelector.codes.codeClasses.find(cc => cc.name === props.dataName);

        if (typeof codeClass !== 'undefined') {
            let selectedItem = undefined;
            let choiceArray = codeClass.codes.map(code => {
                if (typeof code.languages[code.languages.findIndex(i => i.languageName === props.t('common:GMLLanguage'))] !== 'undefined' && typeof code.codeId !== 'undefined') return {
                    id: code.codeId,
                    name: code.languages[code.languages.findIndex(i => i.languageName === props.t('common:GMLLanguage'))].text,
                    title: code.codeId.toString()
                }
                else{
                    return{
                    id: -1,
                    name: 'not named',
                    title: '-1'}
                }
            });

            if (typeof props.selectedCodeId !== 'undefined') {
                selectedItem = choiceArray[choiceArray.findIndex(i => i.id === parseInt(props.selectedCodeId))];
            }

            return (
                <MultiChoiceSearchDropdown required={props.required}
                                           maxChoices={1}
                                           minChoicesFixed={false}
                                           active={props.active}
                                           minChoices={1}
                                           validationMode={(props.submitAttempted ? 'always' : 'onSelect')}
                                           title={props.t('removeCounting:' + props.title)}
                                           tooltip={props.t('removeCounting:' + props.tooltip)}
                                           placeholder={props.t('removeCounting:' + props.placeholder)}
                                           inputData={choiceArray}
                                           choices={typeof selectedItem !== 'undefined' && selectedItem !== null ? [selectedItem] : []}
                                           onChoicesChange={(choicesList) => {
                                               if (choicesList.length > 0) {
                                                    this.setState({
                                                        userTypeCodeId:choicesList[0].id,
                                                        userTypeCodeClassId: codeClass.codeClassId
                                                    });
                                               }else{
                                                    this.setState({
                                                        userTypeCodeId:undefined,
                                                        userTypeCodeClassId: -1
                                                    });
                                               
                                               }
                                           }}
                                           onValidityChange={props.onValidityChange}
                />
            )
        } else {
            return (<>
                <label className="form-label"
                       htmlFor={props.dataName}>{props.t('removeCounting:' + props.title)}<span
                       data-tooltip-id={"tooltip-"+ props.tooltip}
                       data-tooltip-html={props.t('removeCounting:' + "tooltip_" + props.tooltip)}
                       data-tooltip-delay-hide={1000}> <img
                    width="15" alt={props.t("common:info_logo")}
                    src="/img/icon/JHT design system/Information Circle.svg"/></span></label>
                    <Tooltip style={{zIndex:50000, fontSize: 'small'}} noArrow  id={"tooltip-"+ props.tooltip} />
                <input className="form-input" id={props.dataName}
                       placeholder={"Missing codeClass data for " + props.t('removeCounting:' + "placeholder_" + props.dataName)}></input></>)
        }
    }

    

     render() {
        let {t} = this.props;
        return (
            <>
                   <>
                        <form>

                            <FormController onValidityChanged={(validity)=>this.setState({allValid:validity})}>
                            
                            <Typography>{t("removeCounting:main_text")} <strong> {this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestination.destinationName} </strong> {t("removeCounting:main_text2")}</Typography>

                            <this.GenQuickMultiSearchDropdown
                                        required={true}
                                        active={true} dataName="userType"
                                        placeholder={"counting_rule_userType_placeholder"} tooltip={"counting_rule_userType_tooltip"} title={"counting_rule_userType"}
                                        selectedCodeId={typeof this.state.userTypeCodeId !== 'undefined' ? this.state.userTypeCodeId : undefined}
                                        t={t}
                                        storeHelper={this.props.storeHelper}
                                        submitAttempted={this.state.submitAttempted}
                                    />

                            <p className={"" + (this.state.dateErrorMessage.length > 0 && ' label label-rounded label-error')}>{this.state.dateErrorMessage}</p>

                            <div>
                                <label htmlFor={"startTime"}>{t('removeCounting:counting_start_time')}</label><br/>
                                <LocalizationProvider locale={"fi-FI"} dateAdapter={AdapterMoment}>
                                    <DateTimePicker ampm={false} id={"startTime"} format={process.env.REACT_APP_NASTA_DATE_FORMAT} onChange={(date) => {
                                        this.setState({
                                                countingBeginTime: date
                                        }, () => {
                                            this.checkDateValidity();
                                        });
                                    }}
                                    value={moment(this.state.countingBeginTime)}

                                    />


                                </LocalizationProvider>
                            </div>

                            <div>
                                <label htmlFor={"endTime"}>{t('removeCounting:counting_end_time')}</label><br/>

                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DateTimePicker ampm={false} id={"endTime"} format={process.env.REACT_APP_NASTA_DATE_FORMAT} onChange={(date) => {


                                        this.setState({
                                                countingEndTime: date
                                        }, () => {
                                            this.checkDateValidity();
                                        });
                                    }}
                                    value={moment(this.state.countingEndTime)}

                                    />
                                </LocalizationProvider>
                            </div>

                            </FormController>

                            {        this.state.showSubmitAlert &&  <Alert onClose={() => {this.setState({showSubmitAlert: false})}} severity="error">
                                                <AlertTitle>{t("addCalcPlace:error_message_header")}</AlertTitle>
                                                {t("addCalcPlace:error_message_title_alert_box")} — <strong>{this.state.submitAlertMessage}</strong>
                                            </Alert>
                            }

                            <div>
                                {this.state.viewMode === 'edit' && 
                                    <Button size={"small"} variant={"contained"} type="button" onClick={() => this.setState({showErrorModal: true})}
                                        disabled={localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser" ? false : true}
                                        className=" float-left"><img style={{filter:"invert(100%)", height:"1.5vh"}} src="/img/icon/JHT design system/Pencil Write Alternate.svg" alt={t("common:button_edit")}/>{t("addCalcPlace:button_delete")}</Button>}

                               
                                    <Button type="button"
                                            size={"small"} variant={"contained"}
                                            disabled={localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser" ? false : true}
                                            className="float-right" onClick={() => {
                                        if (this.state.editingState) {
                                            this.setState({showEditingModal: true})
                                        } else {
                                            this.handleSubmit();
                                        }

                                    }}><img style={{filter:"invert(100%)", height:"1.5vh"}} src="/img/icon/JHT design system/bin_fill.svg" alt={t("common:delete_button")}/> {t("common:delete_button")}</Button>
                            </div>
                            {this.state.submitAttempted && <UploadingNotification showModal={this.state.submitAttempted} errors={this.state.submitErrors} type={"upload"} />}
                        </form>

                        <CountingDialog 
                            showModal={this.state.dialogOpen}
                            title={this.state.currentTitle}
                            message={this.state.currentMessage}
                            onModalCancel={()=>this.setState({dialogOpen:false},  this.props.navigate("/counting/list/" + this.props.storeHelper.getState().countingDropdownDataSelector.common.listViewDestination.destinationId+"/1"))}
                        />
                   </>
            </>
        )
    }
}

export default withRouter(RemoveCounting);