import React from "react";
import "./Footer.css";
import {withTranslation} from "react-i18next";

class Footer extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            buildInfo: null,
           }
    }

    componentDidMount() {
        fetch('/build-info.json')
          .then(response => response.json())
          .then(data => this.setState({ buildInfo: data }))
          .catch(error => console.error('Error fetching build info:', error));
      }


    render(){
        let {t}=this.props;
        return (
            <>
            <footer>
                <div className="footer">
                    <div className="footer-version">Version: {process.env.REACT_APP_VERSION_INFO}  Build: {this.state.buildInfo !== null && this.state.buildInfo.buildNumber !== null ? this.state.buildInfo.buildNumber : ''}</div>
                    <div className="footer-link">© <a target="_blank" href="https://www.maanmittauslaitos.fi/avoindata-lisenssi-cc40">Maanmittauslaitos</a> contributors</div>
                </div>
            </footer>
            </>
        )
    }
}

export default withTranslation("footer")(Footer)