import React from "react";


export default class ToastManager extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    setVisibility(visibility){
        this.props.onVisibilityChange(visibility)
    }

    render() {
        return (
            <>
                {this.props.visible && <>
                    <div role={"alert"} className={"toast toast-"+(this.props.message.type.toLowerCase()==='success'?'primary':'error')}>
                        <button className="btn btn-clear float-right" onClick={()=>this.setVisibility(false)}></button>
                        {this.props.message.message}
                    </div>
                </>}

            </>
        )
    }
}