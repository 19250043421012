import React from "react";
import {validateChoice} from "./widgets/MultiChoiceSearchDropdown/MultiChoiceSearchDropdown";

class TitleManager extends React.Component {

    constructor(props) {
        super(props);
        this.state = {titleStack: ["Nasta"]}

        this.checkTitle = this.checkTitle.bind(this);
    }

    componentDidMount() {
        setInterval(this.checkTitle, 1000);
    }

    checkTitle(){
        if(document.title!==this.props.titleStack[this.props.titleStack.length-1]+" - " + this.state.titleStack[0]){
            document.title=this.props.titleStack[this.props.titleStack.length-1]+" - " + this.state.titleStack[0];
        }

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        document.title=this.props.titleStack[this.props.titleStack.length-1]+" - " + this.state.titleStack[0];
    }

    render() {
        return (<></>)
    }

}

export default TitleManager