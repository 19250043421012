import { createSlice } from '@reduxjs/toolkit'

export const countingRuleDropdownDataSelector = createSlice({
  name: 'countingRuleSelector',
  initialState: {
    common:{
      destinations: [],
      selectedFlows: [],
      sites: [],
      selectedSites: [],
      destination:{},
      flows:[],
      countingRuleStart: '',
      countingRuleEnd: '',
      weightInputs: [],
      listViewDestinations: [],
      listViewDestination:{}
    }
  },
  reducers: {
    changeDestination: (state, action) => {
        state.common.destination = action.payload;
    },
    changeDestinationList: (state, action) => {
        state.common.destinations = action.payload;
    },
    changeSitesList: (state, action) => {
      state.common.sites = action.payload;
    },
    changeSelectedSitesList: (state, action) => {
      state.common.selectedSites = action.payload;
    },
    changeSelectedFlows: (state, action) => {
      state.common.selectedFlows = action.payload;
    },
    changeFlows: (state, action) => {
      state.common.flows = action.payload;
    },
    changeWeightInputs: (state, action) => {
      state.common.weightInputs = action.payload;
    },
    changeCountingRuleStart: (state, action) => {
      state.common.countingRuleStart = action.payload;
    },
    changeCountingRuleEnd: (state, action) => {
      state.common.countingRuleEnd = action.payload;
    },
    changeListViewDestinationList: (state, action) => {
      state.common.listViewDestinations = action.payload;
    },
    changeListViewDestination: (state, action) => {
      state.common.listViewDestination = action.payload;
    },
    clearAll: (state) => {  
      state.common.selectedFlows = [];
      state.common.selectedSites = [];
      state.common.selectedFlows = [];
      state.common.weightInputs = [];
      state.common.destination = {};
    },
    resetAll: (state) => {  
      state.common.selectedFlows = [];
      state.common.selectedSites = [];
      state.common.selectedFlows = [];
      state.common.weightInputs = [];
      state.common.countingRuleStart = '';
      state.common.countingRuleEnd = '';
      state.common.destination = {};
      state.common.sites = [];
      state.common.flows = [];
    },
    clearListView(state){
      state.common.listViewDestination = {};
    }
  },
})

// Action creators are generated for each case reducer function
export const {changeDestination, changeDestinationList, changeSitesList, changeSelectedFlows, changeSelectedSitesList, changeFlows, clearAll, changeCountingRuleStart, changeCountingRuleEnd, changeWeightInputs, resetAll, changeListViewDestinationList, changeListViewDestination, clearListView} = countingRuleDropdownDataSelector.actions

export default countingRuleDropdownDataSelector.reducer