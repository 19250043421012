import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useMsal} from "@azure/msal-react";
import {useTranslation} from "react-i18next";

const withRouter = WrappedComponent => props => {
    const params = useParams();
    const {instance} = useMsal();
    const navigate = useNavigate();
    const {t} = useTranslation('reports', 'common')

    return (
        <WrappedComponent
            t={t}
            {...props}
            {...{instance, /* other injected props */}}
            params={params}
            navigate={navigate}
        />
    );
};
class ReportView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let {t}=this.props;
        return (
            <>
                <a target={"blank"} href={"https://app.powerbi.com/Redirect?action=OpenReport&appId=19045b26-5b13-42b3-bd2c-e4bc845866fa&reportObjectId=6da0e403-bbd6-4f11-86cd-0a9a75c52184&ctid=bdb27328-5c0b-4132-9b09-c24522571ad9&reportPage=ReportSection&pbi_source=appShareLink&portalSessionId=6168853e-d8cc-47e2-86e7-72a268d14de8"}>
                    PowerBI
                </a>
                <iframe
                    title="Laskentapaikat"
                    width="1140"
                    height="541.25"
                    src="https://app.powerbi.com/reportEmbed?reportId=cfd6c79c-a754-4241-8cf7-5ea8ee50242c&autoAuth=true&ctid=bdb27328-5c0b-4132-9b09-c24522571ad9"
                    allowFullScreen="true"></iframe>

            </>
        )
    }
}
export default withRouter(ReportView)