import React, { Component } from 'react';
import MonthCheckboxGroup from './MonthCheckGroup';
import {useTranslation} from "react-i18next";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as countingRuleTimeLimitsSelector from './CountingRuleTimeLimitsSelector';
import * as countingRuleDropdownDataSelector from './CountingRuleDropdownDataSelector';
import { useSelector, useDispatch, useStore} from 'react-redux';

const hoursOnDay = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14 ,15,16,17,18,19,20,21,22,23];
const daysInMonths = [31,29,31,30,31,30,31,31,30,31,30,31];
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];


const withStore = WrappedComponent => props => {
  const timeLimits = useSelector((state) => state.countingRuleTimeLimitsSelector);
  const dropdownData = useSelector((state) => state.countingRuleDropdownDataSelector);
  const dispatch = useDispatch();
  const {t} = useTranslation('hourLinesComponent', 'common');
  const storeHelper = useStore();

  return (
      <WrappedComponent
          {...props}
          dispatch={dispatch}
          t = {t}
          storeHelper={storeHelper}
          timeLimits={timeLimits}
          dropdownData={dropdownData}
      />
  );
};

class HourLineSelection extends Component {
  constructor(props) {
    super(props);

    let currentIndex = this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.hourLineSelections.find(f => f.flowId === this.props.flowId).hourLineSelections.findIndex(f => f.props.index === this.props.index);

    this.state = {
      currentIndex: this.props.index,
      parentFlow: this.props.flowId,
      hours:  this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.length > 0 && this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId) !== undefined ? this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId).hourLines[currentIndex].hours : [],
      monthDays:   this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.length > 0 && this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId) !== undefined ? this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId).hourLines[currentIndex].monthDays : [],
      simpleMonths:  this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.length > 0 && this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId) !== undefined ? this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId).hourLines[currentIndex].simpleMonths : [],
      weekdays:  this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.length > 0 && this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId) !== undefined ? this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId).hourLines[currentIndex].weekdays : [],
      advancedMode:  this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.length > 0 && this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId) !== undefined ? this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId).hourLines[currentIndex].advancedMode : false,
      selectAllSimpleMonths:  this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.length > 0 &&  this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId) !== undefined ? this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId).hourLines[currentIndex].selectAllSimpleMonths :true,
      selectAllWeekdays:  this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.length > 0 &&  this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId) !== undefined ? this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId).hourLines[currentIndex].selectAllWeekdays : true,
      selectWeekend:  this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.length > 0 &&  this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId) !== undefined ? this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId).hourLines[currentIndex].selectWeekend : true,
      selectWeekdays:  this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.length > 0 &&  this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId) !== undefined ? this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId).hourLines[currentIndex].selectWeekdays : true,
      selectAllHours:  this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.length > 0 &&  this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId) !== undefined ? this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId).hourLines[currentIndex].selectAllHours : true,
      countingRuleStart: this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.countingRuleStart,
      countingRuleEnd: this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.countingRuleEnd,
      filtersEdited: this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.length > 0 && this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId) !== undefined ? this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.props.flowId).hourLines[currentIndex].filtersEdited : false,
    };

  }

  componentDidMount() { 

   this.updateAdvancedMonths();

    if(this.state.hours.length === 0) {
    const hours = [];
    for (let i = 0; i < hoursOnDay.length; i++) {
      const hour = hoursOnDay[i];
      const id = hour < 10 ? `hour0${hour}` : `hour${hour}`;
      const label = hour < 10 ? `0${hour}` : `${hour}`;
      const checked = true;
      hours.push({ id, label, checked });
    }

    this.setState({ hours }, this.updateCommonState);
  }

  if(this.state.weekdays.length === 0){
    const weeks = [];
    for (let day = 0; day < 7; day++) {
      const currentDay = weekdays[day];
      const id = `${currentDay}`;
        const checked = true;
        weeks.push({ id, checked });
    }
    this.setState({ weekdays: weeks }, this.updateCommonState);
  }

  if(this.state.simpleMonths.length === 0){
    const simpleMon = [];
    for (let month = 0; month < 12; month++) {
      const currentMonth = months[month];
      const id = `${currentMonth}`;
        const checked = true;
        simpleMon.push({ id, checked });
    }
    this.setState({ simpleMonths: simpleMon }, this.updateCommonState);
  }

  }

  updateCommonState = () => {
      if(this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimits.find(f => f.flowId === this.state.parentFlow) !== undefined){
        this.props.dispatch(countingRuleTimeLimitsSelector.updateTimeLimitsTable({flowId: this.state.parentFlow, index: this.state.currentIndex, data: this.state}));
      }
  }

  componentDidUpdate(prevProps, prevState, snapshot)  {
  
    if (this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.countingRuleStart !== this.state.countingRuleStart) {
      if(this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.countingRuleStart.split('.')[0] !== '' 
        && this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.countingRuleStart.split('.')[1] !== ''){
          this.setState({ countingRuleStart: this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.countingRuleStart }, this.updateAdvancedMonths);
        }
    }
    if (this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.countingRuleEnd !== this.state.countingRuleEnd) {
      if(this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.countingRuleEnd.split('.')[0] !== '' 
        && this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.countingRuleEnd.split('.')[1] !== ''){
            this.setState({ countingRuleEnd: this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.countingRuleEnd }, this.updateAdvancedMonths);
        }
    }
  }

  updateAdvancedMonths = () => {
    
    const monthDays = [];
    let start = this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.countingRuleStart.split('.');
    let end = this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.countingRuleEnd.split('.');

    if(this.state.monthDays.length <= parseInt(end[1])){
      let monthIndex = 0;
      for (let month = parseInt(start[1]); month <= parseInt(end[1]); month++) {

        const daysInMonth = parseInt(daysInMonths[month-1]);
        const monthDaysArray = [];

        let endDay = parseInt(month) === parseInt(end[1]) ? parseInt(end[0]) : daysInMonth;
        let startDay = parseInt(month) === parseInt(start[1]) ? parseInt(start[0]) : 1;

        for (let day = startDay; day <= endDay; day++) {
          const id = `${months[month-1]}-${day}`;
          const checked = this.state.monthDays.length > 0 && this.state.monthDays[monthIndex] !== undefined && this.state.monthDays[monthIndex][day-1] !== undefined ? this.state.monthDays[monthIndex][day-1].checked : true;
          monthDaysArray.push({ id, checked });
        }
        monthIndex++;
        monthDays.push(monthDaysArray);
      }

      this.setState({ monthDays }, this.updateCommonState);
    }else{
      this.updateCommonState();
    }

  }

  handleHourSelectChange = (event) => {
    const { hours } = this.state;
    const updatedCheckboxes = hours.map((checkbox) => {
      if (checkbox.id === event.target.id) {
        return { ...checkbox, checked: event.target.checked };
      }
      return checkbox;
    });
    this.setState({ hours: updatedCheckboxes });

    const allChecked = updatedCheckboxes.every((checkbox) => checkbox.checked);
    this.setState({ selectAllHours: allChecked }, this.updateCommonState);
  };

  handleSelectAllHoursChange = (event) => {
    const { hours } = this.state;
    const updatedCheckboxes = hours.map((checkbox) => ({ ...checkbox, checked: event.target.checked }));
    this.setState({ hours: updatedCheckboxes, selectAllHours: event.target.checked }, this.updateCommonState);
  };

  handleSelectAllDaysChange = (event) => {
    const { weekdays } = this.state;
    const updatedWeekdays = weekdays.map((checkbox) => ({ ...checkbox, checked: event.target.checked }));
    this.setState({ weekdays: updatedWeekdays, selectAllWeekdays: event.target.checked, selectWeekend: event.target.checked,
      selectWeekdays: event.target.checked }, this.updateCommonState);
  };

  handleSelectAllSimpleMonthsChange = (event) => {
    const { simpleMonths } = this.state;
    const updatedSimpleMonths = simpleMonths.map((checkbox) => ({ ...checkbox, checked: event.target.checked }));
    this.setState({ simpleMonths: updatedSimpleMonths, selectAllSimpleMonths: event.target.checked }, this.updateCommonState);
  };

  monthChanger = (event) => {
    const { monthDays } = this.state;
    const [month, day] = event.target.id.split('-');
    const updatedMonthDays = monthDays.slice();

    let monthIndex = -1;

    updatedMonthDays.forEach((mon, index) => {
        if(mon[0].id.split('-')[0] === month){
          monthIndex = index;
        }
    });

    let slicedMonth = updatedMonthDays[monthIndex].slice();

    let index = slicedMonth.findIndex(f => f.id === event.target.id);

    if (index !== -1) {
      let itemCopy = Object.assign({}, slicedMonth[index]);
      itemCopy.checked = event.target.checked;
      slicedMonth[index] = itemCopy;
    }

    updatedMonthDays[monthIndex] = slicedMonth;
    this.setState({ monthDays: updatedMonthDays }, this.updateCommonState);
  }

  handleAdvancedModeChange = (event) => {
    this.setState({ advancedMode: event.target.checked }, this.updateCommonState);
  }

  handleSelectWeekendChange = (event) => {
    const { weekdays } = this.state;
    const updatedWeekdays = weekdays.map((weekday) => {
      if (weekday.id === 'Saturday' || weekday.id === 'Sunday') {
        return { ...weekday, checked: event.target.checked };
      }
      return weekday;
    });
    this.setState({ weekdays: updatedWeekdays, selectWeekend: event.target.checked });

    const allChecked = updatedWeekdays.every((weekday) => weekday.checked);
    this.setState({ selectAllWeekdays: allChecked }, this.updateCommonState);
  };

  handleSelectWeekdaysChange = (event) => {
    const { weekdays } = this.state;
    const updatedWeekdays = weekdays.map((weekday) => {
      if (weekday.id !== 'Saturday' && weekday.id !== 'Sunday') {
        return { ...weekday, checked: event.target.checked };
      }
      return weekday;
    });
    this.setState({ weekdays: updatedWeekdays, selectWeekdays: event.target.checked });

    const allChecked = updatedWeekdays.every((weekday) => weekday.checked);
    this.setState({ selectAllWeekdays: allChecked }, this.updateCommonState);
  };

  handleWeekdayCheckboxChange = (event) => {
    const { weekdays } = this.state;
    const updatedWeekdays = weekdays.map((weekday) => {
      if (weekday.id === event.target.id) {
        return { ...weekday, checked: event.target.checked };
      }
      return weekday;
    });

    this.setState({ weekdays: updatedWeekdays });

    const allChecked = updatedWeekdays.every((checkbox) => checkbox.checked);
    this.setState({ selectAllWeekdays: allChecked });

    const satChecked = updatedWeekdays.find((weekday) => weekday.id === 'Saturday').checked;
    const sunChecked = updatedWeekdays.find((weekday) => weekday.id === 'Sunday').checked;
    this.setState({ selectWeekend: satChecked && sunChecked }, this.updateCommonState);

    const weekdaysChecked = updatedWeekdays
      .filter((weekday) => weekday.id !== 'Saturday' && weekday.id !== 'Sunday')
      .every((weekday) => weekday.checked);
    this.setState({ selectWeekdays: weekdaysChecked }, this.updateCommonState);
  };

  handleSimpleMonthCheckboxChange = (event) => {
    const { simpleMonths } = this.state;
    const updatedSimpleMonths = simpleMonths.map((month) => {
      if (month.id === event.target.id) {
        return { ...month, checked: event.target.checked };
      }
      return month;
    });
    this.setState({ simpleMonths: updatedSimpleMonths });

    const allChecked = updatedSimpleMonths.every((month) => month.checked);
    this.setState({ selectAllSimpleMonths: allChecked }, this.updateCommonState);

  };

  render() {
    const { hours: checkboxes } = this.state;

    return (
      <div>
          <FormControlLabel
            control={
              <Switch
              checked={this.state.advancedMode}
              onChange={this.handleAdvancedModeChange}
              disabled={this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimitsViewedEditable}
              />}
              label={this.props.t("hourLinesComponent:label_advanced_mode")} 
        />
        <br/> 
        { !this.state.advancedMode && <>
          <div >
            <div style={{ marginRight: '1rem' }}>
              <label>{this.props.t("hourLinesComponent:label_months")}</label>
            </div>
            <br/>
            <div>
              <div style={{ marginRight: '1rem' }}>
                <input type="checkbox" id="selectAllMonths" name="selectAllMonths" checked={this.state.selectAllSimpleMonths} onChange={this.handleSelectAllSimpleMonthsChange} disabled={this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimitsViewedEditable}/>
                <label id={"selectAllMonths"+this.props.index} style={{ marginLeft: '5px' }} htmlFor={"selectAllMonths"+this.props.index}>{this.props.t("hourLinesComponent:label_select_all")}</label>
              </div>
              <div style={{ display: 'flex' }}>
                {months.filter((month, index) => index + 1 >= this.state.countingRuleStart.split('.')[1] && index + 1 <= this.state.countingRuleEnd.split('.')[1]).map((month) => (
                  <div style={{ marginRight: '1rem' }} key={month}>
                  <input type="checkbox" id={month} name={month} checked={this.state.simpleMonths.findIndex(f => f.id === month) !== -1 && this.state.simpleMonths[this.state.simpleMonths.findIndex(f => f.id === month)].checked} onChange={this.handleSimpleMonthCheckboxChange} disabled={this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimitsViewedEditable}/>
                  <label id={month+this.props.index} style={{ marginLeft: '5px' }} htmlFor={month+this.props.index}>{this.props.t(`hourLinesComponent:${month}`)}</label>
                </div>
                ))}  
              </div>
            </div>
          </div>
          <br/>
          <div >
            <div style={{ marginRight: '1rem' }}>
              <label>{this.props.t("hourLinesComponent:label_days")}</label>
            </div>
            <br/> 
            <div style={{ marginRight: '1rem' }}>
              <input type="checkbox" id="selectAllDays" name="selectAllDays" checked={this.state.selectAllWeekdays} onChange={this.handleSelectAllDaysChange} disabled={this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimitsViewedEditable}/>
              <label id={"selectAllDays"+this.props.index} style={{ marginLeft: '5px' }} htmlFor="selectAllDays">{this.props.t("hourLinesComponent:label_select_all")}</label>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: '1rem' }}>
                <input type="checkbox" id={"weekdays"+this.props.index} name="weekdays" checked={this.state.selectWeekdays} onChange={this.handleSelectWeekdaysChange} disabled={this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimitsViewedEditable}/>
                <label id={"weekdays"+this.props.index} style={{ marginLeft: '5px' }} htmlFor="weekdays">{this.props.t("hourLinesComponent:label_weedays")}</label>
              </div>
              <div style={{ marginRight: '1rem' }}>
                <input type="checkbox" id={"weekend"+this.props.index} name="weekend" checked={this.state.selectWeekend} onChange={this.handleSelectWeekendChange} disabled={this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimitsViewedEditable}/>
                <label id={"weekend"+this.props.index} style={{ marginLeft: '5px' }} htmlFor="weekend">{this.props.t("hourLinesComponent:label_weekends")}</label>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
                {weekdays.map((day, index) => (
                  <div style={{ marginRight: '1rem' }} key={day}>
                  <input type="checkbox" id={day} name={day} checked={typeof this.state.weekdays[index] !== 'undefined' && this.state.weekdays[index].checked} onChange={this.handleWeekdayCheckboxChange} disabled={this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimitsViewedEditable}/>
                  <label id={day + this.props.index} style={{ marginLeft: '5px' }} htmlFor={day + this.props.index}>{this.props.t(`hourLinesComponent:${day}`)}</label>
                </div>
                ))}  
            </div>
           </div>
          <br/>
        </>}
          <div>
            <label>{this.props.t("hourLinesComponent:label_hours")}</label>
          </div>
          <br/>
          <div style={{ marginRight: '1rem' }}>
            <input type="checkbox" id={"selectAllHours"+this.props.index} name="selectAllHours" checked={this.state.selectAllHours} onChange={this.handleSelectAllHoursChange} disabled={this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimitsViewedEditable}/>
            <label id={"selectAllHours"+this.props.index} style={{ marginLeft: '5px' }} htmlFor="selectAllHours">{this.props.t("hourLinesComponent:label_select_all")}</label>
          </div>         
          <div style={{ display: 'flex' }}>
            {checkboxes.slice(0, 12).map((checkbox) => (
              <div key={checkbox.id} style={{ marginRight: '1rem' }}>
                <label id={checkbox.id} htmlFor={checkbox.id}>{checkbox.label}</label>
                <br />
                <input type="checkbox" id={checkbox.id} name={checkbox.id}  checked={checkbox.checked} onChange={this.handleHourSelectChange} disabled={this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimitsViewedEditable}/>
              </div>
            ))}
          </div>
          <div style={{ display: 'flex' }}>
            {checkboxes.slice(12).map((checkbox) => (
              <div key={checkbox.id} style={{ marginRight: '1rem' }}>
                <label id={checkbox.id} htmlFor={checkbox.id}>{checkbox.label}</label>
                <br />
                <input type="checkbox" id={checkbox.id} name={checkbox.id} checked={checkbox.checked} onChange={this.handleHourSelectChange} disabled={this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimitsViewedEditable}/>
              </div>
            ))}
          </div>
         { this.state.advancedMode && <>
          <br/>
          <div style={{ marginRight: '1rem' }}>
            <label>{this.props.t("hourLinesComponent:label_months")}</label>
          </div>
          <br/>
          <div>
            {months.filter((month, index) => index + 1 >= this.state.countingRuleStart.split('.')[1] && index + 1 <= this.state.countingRuleEnd.split('.')[1]).map((month, index) => (
              <MonthCheckboxGroup key={index} month={month} daysInMonth={this.state.monthDays[index]} onCheckboxChange={this.monthChanger} disabled={this.props.storeHelper.getState().countingRuleTimeLimitsSelector.times.timeLimitsViewedEditable} t={this.props.t}/>
            ))}
            <br/>
          </div> </>}
      </div>
    );
  }
}export default withStore(HourLineSelection);