import React from "react";
import MultiChoiceSearchDropdown from "../widgets/MultiChoiceSearchDropdown/MultiChoiceSearchDropdown";
import {useTranslation} from "react-i18next";

import './AddFlow.css';
import ViewFlow from "./ViewFlow";
import ValidatedInputField from "../widgets/ValidatedInputField/ValidatedInputField";
import {useMsal} from "@azure/msal-react";
import {useNavigate, useParams} from "react-router-dom";
import ModalComponent from "../widgets/ModalComponent/ModalComponent";
import {AxiosError} from "axios";
import {
    GetFlow,
    InsertFlow,
    DeleteFlow,
    UpdateFlow,
    GetSites,
    GetCodeClasses,
    GetCounts, GetCountsByFlowId, GetTotalCountOfCounts
} from '../utils/NastaApiClient';

import {getCodeText} from '../utils/CodeHelpers';
import FormController from "../widgets/FormController/FormController";
import close_fill from '../icons/close_fill.svg';
import Count from "../dataClasses/Count";

import { useSelector, useDispatch, useStore} from 'react-redux';
import * as codesSelector from '../mapViewOl/codesSelector';
import {getUserRoles} from "../utils/RoleHelpers";
import Button from "@mui/material/Button";
import {IconButton, Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import UploadingNotification from "../widgets/UploadingNotification/UploadingNotification";

const viewModes = {
    1: "view",
    2: "edit"
}

const withRouter = WrappedComponent => props => {
    const params = useParams();
    const {instance} = useMsal();
    const navigate = useNavigate();
    const {t} = useTranslation('addFlow', 'common')
    const codes = useSelector((state) => state.codes);
    const dispatch = useDispatch();
    const storeHelper = useStore();

    return (
        <WrappedComponent
            t={t}
            {...props}
            {...{instance, /* other injected props */}}
            params={params}
            navigate={navigate}
            dispatch={dispatch}
            storeHelper={storeHelper}
            codes={codes}
        />
    );
};

class AddFlow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nonce: crypto.randomUUID(),
            submitAttempted: false,
            showSubmitModal: false,
            showErrorModal: false,
            showDeletionModal:false,
            choices: [],
            currentSearchValue: "",
            viewMode: typeof props !== 'undefined' && typeof props.boundData.viewMode !== 'undefined' ? props.boundData.viewMode : (typeof this.props.params.id !== 'undefined'?2:1),
            destination: {},
            calcPlaces:[],
            dataLoads:{},
            allValid:false,
            counts:null,
            choicesCleared:false,
            isSubmitting:false,
            flow: {
                id: 0,
                flowName: "",
                siteId: 0,
                calibrationCoefficient: null,
                userTypeCodeId: 0,
                UserTypeCodeClassId:0,
                FlowDirectionTypeCodeId:0,
                FlowDirectionTypeCodeClassId:0,
                createdBy:"",
                modifiedBy:"",
                description:""
            },
            editSuccesfulModalOpen:false,
            createSuccesfulModalOpen:false,
            createSuccesfulModalLocation:null,
            submitErrorsModalOpen: false,
            submitErrorsModalTitle: "",
            submitErrorsModalContent: ""
        }
        this.loadData=this.loadData.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.getItemsFromCodeClass = this.getItemsFromCodeClass.bind(this);
        this.getTheRest = this.getTheRest.bind(this);
        this.setSite = this.setSite.bind(this);
    }

    validateString(x) {
        if (typeof x === 'string' || x instanceof String) {
            if (x.length < 1) {
                throw new Error("Field length too low (0): " + x)
            }
            return x
        } else {
            throw new TypeError("invalid field value for: " + x + " (string required)")
        }
    }

    validateBoolean(x) {
        if (typeof x == "boolean") {
            return x
        } else {
            throw new TypeError("invalid field value for: " + x + " (boolean required)")
        }
    }

    validateInteger(x) {
        if (Number.isInteger(x)) {
            return x
        } else {
            try {
                return parseInt(x);
            } catch (e) {
                throw new Error("Field type not applicable integer");
            }
        }
    }

    async getTheRest(){


        this.setState({dataLoads: {...this.state.dataLoads, "codeClasses":false}})

        if(!this.props.storeHelper.getState().codesSelector.codes.codeClassesLoaded){
            let response = await GetCodeClasses();

            if (response instanceof AxiosError) {

                this.setState({error: response});
                this.props.dispatch(codesSelector.changeCodeClassesLoaded(false));
            } else {

                this.props.dispatch(codesSelector.fillCodeClasses(response));
                this.props.dispatch(codesSelector.changeCodeClassesLoaded(true));

                this.setState({
                    dataLoads:{...this.state.dataLoads, "codeClasses":true}
                }, async ()=>{


                    this.setState({dataLoads: {...this.state.dataLoads, "sites":false, "counts": false}})

                    let sites = await GetSites();

                    let counts = null;
                    if(typeof this.props.params.id !== 'undefined'){
                        counts = await GetTotalCountOfCounts(this.props.params.id);

                    }

                    if(sites instanceof AxiosError || counts instanceof AxiosError){
                        this.setState({submitErrorsModalOpen:true, submitErrorsModalTitle:"", submitErrorsModalContent:"Retrieving codeClasses failed:" + sites});
                    }else{
                        this.setState({
                            ...this.state,
                            calcPlaces: sites.data.map((site)=>{
                                return {
                                    id:site.siteId.toString(),
                                    title: site.siteId.toString(),
                                    name:site.siteName,
                                    status: site.status?.codeId
                                }
                            }),
                            counts: counts,
                            dataLoads:{...this.state.dataLoads, "sites":true, counts: true}
                        });
                    }

                });
            }
        }else{
            let sites = await GetSites();
            let counts = null;
            if(typeof this.props.params.id !== 'undefined'){
                counts = await GetTotalCountOfCounts(this.props.params.id);

            }

            if(sites instanceof AxiosError || counts instanceof AxiosError){
                this.setState({submitErrorsModalOpen:true, submitErrorsModalTitle:"", submitErrorsModalContent:"Retrieving codeClasses failed:" + sites});
            }else{
                this.setState({
                    ...this.state,
                    calcPlaces: sites.data.map((site)=>{
                        return {
                            id:site.siteId.toString(),
                            title: site.siteId.toString(),
                            name:site.siteName,
                            status: site.status?.codeId
                        }
                    }),
                    counts: counts,
                    dataLoads:{...this.state.dataLoads, "sites":true, counts: true}
                });
            }
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if(typeof this.state?.flow?.calibrationCoefficient!=='undefined'){
            if(Number.isNaN(this.state.flow.calibrationCoefficient)){
                this.setState({flow:{...this.state.flow, calibrationCoefficient:null}})
            }
        }
    }

    async loadData(){


        this.setState(state => {
            state.flow.createdBy = typeof this.props.instance.getAllAccounts()[0] !== 'undefined' ? this.props.instance.getAllAccounts()[0].name : '';
            state.flow.modifiedBy = typeof this.props.instance.getAllAccounts()[0] !== 'undefined' ? this.props.instance.getAllAccounts()[0].name : '';
            return state
        });


        if(typeof this.props.params.id !== 'undefined'){
            this.setState({dataLoads: {...this.state.dataLoads, "customFlowId":false}});


            let response = await GetFlow(this.props.params.id);

            if(response instanceof AxiosError){
                localStorage.setItem("flowListShouldReload", true);
                this.setState({viewMode:1, editSuccesfulModalOpen:true, isSubmitting:false});
               // this.props.navigate("/flow/list");
            }else{
                this.setState({...this.state,
                    flow: {...response.data, calibrationCoefficient:response.data.calibrationCoefficient!==null?response.data.calibrationCoefficient.toFixed(2).toString().replace(".",","):""},
                    dataLoads:{...this.state.dataLoads, "customFlowId":true},
                    nonce: crypto.randomUUID()
                }, ()=>this.getTheRest());
            }
        }else{
            await this.getTheRest();
        }

    }

    getItemsFromCodeClass(codeClassName) {
        if (this.props.storeHelper.getState().codesSelector.codes.codeClasses.length > 0) {
            const codeClass = this.props.storeHelper.getState().codesSelector.codes.codeClasses.find(cc => cc.name === codeClassName);
            return codeClass.codes.map((code) => {
                const foundClass = code.languages.find(c => c.languageName === this.props.t('common:GMLLanguage'));
                if (typeof foundClass !== 'undefined') {
                    return {
                        id: code.codeId,
                        name: foundClass.text,
                        title: code.codeId.toString()
                    }
                }
            })
        }
    }

    showDeletionModal(){
        this.setState({showDeletionModal:true});
    }

    async deleteFlow(){
        if(typeof this.props.params.id !== 'undefined'){
            let response = await DeleteFlow(this.props.params.id);
            if(response instanceof AxiosError){
                this.setState({submitErrorsModalOpen:true, submitErrorsModalTitle:"", submitErrorsModalContent:"Sending failed:" + response});
                this.setState({showDeletionModal:false});
            }else{
                localStorage.setItem("flowListShouldReload", true);
                this.setState({editSuccesfulModalOpen:true, isSubmitting:false, showDeletionModal:false}, () => this.props.navigate("/flow/list"));
            }
        }
    }

    async handleSubmit(event) {

        this.setState({submitAttempted:true, isSubmitting:true});
        if(this.state.flow.siteId===0){
            let tmpFlow=this.state.flow;
            delete tmpFlow.siteId;
            this.setState({flow: tmpFlow});
        }
        if (this.state.allValid===false || (this.state.flow.calibrationCoefficient<0.00 || this.state.flow.calibrationCoefficient>100.0 )) {
            this.setState({submitErrorsModalOpen:true, submitErrorsModalTitle:"", submitErrorsModalContent:this.props.t("common:one_or_more_required_field_is_invalid")});
            this.setState({isSubmitting:false})
            return false;
        }


        if(typeof this.props.params.id !== 'undefined'){
            let tmpFlow = this.state.flow;
            let testOnlyContainsInts=new RegExp('^[0-9][0-9]*(,[0-9]+)?$');
            if (testOnlyContainsInts.test(tmpFlow.calibrationCoefficient)) {

                tmpFlow.calibrationCoefficient = tmpFlow.calibrationCoefficient.replace(",", ".");
            } else {
                tmpFlow.calibrationCoefficient=null;
            }

            tmpFlow.modifiedBy = typeof this.props.instance.getAllAccounts()[0] !== 'undefined' ? this.props.instance.getAllAccounts()[0].name : '';
            delete(tmpFlow.site);

            let response = await UpdateFlow(this.props.params.id,{
                ...tmpFlow,
                nonce: this.state.nonce
            } );

            if(response instanceof AxiosError){
                this.setState({submitErrorsModalOpen:true, submitErrorsModalTitle:this.props.t("common:submit_error_title"), submitErrorsModalContent:this.props.t("common:submit_error_content") + response.message});
                localStorage.setItem("flowListShouldReload", true);
                this.setState({isSubmitting:false}, ()=>{
                    this.setState({editSuccesfulModalOpen:true, isSubmitting:false});
                });

            }else{
                this.setState({isSubmitting:false}, ()=>{

                    localStorage.setItem("flowListShouldReload", true);
                    this.setState({editSuccesfulModalOpen:true, isSubmitting:false, viewMode:1}, ()=>{

                        this.loadData();
                    });
                })
            }
        }else{

            let response = await InsertFlow({...this.state.flow,
                nonce: this.state.nonce,
                calibrationCoefficient:(this.state.flow.calibrationCoefficient!==null?parseFloat(this.state.flow.calibrationCoefficient.toString().replace(",",".")):null)
            });


            if(response instanceof AxiosError){
                this.setState({submitErrorsModalOpen:true, submitErrorsModalTitle:this.props.t("common:submit_error_title"), submitErrorsModalContent:this.props.t("common:submit_error_content") + response.message});
            }else{
                this.setState({isSubmitting:false}, ()=>{
                    localStorage.setItem("flowListShouldReload", true);
                    this.setState({createSuccesfulModalOpen:true, isSubmitting:false, createSuccesfulModalLocation:response.data})
                })

            }

        }

    }





    async setSite(id){


        this.setState({flow:{...this.state.flow,
                siteId: id
        }});
    }
    render() {
        let {t} = this.props;
        let {customFlowId, sites, counts} = this.state.dataLoads;
        let codeClassesLoaded = this.props.storeHelper.getState().codesSelector.codes.codeClassesLoaded;

        return (
            <>

                <ModalComponent
                    showModal={this.state.submitErrorsModalOpen}
                    title={this.state.submitErrorsModalTitle}
                    content={this.state.submitErrorsModalContent}
                    modalType={"errorModal"}
                    onModalContinue={() => {
                        this.setState({viewMode: 1, submitErrorsModalOpen:false});
                    }}
                />
                <ModalComponent
                    showModal={this.state.editSuccesfulModalOpen}
                    title={t("edit_succesful_title")}
                    content={t("edit_succesful_content")}
                    modalType={"errorModal"}
                    onModalContinue={() => {
                        this.setState({editSuccesfulModalOpen: false,viewMode:2})

                    }}

                />

                <ModalComponent
                    showModal={this.state.createSuccesfulModalOpen}
                    title={t("create_succesful_title")}
                    content={t("create_succesful_content")}
                    modalType={"errorModal"}
                    onModalContinue={async () => {
                        this.setState({createSuccesfulModalOpen:false})
                        this.props.navigate("/flow/"+this.state.createSuccesfulModalLocation);
                        window.location.reload();
                    }}

                />

                <UploadingNotification type={"upload"}  showModal={this.state.isSubmitting} />
                <ModalComponent showModal={this.state.submitAttempted && !this.state.isSubmitting}
                                title={this.props.params.id ? t('addFlow:title_editingModal') : t('addFlow:title_creatingModal')}
                                content={this.props.params.id ? t('addFlow:content_editingModal') : t('addFlow:content_createModal')}
                                modalType={"submitModal"}
                                onModalContinue={async () => {
                                    await this.handleSubmit();
                                    this.setState({submitAttempted: false});
                                }}
                                onModalCancel={() => {
                                    this.setState({submitAttempted: false})
                                }}
                />

                {((this.props.params.id?customFlowId:true) && codeClassesLoaded && sites && counts) && <>

                {this.state.viewMode === 1 ? <>

                        <br/>
                        <form>


                            <FormController onValidityChanged={(validity)=>this.setState({allValid:validity})}>




                                <MultiChoiceSearchDropdown disabled={getUserRoles()==='Nasta.Administrator'?false:!((typeof this.state.counts!=='undefined' &&this.state.counts!==null)?this.state.counts===0:true)}
                                                           inputData={(typeof this.props.params.id!== 'undefined' ?this.state.calcPlaces :this.state.calcPlaces
                                                               .filter(x=>x.status===3)
                                                               .sort((a,b)=>parseInt(a.siteId)>parseInt(b.siteId)?1:-1)
                                                           )}
                                                           title={t('calcPlace_name')}
                                                           tooltip={t('tooltip_calcPlace')}

                                                           minChoicesFixed={false}
                                                           defaultSelected={[(this.state.calcPlaces.find(x=>{
                                                               return parseInt(x.id)===parseInt(this.state.flow.siteId)
                                                           }))]}
                                                           minChoices={1}
                                                           required={false}
                                                           maxChoices={3}
                                                           validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                                           onChoicesChange={(choices) => {

                                                               if(choices.length>0){
                                                                   this.setSite(choices[0].id);
                                                               }else{
                                                                   this.setSite(null);
                                                               }

                                                           }}
                                                           onValidityChange={(validity)=>{
                                                               this.setState({flowValid:{...this.state.flowValid, siteId:validity}})
                                                           }}
                                />


                                {/*
                Lukemavirran numero (seuraava kyseisellä laskentapaikalla vapaana oleva numero, kts. Tietosisältö)*/}
                                <label className="form-label"
                                       htmlFor="flowId">{t('flow_id')}


                                    {t('tooltip_flow_id')!=='' && t('tooltip_flow_id')!==null &&
                                    <Tooltip title={t('tooltip_flow_id')}>
                                        <IconButton>
                                            <InfoIcon/>

                                        </IconButton>
                                    </Tooltip>
                                    }
                                </label>
                                <p id={"flowId"}>{this.state.flow.flowId}</p>

                                <ValidatedInputField
                                    name={"flowName"}
                                    fieldName={this.props.t('flow_name')}
                                    tooltip={this.props.t('tooltip_flow_name')}

                                    required={true}
                                    minLength={1}
                                    maxLength={100}
                                    type={"text"}
                                    fieldId={'flowName'}
                                    value={this.state.flow.flowName}
                                    validatorFunction={(x) => {
                                        return true
                                    }}
                                    onFieldValueChanged={(newValue) => {
                                        this.setState({flow: {...this.state.flow, flowName: newValue}})
                                    }}
                                    validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                    onFieldValidityChanged={(validity) => {
                                        this.setState({flowValid: {...this.state.flowValid, flowName: validity}});
                                        }
                                    }
                                />



                                <MultiChoiceSearchDropdown active={((typeof this.state.counts!=='undefined' &&this.state.counts!==null)?this.state.counts===0:true) ||getUserRoles()==='Nasta.Administrator'}
                                                           inputData={(codeClassesLoaded ? this.getItemsFromCodeClass("flowDirection") : [])}
                                                           title={t('flowDirection')}
                                                           tooltip={t('tooltip_flowDirection')}

                                                           minChoicesFixed={false}
                                                           defaultSelected={[((typeof this.state.flow.flowDirectionType!=='undefined' && this.state.flow.flowDirectionType!==null) && {id:this.state.flow.flowDirectionType.codeId, title:this.state.flow.flowDirectionType.codeId, name:this.state.flow.flowName})]}
                                                           minChoices={1}
                                                           required={true}
                                                           maxChoices={1}
                                                           validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                                           onChoicesChange={(choices) => {
                                                               this.setState({flow:{...this.state.flow,
                                                                       FlowDirectionTypeCodeId: (choices.length > 0 ? parseInt(choices[0].id) : null),
                                                                       FlowDirectionTypeCodeClassId:this.props.storeHelper.getState().codesSelector.codes.codeClasses.find(cc => cc.name === "flowDirection").codeClassId
                                                                   }})
                                                           }}
                                                           onValidityChange={(validity)=>{
                                                               this.setState({flowValid:{...this.state.flowValid, FlowDirectionTypeCodeId:validity}})
                                                           }}
                                />



                                <MultiChoiceSearchDropdown active={((typeof this.state.counts!=='undefined' &&this.state.counts!==null)?this.state.counts===0:true) ||getUserRoles()==='Nasta.Administrator'}
                                                           inputData={(codeClassesLoaded ? this.getItemsFromCodeClass("userType") : [])}
                                                           title={t('visitor_type')}
                                                           tooltip={t('tooltip_visitor_type')}

                                                           minChoicesFixed={false}
                                                           defaultSelected={[((typeof this.state.flow.userType!=='undefined' && this.state.flow.userType!==null)&&{id:this.state.flow.userType.codeId, title: this.state.flow.userType.codeId })]}
                                                           minChoices={1}
                                                           required={false}
                                                           maxChoices={1}
                                                           validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                                           onChoicesChange={(choices) => {
                                                               this.setState({flow:{...this.state.flow,
                                                                       UserTypeCodeId: (choices.length > 0 ? parseInt(choices[0].id) : null),
                                                                       UserTypeCodeClassId:this.props.storeHelper.getState().codesSelector.codes.codeClasses.find(cc => cc.name === "userType").codeClassId
                                                                   }})
                                                           }}
                                                           onValidityChange={(validity)=>{
                                                               this.setState({flowValid:{...this.state.flowValid, userTypeCodeId:validity}})
                                                           }}
                                />


                                <ValidatedInputField
                                    name={"calibrationCoefficient"}
                                    fieldName={this.props.t('calibrationCoefficient_name')}
                                    tooltip={this.props.t('tooltip_calibrationCoefficient_name')}

                                    required={false}
                                    minLength={1}
                                    maxLength={6}
                                    type={"text"}
                                    fieldId={'calibrationCoefficient'}
                                    value={this.state.flow.calibrationCoefficient != null ? this.state.flow.calibrationCoefficient.toString().replace(".",",") : ""}
                                    validationErrorMessage={t('value_must_be_between_0_and_100,00')}
                                    validatorFunction={(x) => {

                                        let testOnlyContainsInts=new RegExp('^[0-9]{1,3}(\,?[0-9]{1,2})?$');
                                        if(testOnlyContainsInts.test(x)){
                                            let floated=parseFloat(x.toString().replace(",","."));
                                            return (floated >= 0 && floated <= 100.00);
                                        }

                                        return false
                                    }}
                                    onFieldValueChanged={(newValue) => {
                                        this.setState({flow: {...this.state.flow, calibrationCoefficient: newValue}})
                                    }}
                                    validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                    onFieldValidityChanged={(validity) => {
                                        this.setState({flowValid: {...this.state.flowValid, calibrationCoefficient: validity}})
                                    }}
                                />

                                <ValidatedInputField
                                name={"flowDescription"}
                                fieldName={this.props.t('flow_description')}
                                tooltip={this.props.t('tooltip_flow_description')}
                                multiline={true}

                                required={false}
                                minLength={0}
                                maxLength={1000}
                                type={"text"}
                                fieldId={'flowName'}
                                value={this.state.flow.description}
                                validatorFunction={() => {
                                    return true
                                }}
                                onFieldValueChanged={(newValue) => {
                                    this.setState({flow: {...this.state.flow, description: newValue}})
                                }}
                                validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                onFieldValidityChanged={(validity) => {
                                    this.setState({flowValid: {...this.state.flowValid, description: validity}})
                                }}
                            />

                            <label htmlFor={"createdBy"} className="form-label">{t('creator_name')}
                                {(typeof this.props.t('tooltip_creator_name')!=='undefined' && this.props.t('tooltip_creator_name')!==null && this.props.t('tooltip_creator_name')!=='') &&      
                                <Tooltip title={this.props.t('tooltip_creator_name')}>
                                    <IconButton>
                                        <InfoIcon/>

                                    </IconButton>
                                </Tooltip>}

                            </label>
                                <p id={"createdBy"}>{this.state.flow.createdBy}</p>


                            <label className="form-label"
                                   htmlFor={"changerName"}>{t('changer_name')}
                                   {(typeof this.props.t('tooltip_changer_name')!=='undefined' && this.props.t('tooltip_changer_name')!==null && this.props.t('tooltip_changer_name')!=='') &&
                                <Tooltip title={this.props.t('tooltip_changer_name')}>
                                    <IconButton>
                                        <InfoIcon/>

                                    </IconButton>
                                </Tooltip>}

                            </label>
                            <p id={"changerName"}>{typeof this.props.instance.getAllAccounts()[0] !== 'undefined' ? this.props.instance.getAllAccounts()[0].name : ''}</p>






                            {
                               
                                (typeof this.props.params.id !== 'undefined') && (!(((this.props.params.id?customFlowId:true) && codeClassesLoaded && sites && (this.state.calcPlaces.some(x=>x.id==this.state.flow.siteId)?this.state.calcPlaces.find(x=>x.id==this.state.flow.siteId).status===3:false) || this.state.flow.siteId===0 || this.state.flow.siteId===null)) ||getUserRoles()==='Nasta.Administrator'  || localStorage.getItem("user-role") === "Nasta.ResponsibleUser" ) &&
                                <Button size={"small"} variant={"contained"} type="button" disabled={((localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser") && (this.state.counts!==null && this.state.counts<1)) ? false : true} onClick={() => this.showDeletionModal()}
                                className=" float-left"><img style={{filter:"invert(100%)", height:"1.5vh"}} src="/img/icon/JHT design system/bin_fill.svg" alt={t("")}/>{t("button_delete")}</Button>

                            }
                            {this.state.showDeletionModal&&
                                <ModalComponent
                                    modalType={"submitModal"}
                                    showModal={this.state.showDeletionModal}
                                    title={t('title_flow_deletion_modal')}
                                    content={t('content_flow_deletion_modal')}
                                    onModalCancel={()=>this.setState({showDeletionModal:false})}
                                    onModalContinue={()=>this.deleteFlow()}

                                />
                            }

                            <Button aria-label={t("common:close_button")}
                                    size={"small"}
                                    variant={"contained"}
                                    type="button"
                                    disabled={localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser" ? false : true}
                                    onClick={() => {

                                        this.setState({submitAttempted:true});
                                    }}
                                    className=" float-right">
                                <img style={{filter:"invert(100%)", height:"1.5vh"}} src="/img/icon/JHT design system/check_fill.svg" alt={t("")}/>{t("button_save")}</Button>

                            </FormController>
                        </form>
                    </> :
                   <>
                       
                       {

                       (((this.props.params.id?customFlowId:true) && codeClassesLoaded && sites && (this.state.calcPlaces.some(x=>x.id==this.state.flow.siteId)?this.state.calcPlaces.find(x=>x.id==this.state.flow.siteId).status===3:false) || this.state.flow.siteId===0 || this.state.flow.siteId===null))
                            ||getUserRoles()==='Nasta.Administrator' 

                               ?
                        <Button aria-label={t("common:close_button")} size={"small"}  variant={"contained"} className=" float-right" disabled={localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser" ? false : true}
                                onClick={() => this.setState({viewMode: 1})}><img style={{filter:"invert(100%)", height:"1.5vh"}} src="/img/icon/JHT design system/Pencil Write Alternate.svg" alt={""}/>{t("button_edit")}</Button>:<></>
                       }
                       <br/>
                        <ViewFlow {...this.state.flow} calcPlaces={this.state.calcPlaces}
                                  counts={this.state.counts}
                                  userType={this.getItemsFromCodeClass("userType").find((x)=>x.id===this.state.flow?.userType?.codeId)?.name}
                                  flowDirectionType={this.getItemsFromCodeClass("flowDirection").find((x)=>x.id===this.state.flow?.flowDirectionType?.codeId)?.name}
                        />
                    </>}
                </>}

            </>

        )
    }
}

export default withRouter(AddFlow);