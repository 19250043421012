import React from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {useMsal} from "@azure/msal-react";
import {useTranslation} from "react-i18next";

import './Pagination.css';

const withRouter = WrappedComponent => props => {
    const params = useParams();
    const {instance} = useMsal();
    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation('pagination', 'common')


    return (
        <WrappedComponent
            t={t}
            {...props}
            {...{instance, /* other injected props */}}
            params={params}
            navigate={navigate}
            location={location}
        />
    );
};

class Pagination extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }


    componentDidMount() {
        if (typeof this.props.totalItemCount === 'undefined') {
            console.error("no totalItemCount property on Pagination component");
        }
        if (typeof this.props.divisor === 'undefined') {
            console.error("no divisor property on Pagination component");
        }
        if (typeof this.props.currentPageNumber === 'undefined') {
            console.error("no currentPageNumber property on Pagination component");
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {}

    onPageSelected(pageNumber) {
        if (typeof this.props.onPageSelected === 'undefined') {
            console.warn("no onPageSelected property on Pagination component");
        } else {
            this.props.onPageSelected(pageNumber)
        }
    }

    genPage(pageNumber) {
        return (
            <li className={"page-item standardPaginationWidth " + (this.props.currentPageNumber === pageNumber + 1 ? 'active' : '')}>
                <Link onClick={() => this.onPageSelected(pageNumber)}>{pageNumber + 1}
                </Link>
            </li>
        )
    }

    render() {
        let {totalItemCount, divisor, currentPageNumber} = this.props;
        const maxPages = Math.ceil(totalItemCount / divisor).toFixed(0);

        let pages = [];
        let sideSize=8;
        if(window.innerWidth<800){
            sideSize=4;
        }

        let pageMin = currentPageNumber < sideSize ? 0 : currentPageNumber-(sideSize-1+(maxPages-currentPageNumber<sideSize?sideSize-(maxPages-currentPageNumber):0));
        let pageMax = currentPageNumber > maxPages - sideSize-1 ? maxPages : currentPageNumber + sideSize*2-4-(currentPageNumber>sideSize-1?sideSize-1:currentPageNumber) ;


        for (let i = 0; i < maxPages; i++) {
            if (i >= pageMin && i <= pageMax) {
                pages.push(this.genPage(i));
            }
        }
        return (
            <div>

                {maxPages > 0 && <>

                    <ul>
                        <p style={{textAlign: "center",marginBottom:"-2vh"}}>
                            {((currentPageNumber - 1) * divisor)+1} - {(currentPageNumber - 1) * divisor + divisor < totalItemCount ? (currentPageNumber - 1) * divisor + divisor : totalItemCount} / {totalItemCount}
                        </p>
                    </ul>

                    { totalItemCount>49 &&
                    <ul className="pagination paginationContainer">
                        <li className={"page-item " + (0).toString()}>
                            <Link onClick={() => this.onPageSelected(0)}>1</Link>
                        </li>
                        <li className={"page-item " + (currentPageNumber > 1 ? '' : 'disabled')}>
                            <Link  onClick={() => this.onPageSelected(0)}><i className={"icon icon-arrow-left"}/><i
                                style={{marginLeft: "-10px"}} className={"icon icon-arrow-left"}/></Link>
                        </li>
                        <li className={"page-item " + (currentPageNumber > 1 ? '' : 'disabled')}>
                            <Link  onClick={() => this.onPageSelected(currentPageNumber - 2)} tabIndex="-1"><i
                                className={"icon icon-arrow-left"}/></Link>
                            {maxPages > sideSize*2 && currentPageNumber > sideSize-1 ? <span className={"standardPaginationWidth"}>...</span> : <span className={"standardPaginationWidth"}/>}
                        </li>

                        {pages}
                        <li className={"page-item " + (currentPageNumber < maxPages ? '' : 'disabled')}>
                            {currentPageNumber < maxPages - sideSize ? <span className={"standardPaginationWidth"}>...</span> : <span className={"standardPaginationWidth"}/>}
                            <Link onClick={() => this.onPageSelected(currentPageNumber)}><i
                                className={"icon icon-arrow-right"}/></Link>
                        </li>
                        <li className={"page-item " + (currentPageNumber < maxPages ? '' : 'disabled')}>

                            <Link onClick={() => this.onPageSelected(maxPages - 1)}>
                                <i className={"icon icon-arrow-right"}/>
                                <i style={{marginLeft: "-10px"}} className={"icon icon-arrow-right"}/>
                            </Link>

                        </li>
                        <li className={"page-item " + (maxPages - 1).toString()}>
                            <Link onClick={() => this.onPageSelected(maxPages - 1)}>{maxPages}</Link>
                        </li>
                    </ul>
                    }
                </>
                }
            </div>
        )
    }
}

export default withRouter(Pagination)