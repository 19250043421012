import React from "react";
import {withTranslation} from "react-i18next";
import {Box, Modal, Typography, Button} from "@mui/material";

import './ModalComponent.css';

const modalTypes = ["errorModal", "submitModal"];
class ModalComponent extends React.Component {
    selectedModalType = undefined;

    constructor(props) {
        super(props);
        const requiredFields = ["showModal", "title", "content", "modalType"]
        let missingProps = requiredFields.filter(x =>
            typeof this.props[x] === 'undefined'
        );

        if (missingProps.length > 0) {
            const errorMessage = "required prop " + missingProps.length > 1 ? "s" : " " + missingProps + " missing from ModalComponent";
            throw new Error(errorMessage);
        }

        this.state = {
            showFooter: false
        }


        if (isNaN(this.props.modalType)) {
            if (modalTypes.includes(this.props.modalType)) {
                this.selectedModalType = modalTypes.find(x => x === this.props.modalType);
            } else {
                throw new Error("invalid modalType " + this.props.modalType + ", requires one of " + modalTypes);
            }

        }

        if (!isNaN(this.props.modalType)) {
            if (this.props.modalType <= modalTypes.length - 1) {
                this.selectedModalType = modalTypes[this.props.modalType];
            } else {
                throw new Error("invalid modalType" + this.props.modalType + ", requires index within 0-" + (modalTypes.length - 1) + " [" + modalTypes + "]");
            }
        }

        if (this.selectedModalType === 'submitModal') {

            const requiredFields = ["onModalContinue"]
            let missingProps = requiredFields.filter(x =>
                typeof this.props[x] === 'undefined'
            );

            if (missingProps.length > 0) {
                const errorMessage = "required prop" + missingProps.length > 1 ? "s" : " " + missingProps + " missing from ModalComponent in submitModal mode";
                throw new Error(errorMessage);
            }
        }
    }

    render() {
        const { t } = this.props
        return (
            <>




                <Modal
                    open={this.props.showModal}
                    onClose={()=>this.props.modalType!=="errorModal" && this.props.onModalCancel()}
                    aria-labelledby={this.props.title}
                    aria-describedby={this.props.content}>
                    <Box className={"modal-box-container"}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {this.props.title}
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {this.props.content}

                        </Typography>

                        <br/>
                        {this.props.modalType!=="errorModal" &&
                        <Button size={"small"} variant={"contained"} className="float-left"
                                onClick={() => this.props.onModalCancel()}>{t('cancel')}
                        </Button>
                        }
                        <Button size={"small"} variant={"contained"} className=" float-right"
                                onClick={() => this.props.onModalContinue()}>{t('continue')}
                        </Button>
                    </Box>
                </Modal>

            </>
        )
    }
}
export default withTranslation("modalComponent")(ModalComponent)