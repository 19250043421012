import { createSlice } from '@reduxjs/toolkit';
import HourLineSelection from "./HourLineSelection";

const emptyTimeLimit = {currentIndex: -1,
  parentFlow: -1,
  hours:  [],
  monthDays:   [],
  simpleMonths:  [],
  weekdays:  [],
  advancedMode:   false,
  selectAllSimpleMonths:  true,
  selectAllWeekdays:  true,
  selectWeekend:  true,
  selectWeekdays:  true,
  selectAllHours:  true,
  timeLimitEdited: false,
}

export const countingRuleTimeLimitsSelector = createSlice({
  name: 'countingRuleTimeLimitsSelector',
  initialState: {
    times:{
        timeLimits:[],
        hourLineSelections:[],
        timeLimitsViewedEditable: false,
      }   
  },
  reducers: {
    updateTimeLimitsTable: (state, action) => {
        let copy = state.times.timeLimits.slice();
        
        copy.find(x => x.flowId === action.payload.flowId).hourLines[action.payload.index] = action.payload.data;
        
        let timeLimitEdited = false;
        
        copy.find(x => x.flowId === action.payload.flowId) !== undefined && copy.find(x => x.flowId === action.payload.flowId).hourLines.forEach(element => {
          
        if(element.hours.some(x => x.checked === false)){
          timeLimitEdited = true;
      }

        if(element.monthDays.some(mdays =>  mdays.some(x => x.checked === false))){
          timeLimitEdited = true;
        }
        if(element.simpleMonths.some(x => x.checked === false)){
          timeLimitEdited = true;
        }
        if(element.weekdays.some(x => x.checked === false)){
          timeLimitEdited = true;
        }
      });
      copy.find(x => x.flowId === action.payload.flowId).hourLines[action.payload.index].timeLimitEdited = timeLimitEdited;
      state.times.timeLimits = copy;

    },
    loadDataToTimeLimitsTable: (state, action) => {
      state.times.timeLimits = action.payload;
      let newTimesArray = [];
      
      action.payload.forEach(element => {
        let newHourLineSelections = [];
        for(let i = 0; i < element.hourLines.length; i++){
            if(newHourLineSelections.find(x => x.flowId === element.flowId) === undefined){
              newHourLineSelections.push(<HourLineSelection flowId={element.flowId} key={i} index={i}/>);
            }
        };

        if(newTimesArray.find(x => x.flowId === element.flowId) === undefined){
        {
          newTimesArray.push({flowId: element.flowId, hourLineSelections: newHourLineSelections});
        }}
      });
      state.times.hourLineSelections = newTimesArray;

    },
    addHourLineSelectionTable: (state, action) => {
      state.times.hourLineSelections.push(action.payload);
      if(state.times.timeLimits.find(f => f.flowId === action.payload.flowId) === undefined){
        state.times.timeLimits.push({flowId: action.payload.flowId, hourLines: [emptyTimeLimit]});
      }
    },
    addHourLineSelectionRow: (state, action) => {
      let copy = state.times.hourLineSelections.slice();
      copy.find(x => x.flowId === action.payload.flowId).hourLineSelections.push(action.payload.timeLimit);
      state.times.hourLineSelections = copy;

      state.times.timeLimits.find(f => f.flowId === action.payload.flowId).hourLines.push(emptyTimeLimit);

    },
    deleteHourLineSelectionRow: (state, action) => {

      let copy = state.times.hourLineSelections.slice();
      copy.find(x => x.flowId === action.payload.flowId).hourLineSelections.splice(action.payload.indexToDelete, 1);
      state.times.hourLineSelections = copy;

      let copy2 = state.times.timeLimits.slice();
      copy2.find(x => x.flowId === action.payload.flowId).hourLines.splice(action.payload.indexToDelete, 1);
      state.times.timeLimits = copy2;

    },
    changeEditingState: (state, action) => {
      state.times.timeLimitsViewedEditable = action.payload;
    },
    resetAll: (state, action) => {
      state.times.timeLimits = [];
      state.times.hourLineSelections = [];
      state.times.timeLimitsViewedEditable = false;
    },
  },
})

// Action creators are generated for each case reducer function
export const {updateTimeLimitsTable, addHourLineSelectionTable, addHourLineSelectionRow, deleteHourLineSelectionRow, updateRuleStartingDate, updateRuleEndingDate, loadDataToTimeLimitsTable, changeEditingState, resetAll} = countingRuleTimeLimitsSelector.actions

export default countingRuleTimeLimitsSelector.reducer

/*
  timeLimits: [
    {
      flowId:1,
      timeLimits:[]
    }
  ],
  hourLineSelections: [
    {
      flowId:1,
      hourLineSelections:[]
    }
  ]
*/

