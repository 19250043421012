import React from "react";
import {SignInButton} from "./components/SignInButton";
import { SignOutButton} from "./components/SignOutButton";
import {useMsal, withMsal} from "@azure/msal-react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {loginRequest} from "./authConfig";
const withRouter = WrappedComponent => props => {
    const {instance} = useMsal();
    const {t} = useTranslation('loginView', 'common');

    return (
        <WrappedComponent
            t={t}
            {...props}
            {...{instance, /* other injected props */}}
        />
    );
};
class LogoutView extends React.Component {

    constructor(props) {
        super(props);
        this.state={}
    }



    render(){
        let {t} = this.props;

        return (
            <>
                <div style={{'height':'100vh', 'display':'flex', 'flexDirection':'column', 'justifyContent':'space-around'}} >
                    <div style={{'display':'flex', 'flexDirection':'column', 'alignItems':'center'}}>
                        <img style={{"width":"10em", "height":"8em"}} src={"/img/metsa-logo.png"} alt={t("common:alt_mh_logo")}/>
                        <SignOutButton/>
                    </div>
                </div>
            </>
        )
    }


}export default withRouter(LogoutView)