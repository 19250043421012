//container for /GetFLow api results
import {Site} from "./Site/Site";
import {UserType} from "./UserType";
import {FlowDirectionType} from "./FlowDirectionType";

export class Flow {
    _astaCounterId;
    _calibrationCoefficient;
    _createdBy;
    _createdTime;
    _description;
    _flowDirectionType;
    _flowEcoVisioId;
    _flowGuid;
    _flowId;
    _flowName;
    _modifiedBy;
    _modifiedTime;
    _nonce;
    _site;
    _siteId;
    _userType;


    constructor(astaCounterId, calibrationCoefficient, createdBy, createdTime, description, flowDirectionType, flowEcoVisioId, flowGuid, flowId, flowName, modifiedBy, modifiedTime, nonce, site, siteId, userType) {

        //check for internal params
        if (typeof astaCounterId["astaCounterId"] !== 'undefined' || astaCounterId["astaCounterId"] !== null) {
            this._astaCounterId = astaCounterId["astaCounterId"];
            this._calibrationCoefficient = astaCounterId["calibrationCoefficient"];
            this._createdBy = astaCounterId["createdBy"];
            this._createdTime = astaCounterId["createdTime"];
            this._description = astaCounterId["description"];
            this._flowDirectionType = (astaCounterId["flowDirectionType"] ? new FlowDirectionType(astaCounterId["flowDirectionType"]) : null);
            this._flowEcoVisioId = astaCounterId["flowEcoVisioId"];
            this._flowGuid = astaCounterId["flowGuid"];
            this._flowId = astaCounterId["flowId"];
            this._flowName = astaCounterId["flowName"];
            this._modifiedBy = astaCounterId["modifiedBy"];
            this._modifiedTime = astaCounterId["modifiedTime"];
            this._nonce = astaCounterId["nonce"];
            this._site = (astaCounterId["site"] ? new Site(astaCounterId["site"]) : null);
            this._siteId = astaCounterId["siteId"];
            this._userType = (astaCounterId["userType"] ? new UserType(astaCounterId["userType"]) : null);
        } else {
            this._astaCounterId = astaCounterId;
            this._calibrationCoefficient = calibrationCoefficient;
            this._createdBy = createdBy;
            this._createdTime = createdTime;
            this._description = description;
            this._flowDirectionType = flowDirectionType;
            this._flowEcoVisioId = flowEcoVisioId;
            this._flowGuid = flowGuid;
            this._flowId = flowId;
            this._flowName = flowName;
            this._modifiedBy = modifiedBy;
            this._modifiedTime = modifiedTime;
            this._nonce = nonce;
            this._site = site;
            this._siteId = siteId;
            this._userType = userType;
        }


    }

    get astaCounterId() {
        return this._astaCounterId;
    }

    get calibrationCoefficient() {
        return this._calibrationCoefficient;
    }

    get createdBy() {
        return this._createdBy;
    }

    get createdTime() {
        return this._createdTime;
    }

    get description() {
        return this._description;
    }

    get flowDirectionType() {
        return this._flowDirectionType;
    }

    get flowEcoVisioId() {
        return this._flowEcoVisioId;
    }

    get flowGuid() {
        return this._flowGuid;
    }

    /**
     *
     *
     * @returns {number}
     */
    get flowId() {
        return this._flowId;
    }

    get flowName() {
        return this._flowName;
    }

    get modifiedBy() {
        return this._modifiedBy;
    }

    get modifiedTime() {
        return this._modifiedTime;
    }

    get nonce() {
        return this._nonce;
    }

    get site() {
        return this._site;
    }

    get siteId() {
        return this._siteId;
    }

    get userType() {
        return this._userType;
    }


    set astaCounterId(value) {
        this._astaCounterId = value;
    }

    set calibrationCoefficient(value) {
        this._calibrationCoefficient = value;
    }

    set createdBy(value) {
        this._createdBy = value;
    }

    set createdTime(value) {
        this._createdTime = value;
    }

    set description(value) {
        this._description = value;
    }

    set flowDirectionType(value) {
        this._flowDirectionType = value;
    }

    set flowEcoVisioId(value) {
        this._flowEcoVisioId = value;
    }

    set flowGuid(value) {
        this._flowGuid = value;
    }

    set flowId(value) {
        this._flowId = value;
    }

    set flowName(value) {
        this._flowName = value;
    }

    set modifiedBy(value) {
        this._modifiedBy = value;
    }

    set modifiedTime(value) {
        this._modifiedTime = value;
    }

    set nonce(value) {
        this._nonce = value;
    }

    set site(value) {
        this._site = value;
    }

    set siteId(value) {
        this._siteId = value;
    }

    set userType(value) {
        this._userType = value;
    }
}


