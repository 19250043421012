export class UserType {
    _codeClass;
    _codeClassId;
    codeClassGuid;
    _codeId;
    _description;
    _languages;
    _usageEndTime;
    _usageStartTime;


    constructor(codeClass, codeClassId, codeClassGuid, codeId, description, languages, usageEndTime, usageStartTime) {

        if (typeof codeClass["codeClass"] !== 'undefined' || codeClass !== null) {
            this._codeClass = codeClass["codeClass"];
            this._codeClassId = codeClass["codeClassId"];
            this._codeClassGuid = codeClass["codeClassGuid"];
            this._codeId = codeClass["codeId"];
            this._description = codeClass["description"];
            this._languages = codeClass["languages"];
            this._usageEndTime = codeClass["usageEndTime"];
            this._usageStartTime = codeClass["usageStartTime"];
        } else {
            this._codeClass = codeClass;
            this._codeClassId = codeClassId;
            this._codeClassGuid = codeClassGuid;
            this._codeId = codeId;
            this._description = description;
            this._languages = languages;
            this._usageEndTime = usageEndTime;
            this._usageStartTime = usageStartTime;
        }


    }


    get codeClass() {
        return this._codeClass;
    }

    get codeClassId() {
        return this._codeClassId;
    }

    get codeClassGuid() {
        return this._codeClassGuid;
    }

    get codeId() {
        return this._codeId;
    }

    get description() {
        return this._description;
    }

    get languages() {
        return this._languages;
    }

    get usageEndTime() {
        return this._usageEndTime;
    }

    get usageStartTime() {
        return this._usageStartTime;
    }
}