import { createSlice } from '@reduxjs/toolkit'

export const codesSelector = createSlice({
  name: 'codesSelector',
  initialState: {
    codes:{
        codeClassesLoaded: false,
        codeClasses: [],
    }
  },
  reducers: {
    changeCodeClassesLoaded: (state, action) => {
        state.codes.codeClassesLoaded = action.payload;
    },
    fillCodeClasses: (state, action) => {
        state.codes.codeClasses = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const {changeCodeClassesLoaded, fillCodeClasses} = codesSelector.actions

export default codesSelector.reducer