import React from "react";
import {useTranslation} from "react-i18next";
import {
    Box,
    Modal,
    Button,
    Typography,
    useTheme,
   Paper,
   Divider,
   TableBody,
   TableContainer, 
   Table, 
   TableRow, 
   TableHead, 
   TableCell,
} from "@mui/material";
import {useNavigate, Link} from "react-router-dom";
import 'moment/locale/fi'

const withRouter = WrappedComponent => props => {

    const {t} = useTranslation('countingErrorDialog', 'common');
    const navigate = useNavigate();

    const theme = useTheme();
    return (
        <WrappedComponent
            t={t}
            {...props}
            theme={theme}
            navigate={navigate}
        />
    );
};


class CountingErrorDialog extends React.Component {
    selectedModalType = undefined;

    constructor(props) {
        super(props);
        this.state = {
            counting: props.counting,
            errorFlows: []
        }
    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.counting !== this.props.counting){
            this.setState({counting: this.props.counting});

            if(this.props.counting.errorReason !== undefined && this.props.counting.errorReason !== null && this.props.counting.errorReason !== ""){
                let errorList = JSON.parse(this.props.counting.errorReason);
                this.setState({errorFlows: errorList});
            } 
        }
    }

    formatDate(dateString) {
        let date = new Date(dateString.replace('Z', ''));
        let day = ("0" + date.getDate()).slice(-2);
        let month = ("0" + (date.getMonth() + 1)).slice(-2);
        let year = date.getFullYear();
        let hours = ("0" + date.getHours()).slice(-2);
        let minutes = ("0" + date.getMinutes()).slice(-2);
    
        return day + "." + month + "." + year + " " + hours + ":" + minutes;
    }

    render() {
        const {t} = this.props
        return (
            <>

                <Modal
                    open={this.props.showModal}
                    onClose={() => this.props.onModalCancel()}
                    aria-labelledby={this.props.title}
                    aria-describedby={this.props.content}>
                    <Box className={"modal-box-container"} style={{width:'1000px'}}>

                        <Typography variant={"h6"}>{t("countingErrorDialog:title")}</Typography>
                        <Divider/>

                <TableContainer component={Paper} style={{ maxHeight: 500 }}>
                    <Table sx={{}} aria-label={t("countingErrorDialog:flow_list")}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {t('countingErrorDialog:flow_id')}
                                </TableCell>
                                <TableCell>
                                    {t('countingErrorDialog:flow_name')}
                                </TableCell>
                                <TableCell>
                                    {t('countingErrorDialog:flow_begin_time')}
                                </TableCell>
                                <TableCell>
                                    {t('countingErrorDialog:flow_end_time')}
                                </TableCell>
                                <TableCell>
                                    {t('countingErrorDialog:flow_error')}
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {this.state.errorFlows.map(flow => {
                                return <>
                                    <TableRow key={flow.FlowId}>
                                        <TableCell>
                                            {typeof flow.FlowId !== 'undefined' ?
                                                <Link to={"/flow/" + flow.FlowId}>
                                                    <td className="c-hand"> {flow.FlowId}</td>
                                                </Link> : <td></td>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {flow.FlowName}
                                        </TableCell>
                                        <TableCell>
                                            {this.formatDate(flow.PeriodBeginTime)}
                                        </TableCell>
                                        <TableCell>
                                            {this.formatDate(flow.PeriodEndTime)}
                                        </TableCell>
                                        <TableCell>
                                            Käyntimäärät tyhjiä
                                        </TableCell>
                                    </TableRow>
                                </>
                            })}
                        </TableBody>

                    </Table>
                </TableContainer>
                        

                        <Divider/>
                        <br/>
                            
                        <Button size={"small"} variant={"contained"} className="float-right"
                                onClick={() => this.props.onModalCancel()}>{t('countingErrorDialog:close_button_text')}
                        </Button>
                    </Box>
                </Modal>


            </>
        )
    }
}

export default withRouter(CountingErrorDialog)