import { configureStore } from '@reduxjs/toolkit'
import mapLayerSelector from './mapViewOl/mapLayerSelector'
import destinationsFilterSelector from './mapViewOl/destinationsFilterSelector'
import sitesFilterSelector from './mapViewOl/siteFilterSelector'
import mapCommonsSelector from './mapViewOl/mapCommonsSelector'
import codesSelector from './mapViewOl/codesSelector'
import mapSelectionSelector from './mapViewOl/mapSelectionSelector'
import CountingRuleDropdownDataSelector from './countingRule/CountingRuleDropdownDataSelector'
import countingRuleTimeLimitsSelector from './countingRule/CountingRuleTimeLimitsSelector'
import countingDropdownDataSelector from './counting/CountingDropdownDataSelector'
import applicationCommonsSelector from './ApplicationCommonsSelector'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import placeSelector from './place/placeSelector';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [ 
              'mapCommonsSelector',
              'mapLayerSelector', 
              'placeSelector',
              ]
};

const rootReducer = combineReducers({
    layerSelector: mapLayerSelector,
    destinationsFilterSelector: destinationsFilterSelector,
    sitesFilterSelector: sitesFilterSelector,
    mapCommonsSelector: mapCommonsSelector,
    codesSelector: codesSelector,
    mapSelectionSelector: mapSelectionSelector,
    countingRuleDropdownDataSelector: CountingRuleDropdownDataSelector,
    countingRuleTimeLimitsSelector: countingRuleTimeLimitsSelector,
    countingDropdownDataSelector: countingDropdownDataSelector,
    applicationCommonsSelector: applicationCommonsSelector,
    placeSelector: placeSelector
  });

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
    }),
  });
  
  export const persistor = persistStore(store);
  
  export default store;
