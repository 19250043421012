import React from "react";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from 'react-i18next';
import Button from "@mui/material/Button";

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export const SignOutButton = () => {
    const { instance } = useMsal();
    const { t } = useTranslation(['common']);
    
    const handleLogout = (logoutType) => {
        if (logoutType === "redirect") {
           instance.logoutRedirect({
                account: instance.getActiveAccount(),
                postLogoutRedirectUri: "/",
            });
        }
    }

    return (
        <Button size={"small"} variant={"contained"}  onClick={() => handleLogout("redirect")}>{t('sign_out_button')}</Button>
    );
}