import React from "react";
import {useTranslation} from "react-i18next";

import {Link, useNavigate, useParams} from "react-router-dom";


import {GetFlows, GetSites, GetTotalCountOfCounts, InsertCountsFromCsvFile} from '../utils/NastaApiClient';
import {useMsal} from "@azure/msal-react";
import {Flow} from "../dataClasses/Flow";
import {Site} from "../dataClasses/Site/Site";

import {default as MPagination} from '@mui/material/Pagination';
import SearchBox from "../widgets/SearchBox/SearchBox";
import {
    Input,
    Stack,
    Paper,
    TableContainer,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Tooltip
} from "@mui/material";
import UploadingNotification from "../widgets/UploadingNotification/UploadingNotification";
import Button from "@mui/material/Button";

const withRouter = WrappedComponent => props => {
    const params = useParams();
    const {instance} = useMsal();
    const navigate = useNavigate();
    const {t} = useTranslation('listFlow', 'common')


    return (
        <WrappedComponent
            t={t}
            {...props}
            params={params}

            {...{instance, /* other injected props */}}
            navigate={navigate}
        />
    );
};

class ListFlow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            paginationOffset: 0,
            updatingCountsList: false,
            flowsLoaded: false,
            sitesLoaded: false,
            countsLoaded: false,
            filteredFlowList: [],
            longAwaitedCountsCounter: 0,
            /**
             *
             * @type {Flow[]}
             */
            flowList: [],

            /**
             *
             * @type {Site[]}
             */
            sitesList: [],

            countsList: {}
        }
        this.getMaskedFlowList = this.getMaskedFlowList.bind(this);
        this.updateCountsList = this.updateCountsList.bind(this);
        this.setPagination=this.setPagination.bind(this);
    }

    /**
     *
     * @returns {boolean}
     */
    allLoaded() {
        let {
            flowsLoaded,
            sitesLoaded,
            countsLoaded
        } = this.state;
        return (flowsLoaded && sitesLoaded && countsLoaded);
    }

    async componentDidMount() {
        let sites = GetSites();
        GetFlows().then(response => {
                let tmpCounts = [];
                let tmpFlowList = response.data
                this.setState({flowsLoaded: true, flowList: tmpFlowList}, async () => {

                    let additionalCountsList=[];
                    if(typeof this.props.params.id!=='undefined'){


                        for (const f of tmpFlowList) {
                            if(f.siteId===parseInt(this.props.params.id)){
                                additionalCountsList.push(f.flowId)
                            }
                        }
                    }

                    await this.updateCountsList(additionalCountsList);
                    sites.then(response => {
                        this.setState({sitesLoaded: true, sitesList: response.data.map(site => new Site(site))}, () => {
                            if (typeof this.props.params.id !== 'undefined') {
                                this.setState({flowList: this.state.flowList.filter(flow => flow.siteId === parseInt(this.props.params.id))})
                            }

                        });
                    })

                });

            }
        )
        /*        GetCounts().then(counts=>{


                    this.setState({ countsLoaded:true, countsList:counts.length>0?counts.map(count=>new Count(count)):[]});
                })*/
        if(typeof this.props.params.page!=='undefined'){
            let page = parseInt(this.props.params.page);

            if(page-1!==this.state.paginationOffset){
                this.setState({paginationOffset:(page-1)})
                await this.updateCountsList()
            }
        }
    }

    getMaskedFlowList() {

        let {flowList} = this.state;
        flowList = flowList === undefined ? [] : flowList.sort((a, b) => {
            if (a.flowId < b.flowId) {
                return 1;
            }
            if (a.flowId > b.flowId) {
                return -1;
            }
            return 0;
        })

        let maskedFlowList = flowList;

        if (this.state.filteredFlowList.length > 0) {

            maskedFlowList = maskedFlowList.filter(flow => {
                let found = false;

                for (let i = 0; i < this.state.filteredFlowList.length; i++) {
                    if (flow.flowId === this.state.filteredFlowList[i].id) {
                        found = true;
                        break;
                    }
                }
                return found;
            });

        }
        //     return maskedFlowList.slice(this.state.paginationOffset*50, (this.state.paginationOffset*50)+50);
        return maskedFlowList
    }

    setPagination(pageNumber){
        let page=parseInt(pageNumber)

        let currentFlow="all";
        if(typeof this.props.params.id!=='undefined'){
            currentFlow=parseInt(this.props.params.id);
        }
        this.setState({paginationOffset: page-1}, async ()=>{

            this.props.navigate("/flow/list/"+currentFlow+"/"+page);
            await this.updateCountsList();
        });
    }
    async componentDidUpdate(prevProps, prevState, snapshot) {

        if(typeof this.props.params.page!=='undefined'){
            let page = parseInt(this.props.params.page);

            if(page-1!==this.state.paginationOffset || typeof prevProps.params.page==='undefined'){
                this.setState({paginationOffset:(page-1)}, async ()=>{

                    await this.updateCountsList()
                })

            }
        }

        let shouldReloadData=localStorage.getItem("flowListShouldReload");
        if(shouldReloadData){

            let sites = await GetSites();
            await GetFlows().then(response => {
                    let tmpCounts = [];
                    let tmpFlowList = response.data.map(flow => new Flow(flow))
                    this.setState({flowsLoaded: true, flowList: tmpFlowList}, async () => {


                        let additionalCountsList=[];
                        if(typeof this.props.params.id!=='undefined'){

                            for (const f of tmpFlowList) {
                                if(f.siteId===parseInt(this.props.params.id)){
                                    additionalCountsList.push(f.flowId)
                                }
                            }
                        }

                        await this.updateCountsList(additionalCountsList);
                        sites.then(response => {
                            this.setState({sitesLoaded: true, sitesList: response.data.map(site => new Site(site))}, () => {
                                if (typeof this.props.params.id !== 'undefined') {
                                    this.setState({flowList: this.state.flowList.filter(flow => flow.siteId === parseInt(this.props.params.id))})
                                }

                            });
                        })

                    });
                localStorage.removeItem("flowListShouldReload");
                }

            )
        }
        if (typeof this.props.addButtonPressed !== 'undefined') {
            if (this.props.addButtonPressed === true) {
                this.props.navigate("/flow/add")
            }
        }


    }


    async updateCountsList(additionalIdList) {




            this.setState({updatingCountsList: true})

            let maskedFlowList = this.getMaskedFlowList();


            let tmpCounts = {}

            let reqList = [];


            for (let i = this.state.paginationOffset * 50; i < (this.state.paginationOffset * 50 + 50 > maskedFlowList.length ? maskedFlowList.length : this.state.paginationOffset * 50 + 50); i++) {

                if (typeof this.state.countsList[maskedFlowList[i].flowId] === 'undefined') {
                    reqList.push(
                        GetTotalCountOfCounts(maskedFlowList[i].flowId).then(resp => {
                            tmpCounts[maskedFlowList[i].flowId] = resp;
                        }));
                } else {
                    tmpCounts[maskedFlowList[i].flowId] = this.state.countsList[maskedFlowList[i].flowId];
                }

            }

            if(typeof additionalIdList !== 'undefined'){
                if(!Array.isArray(additionalIdList)){
                    console.warn("additionalIdList set but is not a list");
                }else{


                    for (const addId of additionalIdList) {
                        reqList.push(GetTotalCountOfCounts(addId).then(resp=>{
                            tmpCounts[addId]=resp;
                        }));
                    }
                }
            }

            for (const req of reqList) {
                await req;
            }


            this.setState({countsLoaded: true, countsList: {...this.state.countsList, ...tmpCounts}, updatingCountsList:false})


    }

    async longAwaitedCounts() {


        const lacc = this.state.longAwaitedCountsCounter;
        if (lacc < Date.now() && lacc !== 0) {
            await this.updateCountsList();
        } else {

        }


    }

    render() {
        let {t} = this.props;
        let flowList = this.state.flowList;

        let maskedFlowList = this.getMaskedFlowList();

        if(typeof this.props.params.page!=='undefined'){


            if(parseInt(this.props.params.page-1)*50>maskedFlowList.length && maskedFlowList.length!==0 && parseInt(this.props.params.page)!==1){
                this.setPagination(1);
            }
        }

        let currentPageFlows = maskedFlowList.slice(this.state.paginationOffset * 50, (this.state.paginationOffset * 50) + 50);

        return (

            <>
                <UploadingNotification
                    showModal={this.allLoaded()===false }
                />

                {(this.allLoaded() === true) && <>

                    <SearchBox
                        sortByAttr={"flowId"}
                        titleAttrs={["flowId", "flowName"]}
                        baseLinkUrl={"/flow"}
                        idAttrField={"flowId"}
                        title={""}
                        searchableFields={["flowId",
                            "site",
                            "flowName",
                            "createdBy",
                            "modifiedBy",
                            "description",
                            "createdTime",
                            "modifiedTime",
                            "calibrationCoefficient",
                            "userType",
                            "flowDirectionType"
                        ]}
                        dateAttrFields={["createdTime", "modifiedTime"]}
                        customSearchRuleFields={[
                            ["calibrationCoefficient", (x) => {

                                return x.toString().replace(".", ",");

                            }],
                            ["userType", (x) => {

                                let y = x.languages.find(lang => {
                                    if (lang.languageName === this.props.t('common:GMLLanguage')) {
                                        return lang;
                                    }
                                })
                                return y.text
                            }],

                            ["flowDirectionType", (x) => {
                                let y = x.languages.find(lang => {
                                    if (lang.languageName === this.props.t('common:GMLLanguage')) {
                                        return lang;
                                    }
                                })
                                return y.text

                            }],

                            ["site", (site) => {
                                return site.siteId+" "+site.siteName;

                            }]

                        ]}
                        items={this.state.flowList}
                        onResultsChanged={async (x) => {

                            if (x.length < this.state.paginationOffset * 50) {
                                this.setPagination(1)
                            }
                            this.setState({filteredFlowList: x, longAwaitedCountsCounter: Date.now()}, ()=>{


                                setTimeout(async () => await this.longAwaitedCounts(), 1000)
                            });

                        }}

                    />


                    <Stack alignItems={"center"} spacing={2} >

                        <p style={{textAlign: "center",marginBottom:"-2vh"}}>
                            {((this.state.paginationOffset) * 50)+1} - {(this.state.paginationOffset) * 50 + 50 < maskedFlowList.length ? (this.state.paginationOffset) * 50 + 50 : maskedFlowList.length} / {maskedFlowList.length}
                        </p>
                        <MPagination
                            page={this.state.paginationOffset+1}
                            onChange={async (e,val)=>{

                                this.setState({updatingCountsList:false}, async ()=>{
                                    this.setPagination(val);

                                });



                            }}
                            count={Math.ceil(this.getMaskedFlowList().length/50)}
                            boundaryCount={1}
                            siblingCount={4}
                            color={"primary"} />
                    </Stack>


                    <TableContainer component={Paper}>

                        <Table sx={{}} aria-label={t("flow_list")}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <b>
                                        {t('flow_id')}
                                        </b>
                                    </TableCell>
                                    <TableCell>
                                        <b>
                                        {t('flow_name')}
                                        </b>
                                    </TableCell>
                                    <TableCell>
                                        <b>
                                        {t('related_counts')}, {t('common:amount')}
                                        </b>
                                    </TableCell>
                                    <TableCell>
                                        <b>
                                        {t('flow_site')}
                                        </b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    currentPageFlows.map((flow) => {
                                        return <>

                                            <TableRow key={flow.flowId}>
                                                <TableCell>{flow.flowId}</TableCell>
                                                {typeof flow.flowName !== 'undefined' ?

                                                        <TableCell className="c-hand"> <Link to={"/flow/" + flow.flowId}>{flow.flowName}</Link></TableCell>
                                                     : <TableCell></TableCell>

                                                }


                                                <TableCell>{this.state.countsList[flow.flowId] > 0 ?
                                                    flow.flowId!==null?
                                                    <Link
                                                        to={"/count/list/" + flow.flowId}>{t('related_counts')} ({this.state.countsList[flow.flowId]})</Link>:<>{t('related_counts')} ({this.state.countsList[flow.flowId]})</> :
                                                    t('related_counts') + " (" + (typeof this.state.countsList[flow.flowId]!=='undefined'?this.state.countsList[flow.flowId]:t("common:loading")) + ")"

                                                }</TableCell>
                                                <TableCell>
                                                    <Link
                                                        to={"/sites/" + flow.siteId}>{(this.state.sitesList.some(x => x.siteId === flow.siteId) ? this.state.sitesList.find(x => x.siteId === flow.siteId).siteName : '')}</Link>
                                                </TableCell>
                                            </TableRow>

                                        </>
                                    })
                                }
                            </TableBody>
                        </Table>

                    </TableContainer>


                    <Stack alignItems={"center"} spacing={2} >

                        <p style={{textAlign: "center",marginBottom:"-2vh"}}>
                            {((this.state.paginationOffset) * 50)+1} - {(this.state.paginationOffset) * 50 + 50 < maskedFlowList.length ? (this.state.paginationOffset) * 50 + 50 : maskedFlowList.length} / {maskedFlowList.length}
                        </p>
                        <MPagination
                            page={this.state.paginationOffset+1}
                            onChange={async (e,val)=>{

                                this.setPagination(val);
                                await this.updateCountsList();
                            }}
                            count={Math.ceil(this.getMaskedFlowList().length/50)}
                            boundaryCount={1}
                            siblingCount={4}
                            color={"primary"} />
                    </Stack>

                </>
                }


            </>
        )
    }
}

export default withRouter(ListFlow);