import {UserType} from "../UserType";
import {CodeClass} from "../CodeClass/CodeClass";

export class DestinationType {
    _codeId;
    _codeGuid;
    _codeClassId;
    _description;
    _usageStartTime;
    _usageEndTime;
    /**
     *
     *
     * @private {CodeClass}
     */
    _codeClass;


    constructor(codeId, codeGuid, codeClassId, description, usageStartTime, usageEndTime, codeClass) {

        if (typeof codeId["codeId"] !== 'undefined' || codeId["codeId"] !== null) {
            this._codeId = codeId["codeId"];
            this._codeGuid = codeId["codeGuid"];
            this._codeClassId = codeId["codeClassId"];
            this._description = codeId["description"];
            this._usageStartTime = codeId["usageStartTime"];
            this._usageEndTime = codeId["usageEndTime"];
            this._codeClass = (codeId["codeClass"] ? new CodeClass(codeId["codeClass"]) : null);

        } else {
            this._codeId = codeId;
            this._codeGuid = codeGuid;
            this._codeClassId = codeClassId;
            this._description = description;
            this._usageStartTime = usageStartTime;
            this._usageEndTime = usageEndTime;
            this._codeClass = codeClass;
        }
    }


    get codeId() {
        return this._codeId;
    }

    get codeGuid() {
        return this._codeGuid;
    }

    get codeClassId() {
        return this._codeClassId;
    }

    get description() {
        return this._description;
    }

    get usageStartTime() {
        return this._usageStartTime;
    }

    get usageEndTime() {
        return this._usageEndTime;
    }

    /**
     *
     *
     * @returns {CodeClass}
     */
    get codeClass() {
        return this._codeClass;
    }
}