import React from "react";
import MultiChoiceSearchDropdown from "../widgets/MultiChoiceSearchDropdown/MultiChoiceSearchDropdown";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import {getCodeText} from '../utils/CodeHelpers' 
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider} from "@mui/x-date-pickers";
import moment from "moment-timezone";
import 'moment/locale/fi'

import './AddCounting.css';
import ValidatedInputField from "../widgets/ValidatedInputField/ValidatedInputField";
import {useMsal} from "@azure/msal-react";
import {useNavigate, useParams, Link} from "react-router-dom";
import {AxiosError} from "axios";
import {
    GetCodeClasses,
    GetDestinations,
    GetActiveCountingRulesByDestinationId,
    ExecuteCounting,
    GetCountingById,
    GetCountingRule,
    GetNextCountingStartDate
} from '../utils/NastaApiClient';
import FormController from "../widgets/FormController/FormController";
import * as countingDropdownDataSelector from './CountingDropdownDataSelector';
import InfoIcon from "@mui/icons-material/Info";
import {IconButton, Tooltip} from "@mui/material";

import { useSelector, useDispatch, useStore} from 'react-redux';
import * as codesSelector from '../mapViewOl/codesSelector';
import CountingTimePeriodErrorDialog from "../widgets/CountingTimePeriodErrorDialog/CountingTimePeriodErrorDialog";
import UploadingNotification from "../widgets/UploadingNotification/UploadingNotification";
import CountingDialog from "../widgets/CountingDialog/CountingDialog";
import OverlapCountingErrorDialog from "../widgets/OverlapCountingErrorDialog/OverlapCountingErrorDialog";

const daysInMonths = [31,28,31,30,31,30,31,31,30,31,30,31];

const withRouter = WrappedComponent => props => {
    const params = useParams();
    const {instance} = useMsal();
    const navigate = useNavigate();
    const {t} = useTranslation('addCounting', 'common');
    const codes = useSelector((state) => state.codes);
    const dropdownCommonSelections = useSelector((state) => state.common);
    const dispatch = useDispatch();
    const storeHelper = useStore();

    return (
        <WrappedComponent
            t={t}
            {...props}
            {...{instance, /* other injected props */}}
            params={params}
            navigate={navigate}
            dispatch={dispatch}
            storeHelper={storeHelper}
            codes={codes}
            dropdownCommonSelections={dropdownCommonSelections}
        />
    );
};

class AddCounting extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nonce: crypto.randomUUID(),
            submitAttempted: false,
            showSubmitModal: false,
            showErrorModal: false,
            showDeletionModal:false,
            choices: [],
            currentSearchValue: "",
            viewMode: this.props.additionalAttributes.countingState,
            destinationsLoaded: false,
            sitesLoaded: false,
            dataLoads:{},
            allValid:false,
            countingName: '',
            pageUpdated: false,
            responsibleEmail: '',
            deputyEmail: '',
            additionalInfo: '',
            showSubmitAlert: false,
            submitAlertMessage: '',
            dateErrorMessage: "",
            countingBeginTime: new Date().setHours(0,0,0,0),
            countingEndTime: new Date().setHours(0,0,0,0),
            dateValid: false,
            description: '',
            countingRuleId: -1,
            clearRulesStamp: new Date(),
            errorDialogOpen: false,
            countingErrorDialogOpen: false,
            overlappingCountings: [],
            currentError: '',
            countingLoaded: false,
            isMarkedReady: false,
            dialogOpen: false,
            currentTitle: '',
            currentMessage: '',
            createdBy: typeof this.props.instance.getAllAccounts()[0] !== 'undefined' ? this.props.instance.getAllAccounts()[0].name : '',
            modifiedBy: typeof this.props.instance.getAllAccounts()[0] !== 'undefined' ? this.props.instance.getAllAccounts()[0].name : '',
            createdTime: '',
            modifiedTime: '',
            onModalCancelCountingDialog: ()=>this.setState({dialogOpen:false})}
        this.handleSubmit = this.handleSubmit.bind(this);
        this.GetDestinationSelectionList = this.GetDestinationSelectionList.bind(this);
        this.GetRulesSelectionList = this.GetRulesSelectionList.bind(this);
        this.checkDateValidity = this.checkDateValidity.bind(this);
    }

  

    validateString(x) {
        if (typeof x === 'string' || x instanceof String) {
            if (x.length < 1) {
                throw new Error("Field length too low (0): " + x)
            }
            return x
        } else {
            throw new TypeError("invalid field value for: " + x + " (string required)")
        }
    }

    validateBoolean(x) {
        if (typeof x == "boolean") {
            return x
        } else {
            throw new TypeError("invalid field value for: " + x + " (boolean required)")
        }
    }

    validateInteger(x) {
        if (Number.isInteger(x)) {
            return x
        } else {
            try {
                return parseInt(x);
            } catch (e) {
                throw new Error("Field type not applicable integer");
            }
        }
    }

    async componentDidMount() {
        
        this.props.dispatch(countingDropdownDataSelector.clearAll());
        this.props.dispatch(countingDropdownDataSelector.clearListView());

        try {
            if(!this.props.storeHelper.getState().codesSelector.codes.codeClassesLoaded){
                let response = await GetCodeClasses();

                if (response instanceof AxiosError) {
                    this.setState({error: response});
                    this.props.dispatch(codesSelector.changeCodeClassesLoaded(false));
                } else {
                    this.props.dispatch(codesSelector.fillCodeClasses(response));
                    this.props.dispatch(codesSelector.changeCodeClassesLoaded(true));
                }
            }
        } catch (error) {
            console.log(error);
        }

        try
        {
            let response = await GetDestinations();
            if(response instanceof AxiosError){
                this.setState({error: response});
                this.setState({destinationsLoaded: false});
            }else{
                if(response.length > 0){
                    if(this.state.viewMode === 'view'){
                        this.props.dispatch(countingDropdownDataSelector.changeDestinationList(response.sort((a,b) => b.destinationId - a.destinationId)));
                    }else{
                        this.props.dispatch(countingDropdownDataSelector.changeDestinationList(response.filter(r => r.inUse === true).sort((a,b) => b.destinationId - a.destinationId)));
                    }
                    this.setState({destinationsLoaded: true});
                }
            }
        }
        catch(error){
            console.log(error);
        }


        if(this.state.viewMode === 'view' || this.state.viewMode === 'edit'){
            try
            {
               
                this.setState({clearRulesStamp: new Date()});
                
                try
                {
                    let response = await GetCountingById(parseInt(this.props.params.id));

                    if(response instanceof AxiosError){
                        this.setState({error: response});
                        this.setState({countingLoaded: false});
                    }else{
                        if(Object.keys(response.data).length > 0){

                            let ruleResponse = await GetCountingRule(response.data.countingRuleId);

                            if(ruleResponse instanceof AxiosError){
                                this.setState({error: ruleResponse});
                                this.setState({countingLoaded: false});
                            }else{
                                if(Object.keys(response.data).length > 0){

                                    await this.loadRulesByDestinationId(ruleResponse.data.destinationId);
                                    this.props.dispatch(countingDropdownDataSelector.changeDestination(this.props.storeHelper.getState().countingDropdownDataSelector.common.destinations.find(f => f.destinationId === ruleResponse.data.destinationId)));
                                    this.props.dispatch(countingDropdownDataSelector.changeCountingRule(ruleResponse.data));

                                    this.setState({description: response.data.additionalInfo, 
                                        countingBeginTime: response.data.countingStart,
                                        countingEndTime: response.data.countingEnd,
                                        createdBy: response.data.createdBy,
                                        modifiedBy: response.data.modifiedBy,
                                        createdTime:  moment(response.data.createdTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(process.env.REACT_APP_NASTA_DATE_FORMAT),
                                        modifiedTime:  moment(response.data.modifiedTime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(process.env.REACT_APP_NASTA_DATE_FORMAT),
                                        countingRuleId: this.props.params.id,
                                        countingLoaded: true,
                                        isMarkedReady: response.data.ready});
                                }
                            }

                        }
                    }
                }
                catch(error){
                    console.log(error);
                }
                
            }
            catch(error){
                console.log(error);
            }
        }else{
        }
    }

    getSelectedItemText(countingRule){

        if(countingRule !== undefined){
        let conName = countingRule.countingRuleName + " , " + getCodeText(countingRule.userTypeCodeClassId, countingRule.userTypeCodeId, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses)
            + " , " + getCodeText(countingRule.frequencyCodeClassId, countingRule.frequencyCodeId, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses)
            + " , " + countingRule.countingRuleStart + " - " + countingRule.countingRuleEnd; 

        return [{id:  countingRule.countingRuleId,
            name: conName,
            title:  countingRule.countingRuleId,
            icon: "chip"}];
        }

        return [];
    }

    async handleSubmit(event) {

        this.setState({showSubmitAlert: false})

        if (this.state.allValid) {

            if(this.checkRuleAndTimePeriodValidity()){

                let submitThis = {
                    destinationId: this.props.storeHelper.getState().countingDropdownDataSelector.common.destination.destinationId,
                    countingRuleId: this.props.storeHelper.getState().countingDropdownDataSelector.common.selectedCountingRule.countingRuleId,
                    additionalInfo: this.state.description,
                    countingStart: this.formatTime(this.state.countingBeginTime),
                    countingEnd: this.formatTime(this.state.countingEndTime),
                    createdBy: typeof this.props.instance.getAllAccounts()[0] !== 'undefined' ? this.props.instance.getAllAccounts()[0].name : '',
                    modifiedBy: typeof this.props.instance.getAllAccounts()[0] !== 'undefined' ? this.props.instance.getAllAccounts()[0].name : '',
                    ready:false
                };

                let response = await ExecuteCounting(submitThis);

                if (response instanceof AxiosError) {

                    
                    if(Array.isArray(response.response.data)){
                        this.setState({countingErrorDialogOpen: true, overlappingCountings: response.response.data, currentTitle: this.props.t('addCounting:dialog_title'), currentMessage: response.response.data.length === 1 ? this.props.t('addCounting:counting_already_exists') :  this.props.t('addCounting:countings_already_exists')});
                    }
                    if(response.response.data === "Nothing_to_count"){
                        this.setState({dialogOpen: true, currentTitle: this.props.t('addCounting:dialog_title'), currentMessage: this.props.t('addCounting:counting_nothing_to_count')});
                    }
                    if(response.response.data === "Not_match_to_counting_rule"){
                        this.setState({dialogOpen: true, currentTitle: this.props.t('addCounting:dialog_title'), currentMessage: this.props.t('addCounting:counting_rule_not_fullfilled')});
                    }
                    if(response.response.data === "All_visits_are_null"){
                        this.setState({dialogOpen: true, currentTitle: this.props.t('addCounting:dialog_title'), currentMessage: this.props.t('addCounting:all_visits_are_null')});
                    }
                    if(Array.isArray(response.response.data) === false && response.response.data !== "Nothing_to_count" && response.response.data !== "Not_match_to_counting_rule" && response.response.data !== "All_visits_are_null"){
                        this.setState({dialogOpen: true, currentTitle: this.props.t('common:submit_error_title'), currentMessage: this.props.t('common:submit_error_content') + response.message});
                    }
                    
                    this.setState({submitAttempted: false});
                } else {
                    this.setState({submitAttempted: false, 
                        currentMessage: this.props.t("addCounting:countings_success_dialog_content") + response.data + this.props.t("addCounting:countings_success_dialog_quantity"),  
                        currentTitle: this.props.t("addCounting:countings_success_dialog_title"), 
                        dialogOpen: true,
                        onModalCancelCountingDialog:()=>{
                            this.setState({dialogOpen:false});
                            this.props.navigate("/counting/list/" + this.props.storeHelper.getState().countingDropdownDataSelector.common.destination.destinationId+"/1");
                    }});
                    
                }
            }else{
                this.setState({errorDialogOpen: true, currentError: this.props.t('addCounting:counting_period_problem')});
            }
         
            }else{
        }
    }

    checkRuleAndTimePeriodValidity(){

            const ruleBegin = this.props.storeHelper.getState().countingDropdownDataSelector.common.selectedCountingRule.ruleBegins;
            const ruleEnd = this.props.storeHelper.getState().countingDropdownDataSelector.common.selectedCountingRule.ruleEnds;
            const frequency = this.props.storeHelper.getState().countingDropdownDataSelector.common.selectedCountingRule.countingFrequency.codeId;
            const start = moment(new Date(this.state.countingBeginTime));
            const end = moment(new Date(this.state.countingEndTime));

            const ruleBeginDay = moment({ year: start.year(), 
                month: parseInt(ruleBegin.split('.')[1], 10) - 1, 
                day: parseInt(ruleBegin.split('.')[0], 10)});

            const ruleEndDay = moment({ year: end.year(),
                month: parseInt(ruleEnd.split('.')[1], 10) - 1,
                day: parseInt(ruleEnd.split('.')[0], 10)});

            if(start < end){
                const oneYearInMilliseconds = 366 * 24 * 60 * 60 * 1000; // 365 days in milliseconds + 1 cause we use 1. next month
                if ((end - start) > oneYearInMilliseconds) {
                    return false;
                }

                    switch(frequency){
                        case 1: //yearly
                            if(ruleBeginDay.date() === 1
                               && ruleBeginDay.month() === 0
                                && ruleEndDay.date() === 31
                                && ruleEndDay.month() === 11
                                && start.month() === 0 
                                && start.date() === 1 
                                && end.month() === 0 
                                && end.date() === 1){ //yearly counting 1.1.2002 - 1.1.2003 and rule must be 1.1 - 31.12
                                    return true;
                                }
                            break;
                        case 2: //monthly
                            if (ruleBeginDay.date() === 1 // rule begins at first day of month
                                && start.date() === 1 // counting begins at first day of month
                                && start.isSameOrAfter(ruleBeginDay) // counting begins at or after rule begins
                                && start.isSameOrBefore(ruleEndDay) // counting begins at or before rule ends
                                && ruleEndDay.date() === daysInMonths[ruleEndDay.month()] // rule ends at last day of month
                                && end <= new Date(ruleEndDay.year(), ruleEndDay.month() + 1, 1) // end is before the first day of the next month
                                ) {
                                return true;
                            }
                            break;
                        case 3: //daily
                            if(
                                start.hour() === 0 
                                && start.minute() === 0 
                                && end.hour() === 0 
                                && end.minute() === 0
                                && start >= ruleBeginDay 
                                && start <= ruleEndDay 
                                && end <= new Date(ruleEndDay.year(), ruleEndDay.month() + 1, 1)
                               ){
                                    return true;
                                }
                            break;
                        case 4: //hourly
                            const endMoment = moment(end);
                            const startMoment = moment(start);
                            const lastDayOfMonth = ruleEndDay.endOf('month');
                            let endFromPeriod = ruleEndDay;

                            if(lastDayOfMonth.date() === ruleEndDay.date()){
                                endFromPeriod = ruleEndDay.add(1, 'day');
                            }

                            if(start.minute() === 0 && end.minute() === 0
                                && startMoment.isSameOrAfter(ruleBeginDay, 'minute') 
                                && endMoment.isSameOrBefore(endFromPeriod, 'minute')){
                                return true;
                            }
                            break;
                        case 0: //unknown
                        case 9: //other
                            if(start >= ruleBeginDay 
                                && start <= ruleEndDay 
                                && ruleEndDay.date() === daysInMonths[ruleEndDay.month()]  
                                && end <= new Date(ruleEndDay.year(), ruleEndDay.month() + 1, 1)){
                                return true;
                            }
                            break;
                    }
                }
            return false
    }

    formatTime(dateString) {
        let date = moment.tz(dateString, Intl.DateTimeFormat().resolvedOptions().timeZone);
        date.seconds(0);
        return date.format('YYYY-MM-DDTHH:mm:ss');
    }

   

    GetDestinationSelectionList (props) {

        let destinationSelection = [];

        if (this.props.storeHelper.getState().countingDropdownDataSelector.common.destinations.length > 0) {
            if (typeof this.props.storeHelper.getState().countingDropdownDataSelector.common.destinations !== 'undefined') {
                destinationSelection = this.props.storeHelper.getState().countingDropdownDataSelector.common.destinations.map(x => {
                    {
                        return {id: x.destinationId,
                            name: x.destinationName,
                            title: x.destinationId.toString(),
                            icon: "chip"}
                    }
                });
            }
        }

        let selectedDestination = [];
        if(this.props.storeHelper.getState().countingDropdownDataSelector.common.destination !== undefined && this.props.storeHelper.getState().countingDropdownDataSelector.common.destination !== null){
            var viewedDestination = destinationSelection.find(f => f.id === this.props.storeHelper.getState().countingDropdownDataSelector.common.destination.destinationId);
            if(viewedDestination !== undefined){
                selectedDestination.push(viewedDestination);
            }
        }

            return <MultiChoiceSearchDropdown
                required={true}
                maxChoices={1}
                active={this.state.viewMode === 'view' ? false : true}
                disabled={this.state.viewMode === 'view'}
                minChoices={1}
                title={this.props.t('addCounting:counting_destination')}
                tooltip={this.props.t('addCounting:counting_destination_tooltip')}
                placeholder={this.props.t('addCounting:counting_destination_placeholder')}
                inputData={destinationSelection}
                validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                choices={selectedDestination}
                onChoicesChange={async (choicesList) => {
                    if (choicesList.length > 0) {
                        this.props.dispatch(countingDropdownDataSelector.changeDestination(this.props.storeHelper.getState().countingDropdownDataSelector.common.destinations.find(f => parseInt(f.destinationId) ===  parseInt(choicesList[0].id))));
                        await this.loadRulesByDestinationId(parseInt(choicesList[0].id));
                        this.setState({pageUpdated: true});
                    }else{
                        this.props.dispatch(countingDropdownDataSelector.clearAll());
                        this.setState({clearRulesStamp: new Date()});
                        this.setState({pageUpdated: true});
                    }
                }}
                onValidityChanged={()=>{}}
                choiceAttrsShown={['id', 'name']}

            />
        
    }

    async loadRulesByDestinationId(destinationId) {
        try
        {
            let response = await GetActiveCountingRulesByDestinationId(destinationId);
            if(response instanceof AxiosError){
                this.setState({error: response});
                this.setState({sitesLoaded: false});
            }else{
                if(response.data.length > 0){
                    if(this.state.viewMode === 'view'){
                        this.props.dispatch(countingDropdownDataSelector.changeCountingRuleList(response.data.sort((a,b) => b.countingRuleId - a.countingRuleId)));
                    }else{
                        this.props.dispatch(countingDropdownDataSelector.changeCountingRuleList(response.data.filter(f => f.inUse === true).sort((a,b) => b.countingRuleId - a.countingRuleId)));
                    }
                    
                    this.setState({rulesLoaded: true});
                }else{
                }
            }
        }
        catch(error){
            console.log(error);
        }
    }

    GetRulesSelectionList = (props) => {

        let maxChoices = this.props.storeHelper.getState().countingDropdownDataSelector.common.countingRules.length;
        let ruleSelection = [];

        if (this.props.storeHelper.getState().countingDropdownDataSelector.common.countingRules.length > 0) {
            if (typeof this.props.storeHelper.getState().countingDropdownDataSelector.common.countingRules !== 'undefined') {
                ruleSelection = this.props.storeHelper.getState().countingDropdownDataSelector.common.countingRules.map(x => {
                    {
                        let conName = x.rulename !== null ?  x.rulename + " , " 
                            + x.userType.languages[x.userType.languages.findIndex(i => i.languageName === this.props.t('common:GMLLanguage'))].text
                            + " , " + x.countingFrequency.languages[x.countingFrequency.languages.findIndex(i => i.languageName === this.props.t('common:GMLLanguage'))].text
                            + " , " + x.ruleBegins + " - " + x.ruleEnds : ""; 
                        return {id: x.countingRuleId,
                            name: conName,
                            title: x.countingRuleId.toString(),
                            icon: "chip"}
                    }
                });
            }
        }

        return <MultiChoiceSearchDropdown
                required={true}
                maxChoices={1}
                active={this.state.viewMode === 'view' ? false : true}
                disabled={this.state.viewMode === 'view'}
                minChoices={1}
                title={this.props.t('addCounting:counting_countingRule')}
                tooltip={this.props.t('addCounting:counting_countingRule_tooltip')}
                placeholder={this.props.t('addCounting:counting_countingRule_placeholder')}
                inputData={ruleSelection}
                validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                choices={this.state.viewMode === 'view' && this.getSelectedItemText(this.props.storeHelper.getState().countingDropdownDataSelector.common.selectedCountingRule)}
                choicesCleared={this.state.clearRulesStamp}
                onValidityChanged={()=>{}}
                onChoicesChange={async (choicesList) => {
                    if (choicesList.length > 0) {
                        this.props.dispatch(countingDropdownDataSelector.changeCountingRule(this.props.storeHelper.getState().countingDropdownDataSelector.common.countingRules.find(f => f.countingRuleId ===  parseInt(choicesList[0].id))));     
                        await this.calculateNextStartingDate(choicesList[0].id);
                        this.setState({pageUpdated: true});       
                    }else{
                        this.props.dispatch(countingDropdownDataSelector.clearRuleSelection());
                        this.setState({pageUpdated: true});
                    }
                }}
                choiceAttrsShown={['id', 'name']}

            />
    }

    checkDateValidity() {


            let valid = false;
            let end = moment(this.state.countingEndTime);
            let begin = moment(this.state.countingBeginTime);


            let msg = "";
            this.setState({
                dateValid: true
            });
    }

    async calculateNextStartingDate(countingRuleId){
        if(countingRuleId !== undefined){
            try
            {
                let response = await GetNextCountingStartDate(countingRuleId);

                if (response instanceof AxiosError) {
                    this.setState({error: response});
                } else {
                    this.setState({countingBeginTime: response.data});
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

     render() {
        let {t} = this.props;
        return (
            <>
                   <>
                        <form>
                           
                        <UploadingNotification showModal={this.state.countingLoaded === false && this.state.viewMode === 'view'} />
                            <FormController onValidityChanged={(validity)=>this.setState({allValid:validity})}>

                                { this.state.viewMode !== 'add' && <div className="form-group">
                                        <label className="form-label" htmlFor="input-example-1">{t("addCounting:counting_id")}
                                            {(t("addCounting:tooltip_counting_id")!==null && t("addCounting:tooltip_counting_id")!=='') &&

                                            <Tooltip title={t("addCalcPlace:tooltip_counting_id")}>
                                                <IconButton>
                                                    <InfoIcon/>
                                                </IconButton>
                                            </Tooltip>}
                                        </label>
                                        <input className="form-input" disabled={true} name="destinationId" type="number"
                                               id="input-example-1"
                                               placeholder={t('addCounting:placeholder_counting_id')}
                                               value={this.state.countingRuleId !== -1 ? this.state.countingRuleId : ''}></input>
                                </div>}

                                <this.GetDestinationSelectionList/>
                                
                                <this.GetRulesSelectionList/>

                                <p className={"" + (this.state.dateErrorMessage.length > 0 && ' label label-rounded label-error')}>{this.state.dateErrorMessage}</p>

                            <div className="time-box">
                                <label htmlFor={"startTime"}>{t('addCounting:counting_start_time')}</label><br/>
                                <LocalizationProvider locale={"fi-FI"} dateAdapter={AdapterMoment}>
                                    <DateTimePicker disabled={this.state.viewMode === 'view'} ampm={false} id={"startTime"} format={process.env.REACT_APP_NASTA_DATE_FORMAT} onChange={(date) => {
                                        this.setState({
                                                countingBeginTime: date
                                        }, () => {
                                            this.checkDateValidity();
                                        });
                                    }}
                                    value={moment(this.state.countingBeginTime)}

                                    />


                                </LocalizationProvider>
                            </div>

                            <div className="time-box">
                                <label htmlFor={"endTime"}>{t('addCounting:counting_end_time')}</label><br/>

                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DateTimePicker disabled={this.state.viewMode === 'view'} ampm={false} id={"endTime"} format={process.env.REACT_APP_NASTA_DATE_FORMAT} onChange={(date) => {


                                        this.setState({
                                                countingEndTime: date
                                        }, () => {
                                            this.checkDateValidity();
                                        });
                                    }}
                                    value={moment(this.state.countingEndTime)}

                                    />
                                </LocalizationProvider>
                            </div>

                            <ValidatedInputField
                                            name={"isMarkedReady"}
                                            fieldName={this.props.t('addCounting:counting_isReady')}
                                            tooltip={this.props.t('addCounting:counting_isReady_tooltip')}
                                            placeholder={this.props.t('addCounting:counting_isReady_placeholder')}
                                            required={false}
                                            disabled={true}
                                            minLength={0}
                                            maxLength={100}
                                            type={"text"}
                                            fieldId={'isMarkedReady'}
                                            validationMode={'always'}
                                            value={this.state.isMarkedReady ? t("addCounting:counting_isReady_true") : t("addCounting:counting_isReady_false")}
                                            validatorFunction={() => {
                                                return true
                                            }}
                                            onFieldValueChanged={(newValue) => {
                                                
                                            }}
                                            
                                    />

                                <ValidatedInputField
                                            name={"additionalInfo"}
                                            fieldName={this.props.t('addCounting:counting_additional_info')}
                                            tooltip={this.props.t('addCounting:counting_additional_info_tooltip')}
                                            placeholder={this.props.t('addCounting:counting_additional_info_placeholder')}
                                            required={false}
                                            disabled={this.state.viewMode === 'view'}
                                            minLength={0}
                                            maxLength={1000}
                                            multiline={true}
                                            type={"text"}
                                            fieldId={'additionalInfo'}
                                            validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                                            value={this.state.description}
                                            validatorFunction={() => {
                                                return true
                                            }}
                                            onFieldValueChanged={(newValue) => {
                                                this.setState({description: newValue})
                                            }}
                                            
                                    />


                                <div className="form-group">
                                    <label className="form-label" htmlFor="input-example-1">{t('addCounting:site_creator')}
                                        {(t("addCounting:tooltip_site_creator")!==null && t("addCounting:tooltip_site_creator")!=='') &&
                                            <Tooltip title={t("addCalcPlace:tooltip_site_creator")}>
                                                <IconButton>
                                                    <InfoIcon/>
                                                </IconButton>
                                            </Tooltip>} 
                                       </label>
                                            <Tooltip style={{zIndex:50000, fontSize: 'small'}} noArrow  id={"tooltip-"+ t("addCalcPlace:tooltip_site_creator")} />
                                    <input className="form-input" type="text" id="input-example-1"
                                        value={this.state.createdBy}
                                        disabled={true}></input>
                                </div>

                                {this.state.viewMode === 'view' && 
                                    <div className="form-group">
                                        <label className="form-label"
                                            htmlFor={"createdTime"}>{t('addCounting:counting_createdTime')}
                                            {this.props.t('addCounting:counting_createdTime_tooltip') !== null && <Tooltip title={this.props.t('addCounting:counting_createdTime_tooltip')}>
                                                <IconButton>
                                                    <InfoIcon/>
                                                </IconButton>
                                            </Tooltip>}

                                        </label>
                                        
                                        <input className="form-input" type="text" id="ctatedTime"
                                            value={this.state.createdTime}
                                            disabled={true}></input>
                                    </div>
                                }


                                <div className="form-group">
                                    <label className="form-label"
                                        htmlFor="input-example-1">{t('addCounting:site_modifier')} 
                                        
                                        {(t("addCounting:tooltip_site_modifier")!==null && t("addCounting:tooltip_site_modifier")!=='') &&
                                            <Tooltip title={t("addCalcPlace:tooltip_site_modifier")}>
                                                <IconButton>
                                                    <InfoIcon/>
                                                </IconButton>
                                            </Tooltip>} 
                                       </label>
                                        <Tooltip style={{zIndex:50000, fontSize: 'small'}} noArrow  id={"tooltip-"+ t("addCalcPlace:tooltip_site_modifier")} />
                                    <input className="form-input" type="text" id="input-example-1"
                                        value={this.state.modifiedBy}
                                        disabled={true}></input>
                                </div>

                                {this.state.viewMode === 'view' && 
                                    <div className="form-group">
                                        <label className="form-label"
                                            htmlFor={"modifiedTime"}>{t('addCounting:counting_modifiedTime')}
                                            {this.props.t('addCounting:counting_modifiedTime_tooltip') !== null && <Tooltip title={this.props.t('addCounting:counting_modifiedTime_tooltip')}>
                                                <IconButton>
                                                    <InfoIcon/>
                                                </IconButton>
                                            </Tooltip>}

                                        </label>
                                        
                                        <input className="form-input" type="text" id="modifiedTime"
                                            value={this.state.modifiedTime}
                                            disabled={true}></input>
                                    </div>
                                }

                            </FormController>

                            {        this.state.showSubmitAlert &&  <Alert onClose={() => {this.setState({showSubmitAlert: false})}} severity="error">
                                                <AlertTitle>{t("addCalcPlace:error_message_header")}</AlertTitle>
                                                {t("addCalcPlace:error_message_title_alert_box")} — <strong>{this.state.submitAlertMessage}</strong>
                                            </Alert>
                            }

                            <div>
                                {this.state.viewMode === 'edit' && 
                                    <Button size={"small"} variant={"contained"} type="button" onClick={() => this.setState({showErrorModal: true})}
                                        disabled={localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser" ? false : true}
                                        className=" float-left"><img style={{filter:"invert(100%)", height:"1.5vh"}} src="/img/icon/JHT design system/Pencil Write Alternate.svg" alt={t("common:button_edit")}/>{t("addCalcPlace:button_delete")}</Button>}

                               {this.state.viewMode !== 'view' && 
                                    <Button type="button"
                                            size={"small"} variant={"contained"}
                                            disabled={localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser" ? false : true}
                                            className="float-right" onClick={() => {
                                        if (this.state.editingState) {
                                            this.setState({showEditingModal: true})
                                        } else {
                                            this.handleSubmit();
                                        }

                                    }}><img style={{filter:"invert(100%)", height:"1.5vh"}} src="/img/icon/JHT design system/check_fill.svg" alt={t("common:add_button")}/> {t("addCounting:button_save")}</Button>}
                            </div>
                            {this.state.submitAttempted && <UploadingNotification showModal={this.state.submitAttempted} errors={this.state.submitErrors} type={"upload"} />}
                        </form>

                        <CountingTimePeriodErrorDialog 
                            showModal={this.state.errorDialogOpen}
                            title={"title"}
                            message={this.state.currentError}
                            ruleId={this.props.storeHelper.getState().countingDropdownDataSelector.common.selectedCountingRule !== undefined && this.props.storeHelper.getState().countingDropdownDataSelector.common.selectedCountingRule.countingRuleId }
                            onModalCancel={()=>this.setState({errorDialogOpen:false})}
                            onModalContinue={()=>this.setState({errorDialogOpen:false})}
                        />

                        <CountingDialog 
                            showModal={this.state.dialogOpen}
                            showSecondButton={false}
                            title={this.state.currentTitle}
                            message={this.state.currentMessage}
                            onModalCancel={this.state.onModalCancelCountingDialog}
                        />

                        <OverlapCountingErrorDialog 
                            showModal={this.state.countingErrorDialogOpen}
                            title={this.state.currentTitle}
                            message={this.state.currentMessage}
                            countings={this.state.overlappingCountings}    
                            onModalCancel={()=>this.setState({countingErrorDialogOpen:false})}
                            onModalContinue={()=>this.setState({countingErrorDialogOpen:false})}
                        />
                   </>
            </>
        )
    }
}

export default withRouter(AddCounting);