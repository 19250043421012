import React from "react";
import {useTranslation, withTranslation} from "react-i18next";
import axios from "axios";
import {Link, useNavigate, useParams, useLocation} from "react-router-dom";
import {getCodeText} from '../utils/CodeHelpers'
import { useSelector, useDispatch, useStore} from 'react-redux'
import MPagination from "@mui/material/Pagination/Pagination"
import { ClimbingBoxLoader } from "react-spinners";
import MultiChoiceSearchDropdown from "../widgets/MultiChoiceSearchDropdown/MultiChoiceSearchDropdown";
import * as countingRuleDropdownDataSelector from './CountingRuleDropdownDataSelector';


import {GetCountingRulesByDestinationId, GetCodeClasses, GetDestinations} from '../utils/NastaApiClient';
import {useMsal} from "@azure/msal-react";
import {AxiosError} from "axios";
import SearchBox from "../widgets/SearchBox/SearchBox";
import * as codesSelector from '../mapViewOl/codesSelector';
import {Input, Stack, Paper, TableContainer, Table, TableRow, TableHead, TableCell, TableBody} from "@mui/material";
import UploadingNotification from "../widgets/UploadingNotification/UploadingNotification";


const withRouter = WrappedComponent => props => {
    const params = useParams();
    const {instance} = useMsal();
    const navigate = useNavigate();
    const location = useLocation();
    const storeHelper = useStore();
    const dispatch = useDispatch();
    const codes = useSelector((state) => state.codes);
    const {t} = useTranslation('listCountingRule', 'common', 'addCounting')

    return (
        <WrappedComponent
            t={t}
            {...props}
            params={params}

            {...{instance, /* other injected props */}}
            navigate={navigate}
            storeHelper={storeHelper}
            dispatch={dispatch}
            codes={codes}
            location={location}
        />
    );
};

class ListCountingRule extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            paginationOffset:0,
            countingRules: [],
            searchResults:[],
            filteredRuleList:[],
            rulesLoaded:false,
            pagedRuleList:[],
            searchValue:null,
            destinationSelectionDone: false,
            destinationSelectionClearStamp: new Date(),
        }
        this.GetDestinationSelectionList = this.GetDestinationSelectionList.bind(this);
    }

    GetDestinationSelectionList (props) {

        let destinationSelection = [];

        if (this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.listViewDestinations.length > 0) {
            if (typeof this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.listViewDestinations !== 'undefined') {
                destinationSelection = this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.listViewDestinations.map(x => {
                    {
                        return {id: x.destinationId,
                            name: x.destinationName,
                            title: x.destinationId.toString(),
                            icon: "chip"}
                    }
                });
            }
        }

        let selectedDestination = [];
        if(this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.listViewDestination !== undefined && this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.listViewDestination !== null){
            var viewedDestination = destinationSelection.find(f => f.id === this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.listViewDestination.destinationId);
            if(viewedDestination !== undefined){
                selectedDestination.push(viewedDestination);
            }
        }

            return <MultiChoiceSearchDropdown
                required={true}
                maxChoices={1}
                active={this.state.viewMode === 'view' ? false : true}
                minChoices={1}
                title={this.props.t('addCounting:counting_destination')}
                tooltip={this.props.t('addCounting:counting_destination_tooltip')}
                placeholder={this.props.t('addCounting:counting_destination_placeholder')}
                inputData={destinationSelection}
                validationMode={(this.state.submitAttempted ? 'always' : 'onSelect')}
                choices={selectedDestination}
                choicesCleared={this.state.destinationSelectionClearStamp}
                onChoicesChange={async (choicesList) => {
                    if (choicesList.length > 0) {
                        this.props.dispatch(countingRuleDropdownDataSelector.changeListViewDestination(this.props.storeHelper.getState().countingRuleDropdownDataSelector.common.listViewDestinations.find(f => parseInt(f.destinationId) ===  parseInt(choicesList[0].id))));
                        this.props.navigate("/countingRule/list/1")
                        try {
            
                            this.setState({countingsLoaded: false});
                            let response = await GetCountingRulesByDestinationId(choicesList[0].id);
                
                            if (response instanceof AxiosError) {
                                this.setState({error: response, rulesLoaded: true, destinationSelectionDone: false});
                            } else {
                                this.setState({countingRules: response.data.sort((a,b) => b.countingRuleId - a.countingRuleId), rulesLoaded: true, destinationSelectionDone: true});
                            }
                            
                        } catch (error) {
                            console.log(error);
                        }

                        this.setState({pageUpdated: true});
                    }else{
                        this.props.dispatch(countingRuleDropdownDataSelector.clearListView());
                        this.setState({pageUpdated: true, countingRules: [], destinationSelectionDone: false},
                            this.props.navigate("/countingRule/list/")
                        );
                    }
                }}
                onValidityChanged={()=>{}}
                choiceAttrsShown={['id', 'name']}

            />
        
    }

    /**
     *
     * @returns {boolean}
     */
    allLoaded(){
        let {
            flowsLoaded,
            sitesLoaded,
            countsLoaded
        } = this.state;
        return (flowsLoaded && sitesLoaded && countsLoaded);
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {

        if(typeof this.props.params.page!=='undefined'){
            let page=parseInt(this.props.params.page);
            if(page-1!==this.state.paginationOffset){
                this.setState({paginationOffset:page-1});
            }
        }

        if(typeof this.props.addButtonPressed !== 'undefined'){
            if(this.props.addButtonPressed===true){
                this.props.navigate("/countingRule/add")
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.location.pathname !== prevProps.location.pathname && this.props.location.pathname === "/countingRule/list"){
            this.props.dispatch(countingRuleDropdownDataSelector.clearListView());
                        this.setState({pageUpdated: true, countingRules: [], destinationSelectionDone: false});
        }

        if(typeof this.props.params.page!=='undefined'){
            let page=parseInt(this.props.params.page);
            if(page-1!==this.state.paginationOffset){
                this.setState({paginationOffset:page-1});
            }
        }

        if(typeof this.props.addButtonPressed !== 'undefined'){
            if(this.props.addButtonPressed===true){
                this.props.navigate("/countingRule/add")
            }
        }
    }

    async componentDidMount(){

        this.props.dispatch(countingRuleDropdownDataSelector.clearListView());
        this.setState({destinationSelectionClearStamp: new Date()});
        
        try
        {
            let response = await GetDestinations();
            if(response instanceof AxiosError){
                this.setState({error: response});
                this.setState({destinationsLoaded: false});
            }else{
                if(response.length > 0){
                    this.props.dispatch(countingRuleDropdownDataSelector.changeListViewDestinationList(response.sort((a,b) => b.destinationId - a.destinationId)));
                    this.setState({destinationsLoaded: true});
                }
            }
        }
        catch(error){
            console.log(error);
        }

        try {
            if(!this.props.storeHelper.getState().codesSelector.codes.codeClassesLoaded){
                let response = await GetCodeClasses();

                if (response instanceof AxiosError) {
                    this.setState({error: response});
                    this.props.dispatch(codesSelector.changeCodeClassesLoaded(false));
                } else {
                    this.props.dispatch(codesSelector.fillCodeClasses(response));
                    this.props.dispatch(codesSelector.changeCodeClassesLoaded(true));
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    setPagination(pageNumber){
        let page = parseInt(pageNumber);
        this.setState({paginationOffset: page}, ()=>{
            this.props.navigate("/countingRule/list/"+page)
        });

    }


   
    render() {
        let {t} = this.props;

        let currentCountingRules = [];
        let currentLength = 0;
        currentCountingRules = this.state.countingRules.length > 0 && this.state.countingRules.slice(this.state.paginationOffset * 50, (this.state.paginationOffset * 50) + 50);
        currentLength = this.state.countingRules.length

        if(this.state.filteredRuleList.length > 0){
            currentCountingRules = this.state.filteredRuleList.length > 0 && this.state.filteredRuleList.map(m => m.item).sort((a,b) => b.countingRuleId - a.countingRuleId).slice(this.state.paginationOffset * 50, (this.state.paginationOffset * 50) + 50);
            currentLength = this.state.filteredRuleList.length
        }

        return (

            <>

                <UploadingNotification showModal={currentCountingRules.length===0} />
                    <this.GetDestinationSelectionList/>

                    <Stack alignItems={"center"} spacing={2} >
                        <p style={{textAlign: "center",marginBottom:"-2vh"}}>
                            {((this.state.paginationOffset) * 50)+1}
                            - {50 === currentCountingRules.length 
                                ? 
                                 this.state.paginationOffset * 50 + 50 
                                :  Number.isNaN(this.state.paginationOffset * 50 + currentCountingRules.length) ? 0 : this.state.paginationOffset * 50 + currentCountingRules.length} /
                            {Math.ceil(currentLength)}
                        </p>
                        <MPagination
                            page={this.state.paginationOffset+1}
                            onChange={(e,val)=>{
                                this.setPagination(val);
                            }}
                            count={Math.ceil(currentLength/50)}
                            boundaryCount={1}
                            siblingCount={4}
                            color={"primary"} />
                    </Stack>


                <TableContainer component={Paper}>

                    <Table sx={{}} aria-label={t("flow_list")}>
                        <TableHead>
                            <TableRow>

                                <TableCell>
                                    <b>
                                    {t("listCountingRule:columnHeader_destination_name")}
                                    </b>
                                </TableCell>
                                <TableCell>
                                    <b>
                                    {t("listCountingRule:columnHeader_countingRule_id")}
                                </b>
                                </TableCell>
                                <TableCell>
                                    <b>
                                    {t("listCountingRule:columnHeader_countingRule_name")}
                                </b>
                                </TableCell>
                                <TableCell>
                                    <b>
                                    {t("listCountingRule:columnHeader_countingRule_timeline")}
                                </b>
                                </TableCell>
                                <TableCell>
                                    <b>
                                    {t("listCountingRule:columnHeader_countingRule_frequency")}
                                </b>
                                </TableCell>
                                <TableCell>
                                    <b>
                                    {t("listCountingRule:columnHeader_countingRule_isValid")}
                                </b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {currentCountingRules.length > 0 && currentCountingRules.map(countingRule => {
                            return <>
                                <TableRow key={countingRule.countingRuleId}>
                                    <TableCell>
                                        {countingRule.destination.destinationName}
                                    </TableCell>
                                    <TableCell>
                                        {countingRule.countingRuleId}
                                    </TableCell>
                                    <TableCell>
                                        {typeof countingRule.rulename !== 'undefined' ?
                                            <Link to={"/countingRule/view/" + countingRule.countingRuleId}>
                                                <td className="c-hand"> {countingRule.rulename}</td>
                                            </Link> : <td></td>

                                        }

                                    </TableCell>
                                    <TableCell>
                                        {countingRule.ruleBegins} - {countingRule.ruleEnds}
                                    </TableCell>
                                    <TableCell>
                                        {getCodeText(countingRule.countingFrequency.codeClassId, countingRule.countingFrequency.codeId, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses)}
                                    </TableCell>
                                    <TableCell>
                                        {countingRule.inUse ?  t("listCountingRule:columnItem_Valid") : t("listCountingRule:columnItem_Invalid")}
                                    </TableCell>
                                </TableRow>

                            </>
                        })}
                        <TableBody>

                        </TableBody>
                    </Table>
                </TableContainer>

                    {this.state.rulesLoaded === true ||  this.state.destinationSelectionDone === false ? '': <div aria-live={"polite"} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width:'100%'}}><ClimbingBoxLoader/></div> }
            </>
        )
    }
}

export default withRouter(ListCountingRule);