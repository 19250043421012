import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import './UserMenu.css';
import logoutIcon from '../icons/Logout 1 Alternate.svg'

export default function UserMenu(props) {
    const {t}=useTranslation("userMenu");
    return (

        <ul className="menu menu-right" style={{right:"0px !important"}}>
            {localStorage.getItem("authorization")?
            <li className="menu-item" onClick={()=>props.onSelectLink()}>
                <Link to="/login">
                    <i className="icon icon-time"></i> <span>{t("sign_in_button")}</span>
                </Link>
            </li>
                :
            <li className="menu-item" onClick={()=>props.onSelectLink()}>
                <Link to="/logout">
                    <img src={logoutIcon} alt={""}/><span> {t("sign_out_button")}</span>
                </Link>
            </li>
            }
        </ul>
    )

}