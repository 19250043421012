import React from "react";
import MapViewOl from "./mapViewOl/MapViewOl";
import AddCalcPlace from "./calcPlace/AddCalcPlace";
import AddPlace from "./place/AddPlace";
import AddFlow from "./flow/AddFlow";
import ViewFlow from "./flow/ViewFlow";
import ListFlow from "./flow/ListFlow";
import AddCount from "./count/AddCount";
import ViewCount from "./count/ViewCount";
import ListCount from "./count/ListCount";
import ListPlace from "./place/ListPlace";
import ListCalcPlace from "./calcPlace/ListCalcPlace";
import ReportList from "./report/ReportList";
import ReportView from "./report/ReportView";
import SearchList from "./Search/SearchList";
import AddCountingRule from "./countingRule/AddCountingRule";
import ListCountingRule from "./countingRule/ListCountingRule";
import AddCounting from "./counting/AddCounting";
import ListCounting from "./counting/ListCounting";
import RemoveCounting from "./counting/RemoveCounting";
import DeleteCountDialog from "./count/deleteCountDialog/DeleteCountDialog";
import MarkReady from "./counting/MarkReady";


export default class ViewSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}

        const requiredAttributes = ["currentlySelectedView"];
        requiredAttributes.forEach(x => {
            if (typeof props[x] === 'undefined') {
                throw new Error(x + " props attribute required for ViewSelector");
            }
        })

        const useableViews = [
            "mapViewOl",
            "searchView",
            "addSiteView",
            "listSiteView",
            "addDestinationView",
            "listDestinationView",
            "addFlow",
            "viewFlow",
            "listFlow",
            "addCount",
            "viewCount",
            "listCount",
            "deleteCount",
            "reportsListView",
            "reportView",
            "addCountingRule",
            "listCountingRule",
            "viewCountingRule",
            "editCountingRule",
            "addCounting",
            "listCounting",
            "viewCounting",
            "removeCounting",
            "markCountingReady"

        ];
        if (!useableViews.find(x => props.currentlySelectedView === x)) {
            throw new Error("value " + props.currentlySelectedView + " in currentlySelectedView not found in useableViews. Available views are "+useableViews);
        }

    }

    changeVisibility(visibility){
        this.props.onVisibilityChanged(visibility);
    }


    render() {

        return <>
            {this.props.currentlySelectedView === 'mapViewOl' && <MapViewOl {...this.props.additionalAttributes??={}}/>}
            {this.props.currentlySelectedView === 'searchView' && <SearchList {...this.props.additionalAttributes??={}}/>}
            {this.props.currentlySelectedView === 'addDestinationView' && <AddPlace onVisibilityChanged={(v)=>this.changeVisibility(v)}
                                                                              visible={this.props.visible}
                                                                              {...this.props.additionalAttributes??={}}/>}
            {this.props.currentlySelectedView === 'listDestinationView' && <ListPlace {...this.props.additionalAttributes??={}}/>}
            {this.props.currentlySelectedView === 'addSiteView' && <AddCalcPlace onVisibilityChanged={(v)=>this.changeVisibility(v)}
                                                                                        {...this.props.additionalAttributes??={}}
                                                                                        visible={this.props.visible}
                                                                                            />}
            {this.props.currentlySelectedView === 'listSiteView' && <ListCalcPlace onVisibilityChanged={(v)=>this.changeVisibility(v)}
                                                                                 {...this.props.additionalAttributes??={}}
                                                                                 visible={this.props.visible}
            />}

            {this.props.currentlySelectedView === 'addFlow' && <AddFlow {...this.props.additionalAttributes??={}}/>}
            {this.props.currentlySelectedView === 'viewFlow' && <ViewFlow {...this.props.additionalAttributes??={}}/>}
            {this.props.currentlySelectedView === 'listFlow' && <ListFlow {...this.props.additionalAttributes??={}}/>}

            {this.props.currentlySelectedView === 'addCount' && <AddCount {...this.props.additionalAttributes??={}}/>}
            {this.props.currentlySelectedView === 'viewCount' && <ViewCount {...this.props.additionalAttributes??={}}/>}
            {this.props.currentlySelectedView === 'listCount' && <ListCount {...this.props.additionalAttributes??={}}/>}
            {this.props.currentlySelectedView === 'deleteCount' && <DeleteCountDialog {...this.props.additionalAttributes??={}}/>}

            {this.props.currentlySelectedView === 'addCountingRule' && <AddCountingRule {...this.props.additionalAttributes??={}}/>}
            {this.props.currentlySelectedView === 'listCountingRule' && <ListCountingRule {...this.props.additionalAttributes??={}}/>}
            {this.props.currentlySelectedView === 'viewCountingRule' && <AddCountingRule {...this.props.additionalAttributes??={}}/>}
            {this.props.currentlySelectedView === 'editCountingRule' && <AddCountingRule {...this.props.additionalAttributes??={}}/>}

            {this.props.currentlySelectedView === 'addCounting' && <AddCounting {...this.props.additionalAttributes??={}}/>}
            {this.props.currentlySelectedView === 'listCounting' && <ListCounting {...this.props.additionalAttributes??={}}/>}
            {this.props.currentlySelectedView === 'viewCounting' && <AddCounting {...this.props.additionalAttributes??={}}/>}
            {this.props.currentlySelectedView === 'editCounting' && <AddCounting {...this.props.additionalAttributes??={}}/>}
            {this.props.currentlySelectedView === 'removeCounting' && <RemoveCounting {...this.props.additionalAttributes??={}}/>}
            {this.props.currentlySelectedView === 'markCountingReady' && <MarkReady {...this.props.additionalAttributes??={}}/>}

            {this.props.currentlySelectedView === 'reportsListView' && <ReportList {...this.props.additionalAttributes??={}}/>}
            {this.props.currentlySelectedView === 'reportView' && <ReportView {...this.props.additionalAttributes??={}}/>}



        </>
    }


}