import React, {useEffect} from "react";
import { useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import { useTranslation } from 'react-i18next';
import Button from "@mui/material/Button";


/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
    const { instance } = useMsal();
    const { t } = useTranslation(['common']);

    const handleLogin = (loginType) => {
        if (loginType === "redirect") {
            instance.loginRedirect(loginRequest)
            .catch(e => {
                console.log(e);
            });
        }
    }
    return (
        <Button size={"small"} variant={"contained"} onClick={() => handleLogin("redirect")}>{t('sign_in_button')}</Button>
    );
}