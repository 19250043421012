import React from "react";
import {useTranslation} from "react-i18next";
import {
    Box,
    Modal,
    Button,
    Typography,
    useTheme,
   Divider,
   TableBody,
   TableContainer, 
   Table, 
   TableRow, 
   TableHead, 
   TableCell,
   Paper
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import "./CountingDialog.css";
import moment from "moment-timezone";
import 'moment/locale/fi'

const withRouter = WrappedComponent => props => {

    const {t} = useTranslation('errorDialog', 'common');
    const navigate = useNavigate();

    const theme = useTheme();
    return (
        <WrappedComponent
            t={t}
            {...props}
            theme={theme}
            navigate={navigate}
        />
    );
};


class CountingDialog extends React.Component {
    selectedModalType = undefined;

    constructor(props) {
        super(props);
        this.state = {
            message: props.message,
            title: props.title,
            showSecondButton: props.showSecondButton,
            listOfDates: props.listOfDates
        }
    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.message !== this.props.message){
            this.setState({message: this.props.message});
        }
        if(prevProps.title !== this.props.title){
            this.setState({title: this.props.title});
        }
        if(prevProps.showSecondButton !== this.props.showSecondButton){
            this.setState({showSecondButton: this.props.showSecondButton});
        }
        if(prevProps.listOfDates !== this.props.listOfDates){
            this.setState({listOfDates: this.props.listOfDates});
        }
    }

    render() {
        const {t} = this.props
        return (
            <>

                <Modal
                    open={this.props.showModal}
                    onClose={() => this.props.onModalCancel()}
                    aria-labelledby={'title'}
                    aria-describedby={this.props.content}>
                    <Box className={"modal-box-container"} style={{width:'1000px'}}>
                        

                        <Typography variant={"h6"}>{this.state.title}</Typography>

                        <Divider/>
                        <br/>

                        <Typography variant="h5" gutterBottom>
                            {this.state.message} 
                        </Typography>
                        <br/>

                        

                        {this.state.listOfDates !== undefined && this.state.listOfDates.length > 0 &&

                            <TableContainer component={Paper} style={{ maxHeight: 500 }}>
                            <Table sx={{}} aria-label={t("countingErrorDialog:flow_list")}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                        {t("countingErrorDialog:time_range_begin")}
                                        </TableCell>
                                        <TableCell>
                                        {t("countingErrorDialog:time_range_end")}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {this.state.listOfDates.map((time, index) => {
                                        return <>
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {moment.utc(time.split('-')[0]).local().format(process.env.REACT_APP_NASTA_DATE_FORMAT+':ss') }
                                                </TableCell>
                                                <TableCell>
                                                    {moment.utc(time.split('-')[1]).local().format(process.env.REACT_APP_NASTA_DATE_FORMAT+':ss') }
                                                </TableCell>
                                            </TableRow>
                                        </>
                                            })}
                                        </TableBody>

                                    </Table>
                            </TableContainer>
                            }
                        <br/>
                        {this.state.showSecondButton && <Button size={"small"} variant={"contained"} className="float-right"
                                onClick={() => this.props.onExecute()}>{t('errorDialog:second_button_text')}
                        </Button>}
                            
                        <Button size={"small"} variant={"contained"} className="float-right  button-gap"
                                onClick={() => this.props.onModalCancel()}>{t('errorDialog:close_button_text')}
                        </Button>
                    </Box>
                </Modal>
            </>
        )
    }
}

export default withRouter(CountingDialog)