import {DestinationType} from "./DestinationType";

export class Destination{
    _destinationId;
    _destinationGuid;
    /**
     *
     * @private {DestinationType}
     */
    _destinationType;
    /**
     *
     * @private {DestinationType}
     */
    _destinationOwner;
    _destinationOwnerCodeId;
    _destinationOwnerCodeClassId;
    /**
     *
     * @private {DestinationType}
     */
    _customerServiceType;
    _destinationTypeCodeId;
    _destinationTypeClassCodeId;
    /**
     *
     * @private {DestinationType}
     */
    _destinationFunder;
    _destinationFunderCodeId;
    _destinationFunderCodeClassId;


    _coordinate;
    _destinationRegion;
    _coordinateId;
    _destinationName;
    _description;
    _customerServiceNumber;
    _customerServiceName;
    _inUse;
    _createdBy;
    _createdTime;
    _modifiedBy;
    _modifiedTime;
    _nonce;


    constructor(destinationId,
                destinationGuid,
                destinationType,
                destinationOwner,
                destinationOwnerCodeId,
                destinationOwnerCodeClassId,
                customerServiceType,
                destinationFunder,
                destinationFunderCodeId,
                destinationFunderCodeClassId,
                destinationTypeCodeId,
                destinationTypeClassCodeId,
                coordinate,
                destinationRegion,
                coordinateId,
                destinationName,
                description,
                customerServiceNumber,
                customerServiceName,
                inUse,
                createdBy,
                createdTime,
                modifiedBy,
                modifiedTime,
                nonce) {
        if(typeof destinationId["destinationId"]!=='undefined' || destinationId["destinationId"]!==null){
            this._destinationTypeCodeId=destinationId["destinationTypeCodeId"];
            this._destinationTypeClassCodeId=destinationId["destinationTypeClassCodeId"];
            this._destinationOwnerCodeId=destinationId["destinationOwnerCodeId"];
            this._destinationOwnerCodeClassId=destinationId["destinationOwnerCodeClassId"];
            this._destinationFunderCodeId=destinationId["destinationFunderCodeId"];
            this._destinationFunderCodeClassId=destinationId["destinationFunderCodeClassId"];
            this._destinationId = destinationId["destinationId"];
            this._destinationGuid = destinationId["destinationGuid"];
            this._destinationType = (destinationId["destinationType"])?new DestinationType(destinationId["destinationType"]):null;
            this._destinationOwner = (destinationId["_destinationOwner"])?new DestinationType(destinationId["_destinationOwner"]):null;
            this._customerServiceType = (destinationId["_customerServiceType"])?new DestinationType(destinationId["_customerServiceType"]):null;
            this._destinationFunder = (destinationId["_destinationFunder"])?new DestinationType(destinationId["_destinationFunder"]):null;
            this._coordinate = destinationId["coordinate"];
            this._destinationRegion = destinationId["destinationRegion"];
            this._coordinateId = destinationId["coordinateId"];
            this._destinationName = destinationId["destinationName"];
            this._description = destinationId["description"];
            this._customerServiceNumber = destinationId["customerServiceNumber"];
            this._customerServiceName = destinationId["customerServiceName"];
            this._inUse = destinationId["inUse"];
            this._createdBy = destinationId["createdBy"];
            this._createdTime = destinationId["createdTime"];
            this._modifiedBy = destinationId["modifiedBy"];
            this._modifiedTime = destinationId["modifiedTime"];
            this._nonce = destinationId["nonce"];

        }else{
            this._destinationTypeCodeId=destinationTypeCodeId;
            this._destinationTypeClassCodeId=destinationTypeClassCodeId;
            this._destinationOwnerCodeId=destinationOwnerCodeId;
            this._destinationOwnerCodeClassId=destinationOwnerCodeClassId;
            this._destinationFunderCodeId=destinationFunderCodeId;
            this._destinationFunderCodeClassId=destinationFunderCodeClassId;

            this._destinationId = destinationId;
            this._destinationGuid = destinationGuid;
            this._destinationType = destinationType;
            this._destinationOwner = destinationOwner;
            this._customerServiceType = customerServiceType;
            this._destinationFunder = destinationFunder;
            this._coordinate = coordinate;
            this._destinationRegion = destinationRegion;
            this._coordinateId = coordinateId;
            this._destinationName = destinationName;
            this._description = description;
            this._customerServiceNumber = customerServiceNumber;
            this._customerServiceName = customerServiceName;
            this._inUse = inUse;
            this._createdBy = createdBy;
            this._createdTime = createdTime;
            this._modifiedBy = modifiedBy;
            this._modifiedTime = modifiedTime;
            this._nonce = nonce;
        }
    }


    get destinationOwnerCodeId() {
        return this._destinationOwnerCodeId;
    }

    get destinationOwnerCodeClassId() {
        return this._destinationOwnerCodeClassId;
    }

    get destinationFunderCodeId() {
        return this._destinationFunderCodeId;
    }

    get destinationFunderCodeClassId() {
        return this._destinationFunderCodeClassId;
    }

    get destinationTypeCodeId() {
        return this._destinationTypeCodeId;
    }

    get destinationTypeClassCodeId() {
        return this._destinationTypeClassCodeId;
    }

    get destinationId() {
        return this._destinationId;
    }

    get destinationGuid() {
        return this._destinationGuid;
    }

    /**
     *
     * @returns {DestinationType}
     */
    get destinationType() {
        return this._destinationType;
    }

    /**
     *
     * @returns {DestinationType}
     */
    get destinationOwner() {
        return this._destinationOwner;
    }

    /**
     *
     * @returns {DestinationType}
     */
    get customerServiceType() {
        return this._customerServiceType;
    }

    /**
     *
     * @returns {DestinationType}
     */
    get destinationFunder() {
        return this._destinationFunder;
    }

    get coordinate() {
        return this._coordinate;
    }

    get destinationRegion() {
        return this._destinationRegion;
    }

    get coordinateId() {
        return this._coordinateId;
    }

    get destinationName() {
        return this._destinationName;
    }

    get description() {
        return this._description;
    }

    get customerServiceNumber() {
        return this._customerServiceNumber;
    }

    get customerServiceName() {
        return this._customerServiceName;
    }

    get inUse() {
        return this._inUse;
    }

    get createdBy() {
        return this._createdBy;
    }

    get createdTime() {
        return this._createdTime;
    }

    get modifiedBy() {
        return this._modifiedBy;
    }

    get modifiedTime() {
        return this._modifiedTime;
    }

    get nonce() {
        return this._nonce;
    }
}