import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Navbar from "./navbar/Navbar";
import ViewSelector from "./ViewSelector";
import LoginView from "./LoginView";
import Footer from "./footer/Footer";


import { PublicClientApplication, EventType, InteractionRequiredAuthError } from "@azure/msal-browser";
import {AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate} from "@azure/msal-react";
import { msalConfig } from "./authConfig";

import MultiViewHandler from "./multiViewHandler/MultiViewHandler";
import TitleManager from "./TitleManager";
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import jwt_decode from "jwt-decode";

import { ThemeProvider } from '@mui/material/styles';

import store, { persistor } from './store'
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux'

import './App.css';
import TranslatedTitleHeader from "./widgets/TranslatedTitleHeader/TranslatedTitleHeader";
import LogoutView from "./LogoutView";
import {createTheme} from "@mui/material";
import * as mapLayerSelector from './mapViewOl/mapLayerSelector';

export const msalInstance = new PublicClientApplication(msalConfig);

const availableRoles = ["Nasta.Administrator", "Nasta.ResponsibleUser", "Nasta.Recorder", "Nasta.Browser"];

let titleStack=[];
function setTitleStack(newTitleStack){
    titleStack=newTitleStack;
}

const theme = createTheme({
    components:{
        MuiTableCell:{
            styleOverrides:{
                root:{
                    fontSize:"1rem"
                }
            }
        }
    },
    palette: {
        primary: {
            main: '#0069B3'

        },
        secondary: {
            main: '#33ACE3'
        },
        tertiary:{
            main:'#34A262'
        }
    },
    shape: {
        borderRadius: 1,
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

const router = createBrowserRouter([
    {path:"/",element:<><Navbar/>
            <TranslatedTitleHeader pageHeader={"main_page_header"}/>
      <MultiViewHandler titleStack={titleStack} setTitleStack={setTitleStack} pageHeader="main_page_header" additionalAttributes={[{},{"additionalAttributes":""}, {}]}
                        viewNames={["mapViewOl","addDestinationView", "addSiteView"]}
                        dataBindPathways={{0:[1,2], 1:[0], 2:[0]}} nWise={2}/><Footer/>
        </>},
    {path: "/search", element: <><Navbar/><ViewSelector currentlySelectedView={'searchView'}/><Footer/></>},
    {path: "/login", element: <><LoginView/></>},
    {path: "/logout", element: <><LogoutView/></>},

    {path: "/destinations/add", element:<><Navbar/><MultiViewHandler titleStack={titleStack} setTitleStack={setTitleStack} pageHeader="place_name"
         additionalAttributes={[{additionalAttributes:{specialToolbar:"destination"}},{"additionalAttributes":""},{}]}
                                                      viewNames={["mapViewOl","addDestinationView", "addSiteView"]}
                                                     iconName={"destination"}
                                                     toolbarButtons={["add","list","map"]}
                                                      dataBindPathways={{0:[1,2], 1:[0], 2:[0]}} nWise={2}/><Footer/></>},
    {path: "/destination/view/:id", element:<><Navbar/>
            <MultiViewHandler titleStack={titleStack} setTitleStack={setTitleStack} pageHeader="place_name" additionalAttributes={[{}]}
                              viewNames={["addDestinationView"]}
                              iconName={"destination"}
                              toolbarButtons={[]}
                              dataBindPathways={{}} nWise={1}/><Footer/></>},

    ...(()=> {
        return ["/destination/list/:id?", "destination/list/all/:page?", "/destination/list/:id/:page?"].map((pathName)=>{
            return {
                path: pathName, element: <><Navbar/>
                    <MultiViewHandler
                        titleStack={titleStack}
                        setTitleStack={setTitleStack}
                        pageHeader={"destination_list_name"}
                        iconName={"destination"}
                        toolbarButtons={["map","list"]}
                        additionalAttributes={[{additionalAttributes: {}}]}
                        viewNames={["listDestinationView"]} nWise={1}/>
                    <Footer/>
                </>
            }
        })
    })(),
    ...(()=> {
        return ["/site/list/:id?","/site/list/all/:page?", "/site/list/:id/:page?"].map((pathName)=>{
            return {
                path: pathName, element: <><Navbar/>
                    <MultiViewHandler
                        titleStack={titleStack}
                        setTitleStack={setTitleStack}
                        iconName={"site"}
                        pageHeader={"site_list_name"}
                        toolbarButtons={["map","list"]}
                        additionalAttributes={[{additionalAttributes: {}}]}
                        viewNames={["listSiteView"]} nWise={1}/>
                    <Footer/>
                </>
            }
        })
    })(),
    {path: "/sites/add", element:<><Navbar/>
            <MultiViewHandler titleStack={titleStack} setTitleStack={setTitleStack} pageHeader="calcPlace_name" additionalAttributes={[{additionalAttributes:{addButtonName:"calcPlace", addingFromMap:"calcPlace"}},{"additionalAttributes":""}, {}]}
                              viewNames={["mapViewOl", "addDestinationView", "addSiteView"]}
                              iconName={"site"}
                              toolbarButtons={["map","list","add"]}
                              dataBindPathways={{0:[1,2], 1:[0], 2:[0]}} nWise={2}/><Footer/></>},

    ...(()=> {
        return ["sites/:id?", "/site/view/:id"].map((pathName)=>{
            return {
                path: pathName, element: <><Navbar/>
                    <MultiViewHandler titleStack={titleStack}
                                      iconName={"site"}
                                      toolbarButtons={[]}
                                      dataBindPathways={{}} setTitleStack={setTitleStack} pageHeader={"site_name"} additionalAttributes={[{additionalAttributes:{}}]} viewNames={["addSiteView"]} nWise={1}/>
                    <Footer/>
                </>
            }
        })
    })(),

    {path: "/flow/add/:id?", element:<><Navbar/>
            <MultiViewHandler titleStack={titleStack} setTitleStack={setTitleStack}
                              pageHeader="flow_name"
                              iconName={"flow"}
                              additionalAttributes={[{},{"additionalAttributes":""}]}
                              viewNames={["addFlow"]}
                              toolbarButtons={[]}
                              dataBindPathways={{}} nWise={1}/><Footer/></>},


    ...(()=> {
        return ["flows/:id?", "/flow/list/:id?", "/flow/list/all/:page?", "/flow/list/:id/:page?"].map((pathName)=>{
            return {
                path: pathName, element: <><Navbar/>
                <MultiViewHandler titleStack={titleStack} setTitleStack={setTitleStack} pageHeader="flow_list"
                                  additionalAttributes={[{}, {"additionalAttributes": ""}]}
                                  iconName={"flow"}
                                  toolbarButtons={["list","add","csv"]}
                                  viewNames={["listFlow"]}
                                  dataBindPathways={{}} nWise={1}/><Footer/></>
            }
        })
    })(),
    ...(()=> {
        return ["/counts/:id", "/count/list/:id", "/count/list/:id/:page"].map((pathName)=>{
            return {
                path: pathName, element: <><Navbar/>
                    <MultiViewHandler
                        titleStack={titleStack}
                        setTitleStack={setTitleStack}
                        pageHeader={"count_list_name"}
                        toolbarButtons={["add","remove"]}
                        additionalAttributes={[{additionalAttributes: {}}]}
                        viewNames={["listCount"]} nWise={1}/>
                    <Footer/>
                </>
            }
        })
    })(),

    {path: "/counts/delete/:id", element:<><Navbar/>
            <MultiViewHandler titleStack={titleStack} setTitleStack={setTitleStack} pageHeader="count_delete_name" additionalAttributes={[{},{"additionalAttributes":""}]}
                              viewNames={["deleteCount"]}
                              toolbarButtons={["list"]}
                              iconName={"count"}
                              dataBindPathways={{}} nWise={1}/><Footer/></>},

    {path: "/flow/:id", element:<><Navbar/>
            <MultiViewHandler titleStack={titleStack} setTitleStack={setTitleStack} pageHeader="flow_name" additionalAttributes={[{},{"additionalAttributes":""}]}
                              viewNames={["addFlow"]}
                              toolbarButtons={["list"]}
                              iconName={"flow"}
                              dataBindPathways={{}} nWise={1}/><Footer/></>},
    {path: "/count/add/:id?", element:<><Navbar/>
            <MultiViewHandler
                titleStack={titleStack}
                setTitleStack={setTitleStack}
                pageHeader={"count_add_name"}
                toolbarButtons={[]}
                additionalAttributes={[{additionalAttributes: {}}]}
                viewNames={["addCount"]} nWise={1}/>
            <Footer/>
        </>
    },

    {path: "/count/:id?", element:<><Navbar/>
            <MultiViewHandler
                titleStack={titleStack}
                setTitleStack={setTitleStack}
                pageHeader={"count_view_name"}
                toolbarButtons={[]}
                additionalAttributes={[{additionalAttributes: {}}]}
                viewNames={["viewCount"]} nWise={1}/>
            <Footer/>
    </>
    },

    {path: "/report/list", element:<><Navbar/>
            <MultiViewHandler
                titleStack={titleStack}
                setTitleStack={setTitleStack}
                toolbarButtons={[]}
                pageHeader={"report_list_name"}
                additionalAttributes={[{additionalAttributes: {}}]}
                viewNames={["reportsListView"]} nWise={1}/>
            <Footer/>
        </>
    },

    {path: "/report/view/:name", element:<><Navbar/>
            <MultiViewHandler
                titleStack={titleStack}
                setTitleStack={setTitleStack}
                toolbarButtons={[]}
                pageHeader={"report_name"}
                additionalAttributes={[{additionalAttributes: {}}]}
                viewNames={["reportView"]} nWise={1}/>
            <Footer/>
        </>
    },
    {path: "/countingRule/add/:id?", element:<><Navbar/>
            <MultiViewHandler titleStack={titleStack} setTitleStack={setTitleStack} pageHeader="countingRule_list" additionalAttributes={[{additionalAttributes: {countingRuleState:'add'}}]}
                              viewNames={["addCountingRule"]}
                              toolbarButtons={[]}
                              iconName={"countingRule"}
                              dataBindPathways={{}} nWise={1}/><Footer/></>},

    {path: "/countingRule/list/:page?", element:<><Navbar/>
            <MultiViewHandler titleStack={titleStack} setTitleStack={setTitleStack} pageHeader="countingRule_list" additionalAttributes={[{additionalAttributes: {}}]}
                            viewNames={["listCountingRule"]}
                              iconName={"countingRule"}
                              toolbarButtons={["add"]}
                            dataBindPathways={{}} nWise={1}/><Footer/></>},
    
    {path: "/countingRule/view/:id?", element:<><Navbar/>
            <MultiViewHandler titleStack={titleStack} setTitleStack={setTitleStack} pageHeader="countingRule_list" additionalAttributes={[{additionalAttributes: {countingRuleState:'view'}}]}
                                viewNames={["viewCountingRule"]}
                              iconName={"countingRule"}
                              toolbarButtons={[]}
                                dataBindPathways={{}} nWise={1}/><Footer/></>},

    {path: "/countingRule/edit/:id?", element:<><Navbar/>
                                <MultiViewHandler titleStack={titleStack} setTitleStack={setTitleStack} pageHeader="countingRule_list" additionalAttributes={[{additionalAttributes: {countingRuleState:'edit'}}]}
                                                    viewNames={["editCountingRule"]}
                                                  iconName={"countingRule"}
                                                  toolbarButtons={[]}
                                                    dataBindPathways={{}} nWise={1}/><Footer/></>},

    {path: "/counting/add/:id?", element:<><Navbar/>
                                <MultiViewHandler titleStack={titleStack} setTitleStack={setTitleStack} pageHeader="counting_list" additionalAttributes={[{additionalAttributes: {countingState:'add'}}]}
                                                    viewNames={["addCounting"]}
                                                  iconName={"counting"}
                                                  toolbarButtons={[]}
                                                    dataBindPathways={{}} nWise={1}/><Footer/></>},
         
    {path: "/counting/list/:id/:page", element:<><Navbar/>
                                <MultiViewHandler titleStack={titleStack} setTitleStack={setTitleStack} pageHeader="counting_list" additionalAttributes={[{additionalAttributes: {}}]}
                                                    viewNames={["listCounting"]}
                                                  iconName={"counting"}
                                                  toolbarButtons={["add", "markReady","remove"]}
                                                    dataBindPathways={{}} nWise={1}/><Footer/></>},
    {path: "/counting/list/:id", element:<><Navbar/>
                                <MultiViewHandler titleStack={titleStack} setTitleStack={setTitleStack} pageHeader="counting_list" additionalAttributes={[{additionalAttributes: {}}]}
                                                    viewNames={["listCounting"]}
                                                    iconName={"counting"}
                                                    toolbarButtons={["add", "markReady","remove"]}
                                                    dataBindPathways={{}} nWise={1}/><Footer/></>},
    {path: "/counting/list", element:<><Navbar/>
                                <MultiViewHandler titleStack={titleStack} setTitleStack={setTitleStack} pageHeader="counting_list" additionalAttributes={[{additionalAttributes: {}}]}
                                                    viewNames={["listCounting"]}
                                                    iconName={"counting"}
                                                    toolbarButtons={["add", "markReady","remove"]}
                                                    dataBindPathways={{}} nWise={1}/><Footer/></>},

    {path: "/counting/edit/:id?", element:<><Navbar/>
                                <MultiViewHandler titleStack={titleStack} setTitleStack={setTitleStack} pageHeader="counting_list" additionalAttributes={[{additionalAttributes: {countingState:'edit'}}]}
                                                    viewNames={["addCounting"]}
                                                  iconName={"counting"}
                                                    toolbarButtons={[]}
                                                    dataBindPathways={{}} nWise={1}/><Footer/></>},

    {path: "/counting/view/:id?", element:<><Navbar/>
                                <MultiViewHandler titleStack={titleStack} setTitleStack={setTitleStack} pageHeader="counting_list" additionalAttributes={[{additionalAttributes: {countingState:'view'}}]}
                                                    viewNames={["addCounting"]}
                                                  iconName={"counting"}
                                                    toolbarButtons={[]}
                                                    dataBindPathways={{}} nWise={1}/><Footer/></>},
    {path: "/counting/remove/:id?", element:<><Navbar/>
                                <MultiViewHandler titleStack={titleStack} setTitleStack={setTitleStack} pageHeader="counting_list" additionalAttributes={[{additionalAttributes: {countingState:'view'}}]}
                                                    viewNames={["removeCounting"]}
                                                  iconName={"counting"}
                                                    toolbarButtons={[]}
                                                    dataBindPathways={{}} nWise={1}/><Footer/></>},
    {path: "/counting/markReady/:id?", element:<><Navbar/>
                                <MultiViewHandler titleStack={titleStack} setTitleStack={setTitleStack} pageHeader="counting_list" additionalAttributes={[{additionalAttributes: {}}]}
                                                    viewNames={["markCountingReady"]}
                                                    iconName={"counting"}
                                                    toolbarButtons={[]}
                                                    dataBindPathways={{}} nWise={1}/><Footer/></>},
    
]);

/**
 * To set an active account after the user signs in, register an event and listen to LOGIN_SUCCESS & LOGOUT_SUCCES. For more,
 * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
 */
 msalInstance.addEventCallback((event) => {

    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
    
    if(event.eventType === EventType.HANDLE_REDIRECT_END){

        if(typeof msalInstance.getAllAccounts()[0] !== 'undefined'){

            const customApiToken = {
                scopes:
                [ process.env.REACT_APP_NASTA_API_SCOPE ],
                account: msalInstance.getAllAccounts()[0],
              };

        
            msalInstance.acquireTokenSilent(customApiToken)
                .then((apiTokenResponse) => {
                    handleApiToken(apiTokenResponse);
                })
                .catch(async function (error) {
                    if (error instanceof InteractionRequiredAuthError) {
                        msalInstance.acquireTokenPopup(customApiToken).then((apiTokenResponse) => {
                            handleApiToken(apiTokenResponse);
                        });
                    } else {
                        //Acquire token silent failure
                        console.log(error);
                    }
                    console.log(error);
                });
        }
    }

    if (event.eventType === EventType.LOGOUT_SUCCESS) {
        localStorage.removeItem("user-role");
    }

    if (event.eventType === EventType.LOGIN_FAILURE) {
        localStorage.removeItem("user-role");
    }

});

export function handleApiToken(apiTokenResponse){
    // Acquire token silent success
    let apiAccessToken = apiTokenResponse.accessToken;

    let token = jwt_decode(apiAccessToken);


    if(typeof token.roles !== 'undefined' && token.roles !== null && token.roles.length > 0){
        if(token.roles.length === 1){
            localStorage.setItem("user-role", token.roles[0]);
        }
        else{
            //list is on order so that highest priviledge is first
            for (let i = 0; i < availableRoles.length; i++) {
                if(token.roles.includes(availableRoles[i])){
                    localStorage.setItem("user-role", availableRoles[i]);
                    return;
                }
            }
        }
    }else if(typeof token.roles === 'undefined'){
        console.log('out no roles');
        msalInstance.logoutRedirect({
            account: msalInstance.getActiveAccount(),
            postLogoutRedirectUri: "/",
        });
    }
}

const root = ReactDOM.createRoot(document.getElementById('root'));

store.dispatch(mapLayerSelector.changeDestinationLayerVisibility(false));
store.dispatch(mapLayerSelector.changeSiteLayerVisibility(false));

root.render(
  <React.StrictMode>
     <MsalProvider instance={msalInstance}>
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <AuthenticatedTemplate>
                        <TitleManager titleStack={titleStack} />
                        <ThemeProvider theme={theme}>
                            <RouterProvider router={router}/>
                        </ThemeProvider>
                    </AuthenticatedTemplate>
                </PersistGate>
            </Provider>
            <UnauthenticatedTemplate>
                <LoginView />
            </UnauthenticatedTemplate>
        </I18nextProvider>
      </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
