export default class Status{
    _codeClass;
    _codeClassId;
    _codeGuid;
    _codeId;
    _description;
    _languages;
    _usageEndTime;
    _usageStartTime;


    constructor(codeClass, codeClassId, codeGuid, codeId, description, languages, usageEndTime, usageStartTime) {


        if(typeof codeClass["codeClass"] !== 'undefined' || codeClass["codeClass"]!==null){
            this._codeClass = codeClass["codeClass"];
            this._codeClassId = codeClass["codeClassId"];
            this._codeGuid = codeClass["codeGuid"];
            this._codeId = codeClass["codeId"];
            this._description = codeClass["description"];
            this._languages = codeClass["languages"];
            this._usageEndTime = codeClass["usageEndTime"];
            this._usageStartTime = codeClass["usageStartTime"];
        }else{
            this._codeClass = codeClass;
            this._codeClassId = codeClassId;
            this._codeGuid = codeGuid;
            this._codeId = codeId;
            this._description = description;
            this._languages = languages;
            this._usageEndTime = usageEndTime;
            this._usageStartTime = usageStartTime;
        }

    }


    get codeClass() {
        return this._codeClass;
    }

    get codeClassId() {
        return this._codeClassId;
    }

    get codeGuid() {
        return this._codeGuid;
    }

    get codeId() {
        return this._codeId;
    }

    get description() {
        return this._description;
    }

    get languages() {
        return this._languages;
    }

    get usageEndTime() {
        return this._usageEndTime;
    }

    get usageStartTime() {
        return this._usageStartTime;
    }
}