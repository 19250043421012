import React from "react";
import {useTranslation} from "react-i18next";
import {
    Box,
    Modal,
    Button,
    Input,
    useTheme,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableContainer,
    TableCell, InputLabel
} from "@mui/material";
import {InsertCountsFromCsvFile} from "../../utils/NastaApiClient";

import {ThemeProvider} from "@mui/material/styles";
import UploadingNotification from "../UploadingNotification/UploadingNotification";

const withRouter = WrappedComponent => props => {

    const {t} = useTranslation('csvUploadDialog', 'common');

    const theme = useTheme();
    return (
        <WrappedComponent
            t={t}
            {...props}
            theme={theme}

        />
    );
};


class CsvUploadDialog extends React.Component {
    selectedModalType = undefined;

    constructor(props) {
        super(props);
        this.state = {
            uploading: false,
            file: null,
            response: null
        }
        this.upload = this.upload.bind(this);
    }


    async upload() {
        this.setState({uploading: true}, async () => {
            let resp = await InsertCountsFromCsvFile(this.state.file);
            console.log(resp)
            this.setState({response: resp, uploading: false})
        })


    }


    render() {
        const {t} = this.props

        return (
            <>
                <UploadingNotification showModal={this.state.uploading} type={"upload"}/>
                <Modal
                    open={this.props.showModal}
                    onClose={() => {
                        this.props.onModalCancel()
                    }}
                    aria-labelledby={this.props.title}
                    aria-describedby={this.props.content}>
                    <Box className={"modal-box-container"}>

                        {this.state.response === null && <>
                            <InputLabel htmlFor={"fileUpload"}>
                                {this.props.title}
                            </InputLabel>

                            <Input id={"fileUpload"} variant={"outlined"}
                                   size={"small"}
                                   type={"file"}

                                   onChange={(x) => {
                                       this.setState({file: x.target.files[0]});
                                   }}
                            ></Input>
                        </>
                        }


                        {this.state.response !== null &&
                            <ThemeProvider theme={this.props.theme.palette.tertiary}>
                                <TableContainer sx={{maxHeight:440}}>
                                <Table stickyHeader >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell color={"error"}>
                                                {t("header_title")}
                                            </TableCell>
                                            <TableCell>
                                                {t("header_value")}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {this.state.response.map(x => {
                                            if (x.key !== "ReadSucceededRow") {
                                                return (
                                                    <TableRow>
                                                        <TableCell style={{
                                                            backgroundColor: (x.key === 'ChangedCountRowsInDb' ? "#34A262" : "#d33030"),
                                                            color: (x.key === 'ChangedCountRowsInDb' ? "black" : "white")
                                                        }
                                                        }>
                                                            {t(x.key)}
                                                        </TableCell>
                                                        <TableCell color={"main"}>
                                                            {this.state.response.find(y=>y.value===x.value).value}
                                                        </TableCell>
                                                    </TableRow>

                                                )
                                            }
                                        })}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                            </ThemeProvider>
                        }


                        <div style={{marginTop: "2em"}}>
                            <Button size={"small"}
                                    variant={"contained"}
                                    className="float-left"
                                    onClick={() => {
                                        this.setState({response: null, file: null, uploading: false});
                                        this.props.onModalCancel()
                                    }}>{t('common:exit')}
                            </Button>
                            {this.state.file !== null && this.state.response === null &&
                                <Button size={"small"} variant={"contained"} className="float-right"
                                        onClick={async () => await this.upload()}>{t('modalComponent:continue')}
                                </Button>
                            }
                        </div>
                    </Box>
                </Modal>


            </>
        )
    }
}

export default withRouter(CsvUploadDialog)