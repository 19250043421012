import { createSlice } from '@reduxjs/toolkit'

export const applicationCommonsSelector = createSlice({
  name: 'applicationCommonsSelector',
  initialState: {
   currentLanguage: 'fi',
  },
  reducers: {
    changeLanguage: (state, action) => {
        state.currentLanguage = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const {changeLanguage} = applicationCommonsSelector.actions

export default applicationCommonsSelector.reducer