import { createSlice } from '@reduxjs/toolkit'

export const mapSelectionSelector = createSlice({
  name: 'mapSelectionSelector',
  initialState: {
    selections:{
        selectedDestinations: [],
        selectedSites: [],
        fromList: false,
    },
  },
  reducers: {
    updateDestinationSelection: (state, action) => {
        state.selections.selectedDestinations = action.payload.list;
        state.selections.fromList = action.payload.listView;
    },
    updateSitesSelection: (state, action) => {
      state.selections.selectedSites = action.payload.list;
      state.selections.fromList = action.payload.listView;
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateMapSelection, updateDestinationSelection, updateSitesSelection } = mapSelectionSelector.actions

export default mapSelectionSelector.reducer