import {CodeClassCode} from "./CodeClassCode";
import {CodeClassLanguage} from "./CodeClassLanguage";

export class CodeClass {
    _codeClassGuid;
    _codeClassId;
    /**
     *
     *
     * @private {string[]}
     */
    _codes;
    _name;
    /**
     *
     *
     * @private {CodeClassLanguage[]}
     */
    _languages;


    constructor(codeClassGuid, codeClassId, codes, name, languages) {
        if (typeof codeClassGuid["codeClassGuid"] !== 'undefined' || codeClassGuid.codeClassGuid !== null) {
            this._codeClassGuid = codeClassGuid["codeClassGuid"];
            this._codeClassId = codeClassGuid["codeClassId"];
            this._codes = codeClassGuid["codes"];
            this._name = codeClassGuid["name"];
            this._languages = codeClassGuid["languages"] ? codeClassGuid["languages"].map(lang => new CodeClassLanguage(lang)) : null;
        } else {
            this._codeClassGuid = codeClassGuid;
            this._codeClassId = codeClassId;
            this._codes = codes;
            this._name = name;
            this._languages = languages;
        }
    }


    /**
     *
     * @returns {CodeClassLanguage[]}
     */
    get languages() {
        return this._languages;
    }

    get codeClassGuid() {
        return this._codeClassGuid;
    }

    get codeClassId() {
        return this._codeClassId;
    }

    /**
     *
     *
     * @returns {string[]}
     */
    get codes() {
        return this._codes;
    }

    get name() {
        return this._name;
    }
}

