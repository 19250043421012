import {Flow} from "./Flow";
import {GetFlow} from "../utils/NastaApiClient";

export default class Count{
    _countId;
    _countGuid;
    _flowId;
    _periodBeginTime;
    _periodEndTime;
    _countsOriginal;
    _countsCorrected;
    _visits;
    _description;
    _calibrationCoefficient;
    _astaCounterId;
    _createdBy;
    _createdTime;
    _modifiedBy;
    _modifiedTime;
    /**
     *
     * @private {Flow}
     */
    _flow;
    _nonce;


    constructor(countId, countGuid, flowId, periodBeginTime, periodEndTime, countsOriginal, countsCorrected, visits, description, calibrationCoefficient, astaCounterId, createdBy, createdTime, modifiedBy, modifiedTime, flow, nonce) {

        if(typeof countId["countId"] !== 'undefined' || countId["countId"] !== null){
            this._countId = countId["countId"];
            this._countGuid = countId["countGuid"];
            this._flowId = countId["flowId"];
            this._periodBeginTime = countId["periodBeginTime"];
            this._periodEndTime=countId["periodEndTime"];
            this._countsOriginal = countId["countsOriginal"];
            this._countsCorrected = countId["countsCorrected"];
            this._visits = countId["visits"];
            this._description = countId["description"];
            this._calibrationCoefficient = countId["calibrationCoefficient"];
            this._astaCounterId = countId["astaCounterId"];
            this._createdBy = countId["createdBy"];
            this._createdTime = countId["createdTime"];
            this._modifiedBy = countId["modifiedBy"];
            this._modifiedTime = countId["modifiedTime"];
            this._flow = ((typeof countId["flow"] !== 'undefined' && countId["flow"]!==null) ? new Flow(countId["flow"]) : null)
            this._nonce = countId["nonce"];
        }else{
            this._countId = countId;
            this._countGuid = countGuid;
            this._flowId = flowId;
            this._periodBeginTime = periodBeginTime;
            this._periodEndTime=periodEndTime;
            this._countsOriginal = countsOriginal;
            this._countsCorrected = countsCorrected;
            this._visits = visits;
            this._description = description;
            this._calibrationCoefficient = calibrationCoefficient;
            this._astaCounterId = astaCounterId;
            this._createdBy = createdBy;
            this._createdTime = createdTime;
            this._modifiedBy = modifiedBy;
            this._modifiedTime = modifiedTime;
            this._flow = flow;
            this._nonce = nonce;
        }


    }


    set countId(value) {
        this._countId = value;
    }

    set countGuid(value) {
        this._countGuid = value;
    }

    set flowId(value) {
        this._flowId = value;
    }

    set periodBeginTime(value) {
        this._periodBeginTime = value;
    }

    set periodEndTime(value) {
        this._periodEndTime = value;
    }

    set countsOriginal(value) {
        this._countsOriginal = value;
    }

    set countsCorrected(value) {
        this._countsCorrected = value;
    }

    set visits(value) {
        this._visits = value;
    }

    set description(value) {
        this._description = value;
    }

    set calibrationCoefficient(value) {
        this._calibrationCoefficient = value;
    }

    set astaCounterId(value) {
        this._astaCounterId = value;
    }

    set createdBy(value) {
        this._createdBy = value;
    }

    set createdTime(value) {
        this._createdTime = value;
    }

    set modifiedBy(value) {
        this._modifiedBy = value;
    }

    set modifiedTime(value) {
        this._modifiedTime = value;
    }

    set flow(value) {
        this._flow = value;
    }

    set nonce(value) {
        this._nonce = value;
    }

    get periodEndTime() {
        return this._periodEndTime;
    }

    get countId() {
        return this._countId;
    }

    get countGuid() {
        return this._countGuid;
    }

    get flowId() {
        return this._flowId;
    }

    get periodBeginTime() {
        return this._periodBeginTime;
    }

    get countsOriginal() {
        return this._countsOriginal;
    }

    get countsCorrected() {
        return this._countsCorrected;
    }

    get visits() {
        return this._visits;
    }

    get description() {
        return this._description;
    }

    get calibrationCoefficient() {
        return this._calibrationCoefficient;
    }

    get astaCounterId() {
        return this._astaCounterId;
    }

    get createdBy() {
        return this._createdBy;
    }

    get createdTime() {
        return this._createdTime;
    }

    get modifiedBy() {
        return this._modifiedBy;
    }

    get modifiedTime() {
        return this._modifiedTime;
    }

    get flow() {
        return this._flow;
    }

    get nonce() {
        return this._nonce;
    }
}