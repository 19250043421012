export function getCodeText(codeClassId, codeId, language , codeClasses){
    if(typeof codeId === 'string'){
        codeId = parseInt(codeId.split(',')[0]);
    }
    let codeCl = codeClasses.find(f => f.codeClassId === codeClassId);
    let result = '';

    if(typeof codeCl !== 'undefined'){
        let code = codeCl.codes.find(f => f.codeId === codeId);
        if(typeof code !== 'undefined' && typeof code.languages !== 'undefined' && code.languages.length > 0){
            result = code.languages[code.languages.findIndex(i => i.languageName === language)].text;
        }else{
            result = '';
        }
    }
    return result
}