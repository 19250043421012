import { createSlice } from '@reduxjs/toolkit'

export const destinationsFilterSelector = createSlice({
  name: 'destinationsFilterSelector',
  initialState: {
    isOpen: false,
    destination: {
        isOpen: false,
        selectAll: false,
        notInUse: false,
        inUse: true,
    },
    destinationType:{
        isOpen: false,
        selectAll: true,
        typeCode_1: true,
        typeCode_2: true,
        typeCode_3: true,
        typeCode_10: true,
        typeCode_11: true,
        typeCode_20: true,
        typeCode_21: true,
        typeCode_30: true,
        typeCode_31: true,
        typeCode_99: true,
    },
    destinationOwner:{
        isOpen: false,
        selectAll: true,
        ownerCode_1: true,
        ownerCode_2: true,
        ownerCode_3: true,
        ownerCode_4: true,
        ownerCode_5: true,
        ownerCode_6: true,
        ownerCode_99: true,
    },
    destinationFunder:{
        isOpen: false,
        selectAll: true,
        funderCode_1: true,
        funderCode_2: true,
        funderCode_3: true,
        funderCode_9: true,
    },
    destinationLandUseUnitFilter:{
        isOpen: false,
        selectAll: true,
        landUseUnitCode_101: true, 
        landUseUnitCode_102: true, 
        landUseUnitCode_103: true, 
        landUseUnitCode_111: true, 
        landUseUnitCode_121: true, 
        landUseUnitCode_122: true, 
        landUseUnitCode_131: true, 
        landUseUnitCode_132: true, 
        landUseUnitCode_201: true, 
        landUseUnitCode_202: true, 
        landUseUnitCode_203: true, 
        landUseUnitCode_204: true, 
        landUseUnitCode_205: true, 
        landUseUnitCode_206: true, 
        landUseUnitCode_207: true, 
        landUseUnitCode_208: true, 
        landUseUnitCode_211: true, 
        landUseUnitCode_212: true, 
        landUseUnitCode_221: true, 
        landUseUnitCode_231: true, 
        landUseUnitCode_232: true, 
        landUseUnitCode_241: true, 
        landUseUnitCode_301: true, 
        landUseUnitCode_302: true, 
        landUseUnitCode_312: true, 
        landUseUnitCode_313: true, 
        landUseUnitCode_401: true, 
        landUseUnitCode_431: true, 
        landUseUnitCode_441: true, 
        landUseUnitCode_451: true, 
        landUseUnitCode_458: true, 
        landUseUnitCode_459: true, 
        landUseUnitCode_501: true, 
        landUseUnitCode_502: true, 
        landUseUnitCode_503: true, 
        landUseUnitCode_551: true, 
        landUseUnitCode_601: true, 
        landUseUnitCode_602: true, 
        landUseUnitCode_603: true, 
        landUseUnitCode_610: true, 
        landUseUnitCode_620: true, 
        landUseUnitCode_631: true, 
        landUseUnitCode_632: true, 
        landUseUnitCode_633: true, 
        landUseUnitCode_634: true, 
        landUseUnitCode_635: true, 
        landUseUnitCode_641: true, 
        landUseUnitCode_642: true, 
        landUseUnitCode_643: true, 
        landUseUnitCode_644: true, 
        landUseUnitCode_661: true, 
        landUseUnitCode_662: true, 
        landUseUnitCode_671: true, 
        landUseUnitCode_672: true, 
        landUseUnitCode_673: true, 
        landUseUnitCode_681: true, 
        landUseUnitCode_682: true, 
        landUseUnitCode_683: true, 
        landUseUnitCode_684: true, 
        landUseUnitCode_685: true, 
        landUseUnitCode_687: true, 
        landUseUnitCode_689: true, 
        landUseUnitCode_810: true, 
        landUseUnitCode_811: true, 
        landUseUnitCode_812: true, 
        landUseUnitCode_813: true, 
        landUseUnitCode_814: true, 
        landUseUnitCode_815: true, 
        landUseUnitCode_900: true, 
        landUseUnitCode_901: true, 
        landUseUnitCode_902: true, 
        landUseUnitCode_950: true,
        landUseUnitCode_104: true,
        landUseUnitCode_233: true,
        landUseUnitCode_0: true,
    }
  },
  reducers: {
    //plus sign
    changeIsOpen: (state) => {
        state.isOpen = !state.isOpen;
    },
    changeDestinationIsOpen: (state) => {
        state.destination.isOpen = !state.destination.isOpen;
    },
    changeDestinationSelectAll: (state) => {
        let status = !state.destination.selectAll;
        Object.entries(state.destination).forEach(fe => {if(fe[0] !== 'isOpen'){ state.destination[fe[0]] = status }});
    },
    changeDestinationSelection: (state, action) => {
        state.destination[action.payload] = !state.destination[action.payload];
        let resultArray = [];
        let lengthOfDestinations = Object.keys(state.destination).filter(key => key !== 'isOpen' && key !== 'selectAll' && key !== 'destinationInUseFilterAll').length;
        
        Object.keys(state.destination)
            .filter(key => key !== 'isOpen' && key !== 'selectAll' && key !== 'destinationInUseFilterAll')
            .forEach(fk => {if(state.destination[fk]){resultArray.push(state.destination[fk])}});

        if(lengthOfDestinations === resultArray.length){
            state.destination.selectAll = true;
        }else{
            state.destination.selectAll = false;
        }
    },

    changeDestinationTypeIsOpen: (state) => {
        state.destinationType.isOpen = !state.destinationType.isOpen
    },
    changeDestinationTypeSelectAll: (state) => {
        let status = !state.destinationType.selectAll;
        Object.entries(state.destinationType).forEach(fe => {if(fe[0] !== 'isOpen'){ state.destinationType[fe[0]] = status }});
    },
    changeDestinationTypeSelection: (state, action) => {
        state.destinationType[action.payload] = !state.destinationType[action.payload];
        let resultArray = [];
        let lengthOfSelection = Object.keys(state.destinationType).filter(key => key !== 'isOpen' && key !== 'selectAll' && key !== 'typeFilterAll').length;
        
        Object.keys(state.destinationType)
            .filter(key => key !== 'isOpen' && key !== 'selectAll' && key !== 'typeFilterAll')
            .forEach(fk => {if(state.destinationType[fk]){resultArray.push(state.destinationType[fk])}});

        if(lengthOfSelection === resultArray.length){
            state.destinationType.selectAll = true;
        }else{
            state.destinationType.selectAll = false;
        }
    },
    
    changeDestinationOwnerCodeIsOpen: (state) => {
        state.destinationOwner.isOpen = !state.destinationOwner.isOpen
    },
    changeDestinationOwnerCodeSelectAll: (state) => {
        let status = !state.destinationOwner.selectAll;
        Object.entries(state.destinationOwner).forEach(fe => {if(fe[0] !== 'isOpen'){ state.destinationOwner[fe[0]] = status }});
    },
    changeDestinationOwnerSelection: (state, action) => {
        state.destinationOwner[action.payload] = !state.destinationOwner[action.payload];
        let resultArray = [];
        let lengthOfSelection = Object.keys(state.destinationOwner).filter(key => key !== 'isOpen' && key !== 'selectAll' && key !== 'ownerFilterAll').length;
        
        Object.keys(state.destinationOwner)
            .filter(key => key !== 'isOpen' && key !== 'selectAll' && key !== 'ownerFilterAll')
            .forEach(fk => {if(state.destinationOwner[fk]){resultArray.push(state.destinationOwner[fk])}});

        if(lengthOfSelection === resultArray.length){
            state.destinationOwner.selectAll = true;
        }else{
            state.destinationOwner.selectAll = false;
        }
    },

    changeDestinationFunderCodeIsOpen: (state) => {
        state.destinationFunder.isOpen = !state.destinationFunder.isOpen
    },
    changeDestinationFunderCodeSelectAll: (state) => {
        let status = !state.destinationFunder.selectAll;
        Object.entries(state.destinationFunder).forEach(fe => {if(fe[0] !== 'isOpen'){ state.destinationFunder[fe[0]] = status }});
    },
    changeDestinationFunderSelection: (state, action) => {
        state.destinationFunder[action.payload] = !state.destinationFunder[action.payload];
        let resultArray = [];
        let lengthOfSelection = Object.keys(state.destinationFunder).filter(key => key !== 'isOpen' && key !== 'selectAll' && key !== 'funderFilterAll').length;
        
        Object.keys(state.destinationFunder)
            .filter(key => key !== 'isOpen' && key !== 'selectAll' && key !== 'funderFilterAll')
            .forEach(fk => {if(state.destinationFunder[fk]){resultArray.push(state.destinationFunder[fk])}});

        if(lengthOfSelection === resultArray.length){
            state.destinationFunder.selectAll = true;
        }else{
            state.destinationFunder.selectAll = false;
        }
    },
  
   

    changeDestinationLandUseUnitCodeIsOpen: (state) => {
        state.destinationLandUseUnitFilter.isOpen = !state.destinationLandUseUnitFilter.isOpen
    },
    changeDestinationLandUseUnitCodeSelectAll: (state) => {
        let status = !state.destinationLandUseUnitFilter.selectAll;
        Object.entries(state.destinationLandUseUnitFilter).forEach(fe => {if(fe[0] !== 'isOpen'){ state.destinationLandUseUnitFilter[fe[0]] = status }});
    },
    changeDestinationLandUseUnitCodeSelection: (state, action) => {
        state.destinationLandUseUnitFilter[action.payload] = !state.destinationLandUseUnitFilter[action.payload];
        let resultArray = [];
        let lengthOfSelection = Object.keys(state.destinationLandUseUnitFilter).filter(key => key !== 'isOpen' && key !== 'selectAll' && key !== 'landUseUnitFilterAll').length;
        
        Object.keys(state.destinationLandUseUnitFilter)
            .filter(key => key !== 'isOpen' && key !== 'selectAll' && key !== 'landUseUnitFilterAll')
            .forEach(fk => {if(state.destinationLandUseUnitFilter[fk]){resultArray.push(state.destinationLandUseUnitFilter[fk])}});

        if(lengthOfSelection === resultArray.length){
            state.destinationLandUseUnitFilter.selectAll = true;
        }else{
            state.destinationLandUseUnitFilter.selectAll = false;
        }
    },
  },
})

// Action creators are generated for each case reducer function
export const { changeDestinationSelectAll, changeDestinationSelection, changeDestinationTypeSelectAll, changeDestinationTypeSelection
    , changeDestinationOwnerCodeSelectAll,
    changeDestinationOwnerSelection, changeDestinationFunderCodeSelectAll, changeDestinationFunderSelection,
    changeDestinationLandUseUnitCodeSelectAll, changeDestinationLandUseUnitCodeSelection, 
    changeIsOpen, changeDestinationLayerVisibility, changeDestinationIsOpen,
    changeDestinationTypeIsOpen, changeDestinationOwnerCodeIsOpen, changeDestinationFunderCodeIsOpen, 
    changeDestinationLandUseUnitCodeIsOpen} = destinationsFilterSelector.actions

export default destinationsFilterSelector.reducer