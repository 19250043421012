import React from 'react';

class MonthCheckboxGroup extends React.Component {
  render() {
    const { month, daysInMonth, onCheckboxChange, disabled, t } = this.props;

    if(typeof daysInMonth === 'undefined') {  
      return;
    }

    const checkboxes = [];
    for (let i = 0; i <= daysInMonth.length - 1; i++) {
      checkboxes.push({
        id: daysInMonth[i].id,
        label: daysInMonth[i].id.split('-')[1].toString().length === 1 ? '0' + daysInMonth[i].id.split('-')[1].toString() : daysInMonth[i].id.split('-')[1].toString(),
        checked: daysInMonth[i].checked,
      });
    }

    const rows = [];
    for (let i = 0; i < checkboxes.length; i += 7) {
      rows.push(checkboxes.slice(i, i + 7));
    }

    return (
      <div>
        <div style={{ marginRight: '1rem' }}>
          <label>{this.props.t(`hourLinesComponent:${month}`)}</label>
        </div>
        <br/>
        {rows.map((row, rowIndex) => (
          <div key={rowIndex} style={{ display: 'flex', flexWrap: 'wrap' }}>
            {row.map((checkbox) => (
              <div key={checkbox.id} style={{ marginRight: '1rem' }}>
                <label htmlFor={checkbox.id+this.props.key}>{checkbox.label}</label>
                <br />
                <input type="checkbox" checked={checkbox.checked} id={checkbox.id} name={checkbox.id} onChange={onCheckboxChange} disabled={disabled}/>
              </div>
            ))}
          </div>
        ))}
        <br/>
      </div>
    );
  }
}

export default MonthCheckboxGroup;