import React from "react";

import './FormController.css';

class ValidRequiredChild {


    childDOMObject;


    constructor(childDOMObject, valid) {
        this.childDOMObject = childDOMObject;

        this.required = this.childDOMObject.props.required;

        if (typeof valid === 'undefined') {
            this.valid = !this.required;
        } else {
            this.valid = valid;
        }

    }


}


export default class FormController extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isValid: false,
            childList: []
        }

        this.checkRequired = this.checkRequired.bind(this);
        this.setupNewChildren = this.setupNewChildren.bind(this);
    }

    componentDidMount() {
        this.setupNewChildren();

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(typeof this.props.children !== 'undefined') {


            let loopableChildren = this.props.children;
            if (typeof loopableChildren.length === 'undefined') {
                loopableChildren = [loopableChildren];
            }

            if (loopableChildren.length !== this.state.childList.length) {
                this.setupNewChildren();
            }

            for (let i = 0; i < this.state.childList.length; i++) {

                if (typeof this.state.childList[i].childDOMObject !== 'undefined') {
                    if (this.state.childList[i].childDOMObject.props.value !== loopableChildren[i].props.value) {
                        this.setupNewChildren();
                    } else if (this.state.childList[i]?.childDOMObject?.props?.validationMode !== loopableChildren[i]?.props.validationMode) {
                        this.setupNewChildren();
                    } else if (this.state.childList[i]?.childDOMObject?.props?.defaultSelected !== loopableChildren[i]?.props.defaultSelected) {

                        this.setupNewChildren();
                    }
                } else {
                    if (typeof this.state.childList[i].props !== 'undefined') {
                        if (typeof this.state.childList[i].props !== 'undefined') {

                            for (let [k, v] of Object.entries(this.state.childList[i].props)) {
                                if (v !== loopableChildren[i]?.props?.[k]) {
                                    this.setupNewChildren();
                                }
                            }
                            for (let [k, v] of Object.entries(this.state.childList[i].props)) {
                                if (v !== loopableChildren[i]?.props?.[k]) {
                                    this.setupNewChildren();
                                }
                            }

                        } else {

                        }


                    }
                }
            }

            let allValid = true;

            for (let child of this.state.childList) {

                if (typeof child.childDOMObject !== 'undefined') {
                    if (child.required === true && child.valid === false) {
                        allValid = false;
                    }
                }
            }
            if (allValid !== this.state.isValid) {
                this.setState({isValid: allValid});
                this.onValidityChanged(allValid);
            }
        }
    }


    onValidityChanged(validity) {
        if (typeof this.props.onValidityChanged !== 'undefined') {
            this.props.onValidityChanged(validity);
        } else {
            console.warn("FormController onValidityChanged not bound");
        }
    }

    setupNewChildren() {
        if(typeof this.props.children !== 'undefined') {


            let tmpChildren = [];
            let loopableChildren = this.props.children;


            if (typeof loopableChildren.length === 'undefined') {
                loopableChildren = [loopableChildren];
            }

            for (let i = 0; i < loopableChildren.length; i++) {

                let currentChild = loopableChildren[i];

                let validityFieldName;
                let childPropertyToCompare;


                if (typeof currentChild.type !== 'undefined' && (typeof currentChild.type.WrappedComponent !== 'undefined' || typeof currentChild.type === 'function')) {

                    //             if(typeof currentChild.type.WrappedComponent === 'undefined') {

                    if (typeof currentChild.props.title !== 'undefined') {
                        validityFieldName = "onValidityChange";
                        childPropertyToCompare = "title";

                    } else if (typeof currentChild.props.name !== 'undefined') {
                        validityFieldName = "onFieldValidityChanged"
                        childPropertyToCompare = "name";

                    } else {

                    }

                    let found = false;
                    for (let [k, v] of Object.entries(currentChild.props)) {
                        if (typeof v === "boolean") {
                            if (k === 'required') {
                                tmpChildren.push(new ValidRequiredChild(
                                    React.cloneElement(currentChild, {
                                        ...currentChild.props, [validityFieldName]: (validity) => {
                                            let prevChildren = [...this.state.childList];

                                            for (let j = 0; j < prevChildren.length; j++) {
                                                if (typeof prevChildren[j].childDOMObject !== 'undefined') {
                                                    if (typeof currentChild.props !== 'undefined') {
                                                        if (prevChildren[j].childDOMObject.props[childPropertyToCompare] === currentChild.props[childPropertyToCompare]) {
                                                            prevChildren[j].valid = validity;

                                                        }
                                                    }

                                                }
                                            }

                                            this.setState({childList: prevChildren}, () => {

                                            })
                                        }
                                    }), (this.state.childList.length > 0 ? this.state.childList.find(x =>
                                            //find where object prop name is same
                                            typeof x.childDOMObject !== 'undefined' && typeof currentChild.props !== 'undefined' && x.childDOMObject.props[childPropertyToCompare] === currentChild.props[childPropertyToCompare])
                                            ?.valid : false
                                    ))
                                )
                                found = true

                                break;
                            }
                        }
                    }

                    if (found === true) {

                    } else {

                        tmpChildren.push(React.cloneElement(currentChild));
                    }

                } else {
                    tmpChildren.push(React.cloneElement(currentChild));
                }
            }


            this.setState({childList: tmpChildren});

        }
    }

    checkRequired() {

    }

    render() {
        return (

            <div>
                {this.state.childList.map(child => {
                    if (typeof child.childDOMObject === 'undefined' && typeof child.type !== 'undefined') {
                        return child
                    } else {
                        return child.childDOMObject
                    }


                })}


            </div>
        )
    }
}