import Status from "./Status";
import Coordinate from "./Coordinate";
import {Destination} from "../Destination/Destination";

export class Site{
    /**
     *
     *
     * @private {Coordinate}
     */
    _coordinate;
    _coordinateId;
    _createdBy;
    _createdTime;
    _description;
    _modifiedBy;
    _modifiedTime;
    _nonce;
    _paveSiteEntityId;
    _paveSiteId;
    _responsibleName;
    _siteDestination;
    _siteEcoVision;
    _siteGuid;
    _siteId;
    _siteName;
    _siteRegion;
    _siteType;
    /**
     *
     *
     * @private {Status}
     */
    _status;


    constructor(coordinate, coordinateId, createdBy, createdTime, description, modifiedBy, modifiedTime, nonce, paveSiteEntityId, paveSiteId, responsibleName, siteDestination, siteEcoVision, siteGuid, siteId, siteName, siteRegion, siteType, status) {

        if(typeof coordinate["siteId"] !== 'undefined' || coordinate["siteId"]!==null){

            this._coordinate = ((typeof coordinate["coordinate"] !== 'undefined' && coordinate["coordinate"]!==null)?new Coordinate(coordinate["coordinate"]):null);
            this._coordinateId = coordinate["coordinateId"];
            this._createdBy = coordinate["createdBy"];
            this._createdTime = coordinate["createdTime"];
            this._description = coordinate["description"];
            this._modifiedBy = coordinate["modifiedBy"];
            this._modifiedTime = coordinate["modifiedTime"];
            this._nonce = coordinate["nonce"];
            this._paveSiteEntityId = coordinate["paveSiteEntityId"];
            this._paveSiteId = coordinate["paveSiteId"];
            this._responsibleName = coordinate["responsibleName"];
            this._siteDestination = (coordinate["siteDestination"]) ?coordinate["siteDestination"].map(dest=>new Destination(dest)):null;
            this._siteEcoVision = coordinate["siteEcoVision"];
            this._siteGuid = coordinate["siteGuid"];
            this._siteId = coordinate["siteId"];
            this._siteName = coordinate["siteName"];
            this._siteRegion = coordinate["siteRegion"];
            this._siteType = coordinate["siteType"];
            this._status = ((typeof coordinate["status"] !== 'undefined' && coordinate["status"]!==null)?new Status(coordinate["status"]):null);
        }else{
            console.log("2")
            this._coordinate = coordinate;
            this._coordinateId = coordinateId;
            this._createdBy = createdBy;
            this._createdTime = createdTime;
            this._description = description;
            this._modifiedBy = modifiedBy;
            this._modifiedTime = modifiedTime;
            this._nonce = nonce;
            this._paveSiteEntityId = paveSiteEntityId;
            this._paveSiteId = paveSiteId;
            this._responsibleName = responsibleName;
            this._siteDestination = siteDestination;
            this._siteEcoVision = siteEcoVision;
            this._siteGuid = siteGuid;
            this._siteId = siteId;
            this._siteName = siteName;
            this._siteRegion = siteRegion;
            this._siteType = siteType;
            this._status = status;
        }



    }


    set coordinate(value) {
        this._coordinate = value;
    }

    set coordinateId(value) {
        this._coordinateId = value;
    }

    set createdBy(value) {
        this._createdBy = value;
    }

    set createdTime(value) {
        this._createdTime = value;
    }

    set description(value) {
        this._description = value;
    }

    set modifiedBy(value) {
        this._modifiedBy = value;
    }

    set modifiedTime(value) {
        this._modifiedTime = value;
    }

    set nonce(value) {
        this._nonce = value;
    }

    set paveSiteEntityId(value) {
        this._paveSiteEntityId = value;
    }

    set paveSiteId(value) {
        this._paveSiteId = value;
    }

    set responsibleName(value) {
        this._responsibleName = value;
    }

    set siteDestination(value) {
        this._siteDestination = value;
    }

    set siteEcoVision(value) {
        this._siteEcoVision = value;
    }

    set siteGuid(value) {
        this._siteGuid = value;
    }

    set siteId(value) {
        this._siteId = value;
    }

    set siteName(value) {
        this._siteName = value;
    }

    set siteRegion(value) {
        this._siteRegion = value;
    }

    set siteType(value) {
        this._siteType = value;
    }

    set status(value) {
        this._status = value;
    }

    /**
     *
     *
     * @returns {Coordinate}
     */
   get coordinate() {
        return this._coordinate;
    }

    get coordinateId() {
        return this._coordinateId;
    }

    get createdBy() {
        return this._createdBy;
    }

    get createdTime() {
        return this._createdTime;
    }

    get description() {
        return this._description;
    }

    get modifiedBy() {
        return this._modifiedBy;
    }

    get modifiedTime() {
        return this._modifiedTime;
    }

    get nonce() {
        return this._nonce;
    }

    get paveSiteEntityId() {
        return this._paveSiteEntityId;
    }

    get paveSiteId() {
        return this._paveSiteId;
    }

    get responsibleName() {
        return this._responsibleName;
    }

    /**
     *
     *
     * @returns {SiteDestination[]}
     */
    get siteDestination() {
        return this._siteDestination;
    }

    get siteEcoVision() {
        return this._siteEcoVision;
    }

    get siteGuid() {
        return this._siteGuid;
    }

    get siteId() {
        return this._siteId;
    }

    get siteName() {
        return this._siteName;
    }

    get siteRegion() {
        return this._siteRegion;
    }

    get siteType() {
        return this._siteType;
    }

    /**
     *
     * @returns {Status}
     */
    get status() {
        return this._status;
    }
}