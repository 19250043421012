import axios from "axios";
import {msalInstance, handleApiToken} from "../index";

async function getTokenSilently() {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length > 0) {
        try {
            const silentResult = await msalInstance.acquireTokenSilent({
                scopes: [process.env.REACT_APP_NASTA_API_SCOPE],
                account: accounts[0],
            });
            handleApiToken(silentResult);
            return silentResult.accessToken;
        } catch (error) {
            console.error('Token acquisition failed', error);
            throw error;
        }
    } else {
        throw new Error("No accounts detected");
    }
}

function createAxios(contentType = 'application/json', token) {
    return axios.create({
        baseURL: process.env['REACT_APP_NASTA_API_BASE_URL'],
        timeout: 0,
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": contentType
        }
    });
}

async function makeApiRequest(method, endpoint, data = null) {
    const accessToken = await getTokenSilently();
    try {
        const axiosInstance = createAxios(data instanceof FormData ? 'multipart/form-data' : 'application/json', accessToken);
        const config = method === 'get' ? { params: data } : data;
        const response = await axiosInstance[method](endpoint, config);
        return response;
    } catch (error) {
        console.error(error);
        return error; // Rethrow to allow caller to handle
    }
}

export async function InsertCountsFromCsvFile(file) {
    const formData = new FormData();
    formData.append("file", file);
    const result = await makeApiRequest('post', '/counts/ImportCsvToDatabase', formData);
    return result.data;
}

export async function GetCodeClasses() {
    const response = await makeApiRequest('get', '/CodesClass/GetCodeClasses');
    return response.data;
}

export async function GetTotalCountOfCounts(flowId) {
    const response = await makeApiRequest('get', '/Counts/GetTotalCountOfCounts?id='+flowId);
    return response.data;
}

export async function GetCountsByFlowId(flowId) {
    const response = await makeApiRequest('get', '/Counts/GetCountByFlowId?id='+flowId);
    return response.data;
}

export async function GetCounts() {
    const response = await makeApiRequest('get', '/Counts');
    return response.data;
}

export async function InsertCount(countData) {
    return makeApiRequest('post', '/Counts', countData);
}

export async function GetCount(countId) {
    return makeApiRequest('get', '/Counts/' + countId);
}

export async function DeleteCount(countId) {
    return makeApiRequest('delete', '/Counts/'+ countId);
}

export async function DeleteCounts(countsList) {
    return makeApiRequest('post', '/Counts/DeleteCountsList', countsList);
}

export async function GetDestination(destinationId) {
    return makeApiRequest('get', '/Destinations/'+ destinationId);
}

export async function GetDestinations() {
    const response = await makeApiRequest('get', '/Destinations');
    return response.data;
}

export async function UpdateDestination(destinationId, updateModel) {
    return makeApiRequest('put', '/Destinations/'+ destinationId, updateModel);
}

export async function DeleteDestination(destinationId) {
    return makeApiRequest('delete', '/Destinations/'+ destinationId);
}

export async function InsertDestination(destinationData) {
    return makeApiRequest('post', '/Destinations', destinationData);
}

export async function DestinationDeleteAllowed(destinationId) {
    return makeApiRequest('get', '/Destinations/DeleteAllowed/'+ destinationId);
}

export async function InserSite(siteData) {
    return makeApiRequest('post', '/Sites', siteData);
}

export async function UpdateSite(siteId, updateModel) {
    return makeApiRequest('put', '/Sites/'+ siteId, updateModel);
}

export async function SiteDeleteAllowed(siteId) {
    return makeApiRequest('get', '/Sites/DeleteAllowed/'+ siteId);
}

export async function  SiteDestinationsDeleteAllowed(destinationIds, siteId) {
    let requestObject = {
        "destinationIds":destinationIds,
        "siteId":parseInt(siteId)
    }
    return makeApiRequest('post', '/Sites/SiteDestinations/DeleteAllowed', requestObject);
}

export async function DeleteSite(siteId) {
    return makeApiRequest('delete', '/Sites/'+ siteId);
}

export async function GetUserCreatedSites() {
    return makeApiRequest('get', '/Sites/GetUserCreatedSites');
}

export async function GetUserCreatedDestinations() {
    return makeApiRequest('get', '/Destinations/GetUserCreatedDestinations');
}

export async function GetUserCreatedSite(siteId) {
    return makeApiRequest('get', '/Sites/GetUserCreatedSite/'+ siteId);
}

export async function GetUserCreatedDestination(destinationId) {
    return makeApiRequest('get', '/Destinations/GetUserCreatedDestination/'+ destinationId);
}

export async function GetCounties() {
    return makeApiRequest('get', '/Map/GetCounties');
}

export async function GetMunicipalities() {
    return makeApiRequest('get', '/Map/GetMunicipalities');
}

export async function GetSites() {
    return makeApiRequest('get', '/Sites');
}

export async function GetSitesMinimumData() {
    return makeApiRequest('get', '/MinData');
}

export async function GetSite(siteId) {
    return makeApiRequest('get', '/Sites/'+ siteId);
}

export async function GetSitesByDestinationId(destinationId) {
    return makeApiRequest('get', '/Sites/sitesByDestinationId/'+ destinationId);
}

export async function GetFlows() {
    return makeApiRequest('get', '/Flow/');
}

export async function GetFlowsBySiteCount() {
    return makeApiRequest('get', '/FlowsBySiteCount');
}

export async function GetFlow(flowId) {
    return makeApiRequest('get', '/Flow/'+ flowId);
}

export async function GetFlowsBySiteId(siteId) {
    return makeApiRequest('get', '/Flow/BySiteId/'+ siteId);
}

export async function DeleteFlow(flowId) {
    return makeApiRequest('delete', '/Flow/' + flowId);
}

export async function UpdateFlow(flowId, flow) {
    return makeApiRequest('put', '/Flow/' + flowId, flow);
}

export async function InsertFlow(flow) {
    return makeApiRequest('post', '/Flow', flow);
}

export async function GetRegionalUnits() {
    return makeApiRequest('get', '/Map/GetRegionalUnits');
}

export async function GetParkDistricts() {
    return makeApiRequest('get', '/Map/GetParkDistricts');
}

export async function GetEntities() {
    return makeApiRequest('get', '/Map/GetEntities');
}

let landUseUnitCache;
export async function GetLandUseUnits() {

    if(typeof landUseUnitCache !== 'undefined'){
        return await landUseUnitCache
    }
    return makeApiRequest('get', '/Map/GetLandUseUnits');
}

export async function GetLandUseUnitsData() {
    return makeApiRequest('get', '/Map/GetLandUseUnitIdAndNameList');
}

export async function InsertCountingRule(countingRule) {
    return makeApiRequest('post', '/CountingRules', countingRule);
}

export async function GetCountingRules() {
    return makeApiRequest('get', '/CountingRules/');
}

export async function GetCountingsCountByDestination() {
    return makeApiRequest('get', '/CountingCountByDestination');
}
  
export async function GetCountingRulesForList() {
    return makeApiRequest('get', '/CountingRulesForList');
}
  
export async function GetPagedCountingRulesForList(ids) {
    const queryString = `ids=${ids}`;
    return makeApiRequest('get', `/GetPagedCountingRulesForList?${queryString}`);
}

export async function GetCountingRuleCountByDestination() {
    return makeApiRequest('get', '/CountingRuleCountByDestination');
}

export async function UpdateCountingRule(countingRuleId, updateModel) {
    return makeApiRequest('put', '/CountingRules/'+ countingRuleId, updateModel);
}

export async function GetCountingRule(countingRuleId) {
    return makeApiRequest('get', '/CountingRules/'+ countingRuleId);
}

export async function IsCountingRuleInUse(countingRuleId) {
    return makeApiRequest('get', '/CountingRules/CheckIsRuleInUse/'+ countingRuleId);
}

export async function GetActiveCountingRulesByDestinationId(destinationId) {
    return makeApiRequest('get', '/CountingRules/AllActiveByDestinationId/'+ destinationId);
}

export async function  DeleteCountingRule(countingRuleId) {
    return makeApiRequest('delete', '/CountingRules/'+ countingRuleId);
}

export async function  GetCountingRulesByDestinationId(destinationId) {
    return makeApiRequest('get', '/CountingRules/AllByDestinationId/'+ destinationId);
}

export async function  GetCountings() {
    return makeApiRequest('get', '/Counting/');
}

export async function  GetCountingsForList() {
    return makeApiRequest('get', '/CountingsForList');
}
 
export async function  GetPagedCountingsForList(ids) {
    const queryString = `ids=${ids}`;
    return makeApiRequest('get', `/PagedCountingsForList?${queryString}`);
}
 
export async function ExecuteCounting(countingData) {
    return makeApiRequest('post', '/Counting', countingData);
}

export async function GetCountingById(countingId) {
    return makeApiRequest('get', '/Counting/' + countingId);
}
 
export async function GetCountingsByDestinationId(destinationId) {
    return makeApiRequest('get', '/Counting/ByDestinationId/' + destinationId);
}

export async function GetNextCountingStartDate(countingRuleId) {
    return makeApiRequest('get', '/Counting/NextCountingStartingDateByCountingRuleId/' + countingRuleId);
}

export async function DeleteCounting(deleteData) {
    return makeApiRequest('post', '/Counting/HandleCountingsRemoval', deleteData);
}

export async function PublishCountings(publishData) {
    return makeApiRequest('post', '/Counting/PublishCountings', publishData);
}

export async function UnpublishCountings(unpublishData) {
    return makeApiRequest('post', '/Counting/UnpublishCountings', unpublishData);
} 

export async function IsCountDeletionAllowed(flowId, startTime, endTime) {
    const queryString = `flowId=${flowId}&startTime=${startTime}&endTime=${endTime}`;
    return makeApiRequest('get', `/Counts/IsCountDeletionAllowed?${queryString}`);
}

