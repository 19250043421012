import { createSlice } from '@reduxjs/toolkit'

export const mapCommonsSelector = createSlice({
  name: 'mapCommonsSelector',
  initialState: {
    isOpen: false,
    clearAll: false,
    map:{
        mapSelection: 'taustakartta',
        regionalUnits: [],
        parkDistricts: [],
        entities: [],
        landUseUnits: [],
        counties: [],
        municipalities: [],
    }
  },
  reducers: {
    changeBasemap: (state, action) => {
        state.map.mapSelection = action.payload;
    },
    changeBasemapCommonsIsOpen: (state) => {
        state.isOpen = !state.isOpen;
    },
    updateRegionalUnits: (state, action) => {
        state.map.regionalUnits = action.payload;
    },
    updateParkDistricts: (state, action) => {
        state.map.parkDistricts = action.payload;
    },
    updateEntities: (state, action) => {
        state.map.entities = action.payload;
    },
    updateLandUseUnits: (state, action) => {
        state.map.landUseUnits = action.payload;
    },
    updateCounties: (state, action) => {
        state.map.counties = action.payload;
    },
    updateMunicipalities: (state, action) => {
        state.map.municipalities = action.payload;
    },
    changeClearAllFlag: (state, action) => {
        state.clearAll = action.payload;
      },
  },
})

// Action creators are generated for each case reducer function
export const {changeBasemap, changeBasemapCommonsIsOpen, updateCounties, updateEntities, updateLandUseUnits, updateMunicipalities,updateParkDistricts, updateRegionalUnits, changeClearAllFlag} = mapCommonsSelector.actions

export default mapCommonsSelector.reducer