import React from "react";
import './MultiViewHandler.css'
import ViewSelector from "../ViewSelector";
import {useTranslation, withTranslation} from "react-i18next";
import ToastManager from "../ToastManager";
import {Link, useNavigate, useParams, useLocation} from "react-router-dom";
import {useMsal} from "@azure/msal-react";

import maps_fill from '../icons/maps_fill.svg';
import list_bullets from '../icons/List Bullets 1.svg';
import add_fill from '../icons/add_fill.svg';
import data_file_bars_clock from '../icons/Data File Bars Clock.svg';
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import UploadDialog from "../widgets/CsvUploadDialog/CsvUploadDialog";
import siteIcon from '../icons/style-two-pin-marker_fill.svg';
import destinationIcon from '../icons/Property 1=Filled-4.svg';
import waveIcon from '../icons/Property 1=Filled-5.svg';
import countingRuleIcon from '../icons/Property 1=Filled-3.svg';
import countingIcon from '../icons/Property 1=Filled-2.svg';
import bin_fill from '../icons/bin_fill.svg';
import mark_ready_icon from '../icons/share-external-link-1_fill.svg';

const withRouter = WrappedComponent => props => {
    const params = useParams();
    const {instance} = useMsal();
    const navigate = useNavigate();
    const location = useLocation();

    const {t} = useTranslation('multiViewHandler', 'common')

    return (
        <WrappedComponent
            t={t}
            {...props}
            {...{instance, /* other injected props */}}
            params={params}
            location={location}
            navigate={navigate}
        />
    );
};


class MultiViewHandler extends React.Component {
    nWise = 2;
    viewNames = [];

    constructor(props) {
        super(props);

        this.state = {
            addButtonPressed: false,
            removeButtonPressed: false,
            markReadyButtonPressed: false,
            additionalAttributes: props.additionalAttributes,
            boundData: [],
            swapped: false,
            screenWidth: window.innerWidth,
            selectedView: 0,
            toastMessage: {type: "Success", message: "Success", visible: false},
            showAddTooling: false,
            showRemoveTooling: false,
            showMarkReadyTooling: false,
            likelyTargetUrl: null,
            shouldShow: 0,
            toolbarButtons: []
        }
        this.transportData = this.transportData.bind(this);
        this.findAndRemoveTitle = this.findAndRemoveTitle.bind(this);

        this.setToastMessage = this.setToastMessage.bind(this);
        this.changeToastVisibility = this.changeToastVisibility.bind(this);
        this.updateAdditionalAttrs = this.updateAdditionalAttrs.bind(this);
        this.updateRemoveAdditionalAttrs = this.updateRemoveAdditionalAttrs.bind(this);
        this.updateMarkReadyAdditionalAttrs = this.updateMarkReadyAdditionalAttrs.bind(this);

        this.updateAdditionalAttrs();
        this.updateRemoveAdditionalAttrs();
        this.updateMarkReadyAdditionalAttrs();

    }

    updateAdditionalAttrs() {
        if (typeof this.props.nWise !== 'undefined') {
            this.nWise = this.props.nWise;
        }
        let tempAttrs = this.props.additionalAttributes;
        for (let i = 0; i < tempAttrs.length; i++) {
            tempAttrs[i]["onExportData"] = (e) => this.transportData(e, i);

            if (typeof tempAttrs[i]["boundData"] === "undefined") {
                tempAttrs[i]["boundData"] = {};
            }
            tempAttrs[i]["onSubmit"] = this.setToastMessage;

            //    tempAttrs[i]["visible"] = this.state.swapped;


            tempAttrs[i]["showAddTooling"] = this.state.showAddTooling;
            tempAttrs[i]["addButtonPressed"] = this.state.addButtonPressed;
        }
        this.setState({additionalAttributes: tempAttrs})


        if (typeof this.props.viewNames === 'undefined') {
            throw new Error("viewNames props attribute required for MultiViewHandler");
        } else {
            this.viewNames = this.props.viewNames;
        }

        this.setState({addButtonPressed: false})
    }

    updateRemoveAdditionalAttrs() {
        if (typeof this.props.nWise !== 'undefined') {
            this.nWise = this.props.nWise;
        }
        let tempAttrs = this.props.additionalAttributes;
        for (let i = 0; i < tempAttrs.length; i++) {
            tempAttrs[i]["onExportData"] = (e) => this.transportData(e, i);

            if (typeof tempAttrs[i]["boundData"] === "undefined") {
                tempAttrs[i]["boundData"] = {};
            }
            tempAttrs[i]["onSubmit"] = this.setToastMessage;

            //    tempAttrs[i]["visible"] = this.state.swapped;

            tempAttrs[i]["showRemoveTooling"] = this.state.showRemoveTooling;
            tempAttrs[i]["removeButtonPressed"] = this.state.removeButtonPressed;
        }

        if (typeof this.props.viewNames === 'undefined') {
            throw new Error("viewNames props attribute required for MultiViewHandler");
        } else {
            this.viewNames = this.props.viewNames;
        }

        this.setState({additionalAttributes: tempAttrs, removeButtonPressed: false})
    }

    updateMarkReadyAdditionalAttrs() {
        if (typeof this.props.nWise !== 'undefined') {
            this.nWise = this.props.nWise;
        }
        let tempAttrs = this.props.additionalAttributes;
        for (let i = 0; i < tempAttrs.length; i++) {
            tempAttrs[i]["onExportData"] = (e) => this.transportData(e, i);

            if (typeof tempAttrs[i]["boundData"] === "undefined") {
                tempAttrs[i]["boundData"] = {};
            }
            tempAttrs[i]["onSubmit"] = this.setToastMessage;

            //    tempAttrs[i]["visible"] = this.state.swapped;

            tempAttrs[i]["showMarkReadyTooling"] = this.state.showMarkReadyTooling;
            tempAttrs[i]["markReadyButtonPressed"] = this.state.markReadyButtonPressed;
        }

        if (typeof this.props.viewNames === 'undefined') {
            throw new Error("viewNames props attribute required for MultiViewHandler");
        } else {
            this.viewNames = this.props.viewNames;
        }

        this.setState({additionalAttributes: tempAttrs, markReadyButtonPressed: false})
    }


    iconGenerator(props){
        switch (props.iconName) {
            case "site":
                return (<img style={{width:"0.75em", filter: ""}} src={siteIcon} alt={""}/>)

            case "destination":
                return (<img style={{width:"0.75em",filter: ""}} src={destinationIcon} alt={""}/>)

            case "flow":
                return (<img style={{width:"0.75em",filter: ""}} src={waveIcon} alt={""}/>)
            case "countingRule":
                return (<img style={{width:"0.75em",filter: ""}} src={countingRuleIcon} alt={""}/>)
            case "counting":
                return (<img style={{width:"0.75em",filter: ""}} src={countingIcon} alt={""}/>)
            default:
                break;
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {

        if(typeof this.props.toolbarButtons !== 'undefined'){
            if(this.props.toolbarButtons.length!==this.state.toolbarButtons.length){
                this.setState({toolbarButtons: this.props.toolbarButtons});
            }{
                for (const toolbarButton of this.props.toolbarButtons) {
                    if(!this.state.toolbarButtons.includes(toolbarButton)){
                        this.setState({toolbarButtons: this.props.toolbarButtons});
                    }
                }
            }
        }

        let tmpShouldShow = 0;
        let tmpAttrs = this.props.additionalAttributes

        for (let i = 0; i < tmpAttrs.length; i++) {


            if (typeof tmpAttrs[i]["boundData"] !== 'undefined') {

                if (typeof tmpAttrs[i]["boundData"]["destinationName"] !== 'undefined' || (tmpAttrs[i].addButtonPressed === true && window.location.href.includes("destinations"))) {
                    tmpShouldShow = 1;
                }

                if (typeof tmpAttrs[i]["boundData"]["siteName"] !== 'undefined' || (tmpAttrs[i].addButtonPressed === true && window.location.href.includes("sites"))) {
                    tmpShouldShow = 2;
                }
            }


        }

        for (let i = 0; i < this.props.viewNames.length; i++) {
            if (this.props.viewNames[i] !== this.viewNames[i]) {
                this.updateAdditionalAttrs();
                this.setState({swapped: false})
            }
        }
        if (this.props.titleStack[this.props.titleStack - 1] !== this.props.t(this.props.pageHeader)) {
            this.updateTitleStack();
        }

        if (tmpShouldShow !== this.state.shouldShow) {
            this.setState({shouldShow: tmpShouldShow})
        }

        if(this.state.removeButtonPressed !== prevState.removeButtonPressed){
            this.updateRemoveAdditionalAttrs();
        }

        if(this.state.markReadyButtonPressed !== prevState.markReadyButtonPressed){
            this.updateMarkReadyAdditionalAttrs();
        }

    }

    componentDidMount() {
        if(typeof this.props.toolbarButtons !== 'undefined'){

            this.setState({toolbarButtons: this.props.toolbarButtons});

        }

        window.addEventListener('resize', () => {
            this.setState({
                screenWidth: window.innerWidth
            });

        });
        this.updateTitleStack();
    }

    updateTitleStack() {
        let tmpTitle = this.props.titleStack;
        tmpTitle.push(this.props.t(this.props.pageHeader));
        this.props.setTitleStack(tmpTitle);
    }

    checkAddToolRestrictions() {
        if (typeof this.props.location !== 'undefined') {
            if (this.props.location.pathname.startsWith('/destinations') && localStorage.getItem("user-role") === "Nasta.Administrator") {
                return false;
            }
            if (this.props.location.pathname.startsWith('/sites') && (localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser")) {
                return false;
            }
            if (this.props.location.pathname.startsWith('/flow') && (localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser")) {
                return false;
            }
            if (this.props.location.pathname.startsWith('/counting/') && (localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser")) {
                return false;
            }
            if (this.props.location.pathname.startsWith('/countingRule/') && (localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser")) {
                return false;
            }
            if (this.props.location.pathname.startsWith('/count/') && (localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser" || localStorage.getItem("user-role") === "Nasta.Recorder")) {
                return false;
            }
        }
        return true;
    }



    checkRemoveToolRestrictions() {

        if (typeof this.props.location !== 'undefined') {

            if (this.props.location.pathname.startsWith('/count/list') &&( localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser" || localStorage.getItem("user-role") === "Nasta.Recorder"  )) {
                return false;
            }
            if (this.props.location.pathname.startsWith('/counting') && (localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser")) {
                return false;
            }


        }
        return true;
    }

    checkMarkReadyToolRestrictions() {

        if (typeof this.props.location !== 'undefined') {

            if (this.props.location.pathname.startsWith('/counting') && (localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser")) {
                return false;
            }
        }
        return true;
    }

    componentWillUnmount() {
        this.findAndRemoveTitle();
    }

    findAndRemoveTitle() {
        for (let i = 0; i < this.props.titleStack.length; i++) {
            if (this.props.titleStack[i] === this.props.t(this.props.pageHeader)) {
                let tmpTitleStack = this.props.titleStack.splice(i, 1)
                this.props.setTitleStack(tmpTitleStack);
            }
        }
    }

    transportData(data, fromWhatIndex) {
        let tempAttrs = this.state.additionalAttributes;


        for (const [key, value] of Object.entries(this.props.dataBindPathways)) {
            if (parseInt(key) === fromWhatIndex) {
                if (typeof value.length === 'undefined') {
                    tempAttrs[value]["boundData"] = data;

                } else {
                    for (const targetValue of value) {
                        tempAttrs[targetValue]["boundData"] = data;
                    }
                }
            }
        }
        this.setState({additionalAttributes: tempAttrs, swapped: true})
    }

    setToastMessage(type, message) {
        this.setState({toastMessage: {type: type, message: message, visible: true}});
    }

    changeToastVisibility(visibility) {
        this.setState({toastMessage: {visible: visibility}});
    }

    render() {
        let {t} = this.props;

        let toolbarButtons = this.state.toolbarButtons;
        localStorage.setItem("sessionRefresh", JSON.stringify(Date.now()))
        let likelyTargetUrl = null;

        for (let target of ["flow", "destination", "site", "count", "countingRule", "counting", "report"]) {
            if (window.location.href.includes(target)) {
                likelyTargetUrl = target;
            }
        }

        return (

            <>
                <ToastManager onVisibilityChange={this.changeToastVisibility} visible={this.state.toastMessage.visible}
                              message={this.state.toastMessage}/>


                    <UploadDialog
                        showModal={this.state.showUploadDialog}
                        title={this.props.t("uploadDialog:choose_file")}

                        onModalCancel={()=>this.setState({showUploadDialog:false})}
                        onModalContinue={()=>this.setState({showUploadDialog:false})}

                    />



                <div className="header-grid" >

                    <h1 style={{wordWrap: "break-word"}} id={"mainContent"} className="page-name-cell">
                        <this.iconGenerator iconName={this.props?.iconName} /> {this.props.pageHeader !== 'main_page_header' && t(this.props.pageHeader)}</h1>
                    {this.props.pageHeader &&
                        <div className="button-cell" >
                            {(likelyTargetUrl !== null) && <>
                            {toolbarButtons.map(toolbarButton=>{


                                if(toolbarButton==="map") return(
                                <Link
                                    to={"/" + likelyTargetUrl + "s/add" + (window.location.href.includes("?") ? "?" + window.location.href.split("?")[1] : '')}>
                                    {/*
                                <span data-tooltip-id={"tooltip-" + t("button_map_view")}
                                      data-tooltip-html={t("button_map_view")}
                                      data-tooltip-delay-hide={1000}>
*/}

                                    <Tooltip title={t("button_map_view")}>
                                        <span>

                                    <Button alt={t("common:map_mode")}  size={"small"}  variant={"contained"}  aria-label={t("common:map_mode")}
                                            style={{border: (window.location.href.includes("/add") ? '2px solid #0069B3' : '')}}>
                                    <div className="div-to-center-image">
                                        <img alt="" style={{filter:"invert(1)"}} src={maps_fill}></img>
                                    </div>
                                </Button>

                                            </span>
                                    </Tooltip>

                                </Link>)


                                if(toolbarButton==="list") return ( <Link
                                    to={"/" + likelyTargetUrl + "/list/all/1" + (window.location.href.includes("?") ? "?" + window.location.href.split("?")[1] : '')}>
                                <span data-tooltip-id={"tooltip-" + t("button_list_view")}
                                      data-tooltip-html={t("button_list_view")}
                                      data-tooltip-delay-hide={1000}>
{/*                                <Tooltip style={{zIndex: 50000, fontSize: 'small'}} noArrow
                                         id={"tooltip-" + t("button_list_view")}/>*/}


                                    <Tooltip title={t("button_list_view")}>
                                        <span>
                                    <Button size={"small"}  variant={"contained"} aria-label={t("common:list_mode")}
                                            style={{border: (window.location.href.includes("/list") ? '2px solid #0069B3' : '')}}>
                                        <div className="div-to-center-image">
                                            <img alt={""} style={{filter:"invert(1)"}} src={list_bullets}/>
                                        </div>
                                    </Button>
                                            </span>
                                        </Tooltip>
                                </span>
                                </Link>)


                                 if(toolbarButton==="add") return (<>


                                    <Tooltip title={t("addtools_button_tooltip")}>

                                   <span>
                                        <Button size={"small"}  variant={"contained"}
                                                data-tooltip-id={"tooltip-" + this.props.t("addtools_button_tooltip")}
                                                data-tooltip-html={t('addtools_button_tooltip')}
                                                data-tooltip-delay-hide={1000}
                                                aria-label={this.props.t("common:add")}
                                                disabled={this.checkAddToolRestrictions()}
                                                onClick={() => {
                                                    this.setState({
                                                        showAddTooling: !this.state.showAddTooling,
                                                        addButtonPressed: true
                                                    }, this.updateAdditionalAttrs)
                                                }}
                                        >
                                            <div className="div-to-center-image"><img alt={""} style={{filter:"invert(1)"}} src={add_fill}/></div>
                                        </Button>
                                       </span>
                                    </Tooltip>
                                    {/*                                        <Tooltip style={{zIndex: 50000, fontSize: 'small'}} noArrow
                                                 id={"tooltip-" + this.props.t("addtools_button_tooltip")}/>*/}
                                </>)

                                 if(toolbarButton==="csv") return (<>
                                    <Tooltip title={t("import_csv")}>

                                        <span>


                                        <Button size={"small"}
                                                type={"file"}
                                                variant={"contained"}
                                                disabled={(localStorage.getItem("user-role") === "Nasta.Administrator" || localStorage.getItem("user-role") === "Nasta.ResponsibleUser") ? false : true}
                                                data-tooltip-id={"tooltip-" + t("import_csv")}
                                                data-tooltip-html={t('import_csv')}
                                                data-tooltip-delay-hide={1000}
                                                aria-label={t("import_csv")}
                                                onClick={() => {
                                                    this.setState({showUploadDialog: true});
                                                }}
                                        >
                                            <div className="div-to-center-image"><img alt={""} style={{filter:"invert(1)"}} src={data_file_bars_clock}/></div>
                                        </Button>
                                            </span>
                                    </Tooltip>
                                    {/*                                        <Tooltip style={{zIndex: 50000, fontSize: 'small'}} noArrow
                                                 id={"tooltip-" + this.props.t("import_csv")}/>*/}
                                </>)


                                 if(toolbarButton==="remove")return (<>


                                    <Tooltip title={t("removetools_button_tooltip")}>

                                <span>
                                    <Button size={"small"}  variant={"contained"}
                                            data-tooltip-id={"tooltip-" + this.props.t("removetools_button_tooltip")}
                                            data-tooltip-html={t('removetools_button_tooltip')}
                                            data-tooltip-delay-hide={1000}
                                            aria-label={this.props.t("common:remove")}
                                            disabled={this.checkRemoveToolRestrictions()}
                                            onClick={() => {
                                                this.setState({
                                                    showRemoveTooling: !this.state.showRemoveTooling,
                                                    removeButtonPressed: true
                                                }, this.updateRemoveAdditionalAttrs)
                                            }}
                                    >
                                        <div className="div-to-center-image"><img alt={""} style={{filter:"invert(1)"}} src={bin_fill}/></div>
                                    </Button>
                                </span>
                                    </Tooltip>
                                    {/*                                        <Tooltip style={{zIndex: 50000, fontSize: 'small'}} noArrow
                                            id={"tooltip-" + this.props.t("addtools_button_tooltip")}/>*/}
                                </>)

                                if(toolbarButton==="markReady")return ( <>


                                    <Tooltip title={t("markReady_button")}>

                                    <span>
                                        <Button size={"small"}  variant={"contained"}
                                                data-tooltip-id={"tooltip-" + this.props.t("markReady_button")}
                                                data-tooltip-html={t('markReady_button')}
                                                data-tooltip-delay-hide={1000}
                                                aria-label={this.props.t("common:mark_ready")}
                                                disabled={this.checkMarkReadyToolRestrictions()}
                                                onClick={() => {
                                                    this.setState({
                                                        showMarkReadyTooling: !this.state.showMarkReadyTooling,
                                                        markReadyButtonPressed: true
                                                    }, this.updateMarkReadyAdditionalAttrs)
                                                }}
                                        >
                                            <div className="div-to-center-image"><img alt={""} style={{filter:"invert(1)"}} src={mark_ready_icon}/></div>
                                        </Button>
                                    </span>
                                    </Tooltip>
                                    {/*                                        <Tooltip style={{zIndex: 50000, fontSize: 'small'}} noArrow
                                                id={"tooltip-" + this.props.t("addtools_button_tooltip")}/>*/}
                                    </>
                                    )
                            })}
                            </>
                            }
                        </div>
                    }
                </div>
                <div className="container">
                    <div className="columns">

                        {(this.state.screenWidth > 639 || !this.state.swapped) &&
                            <div
                                className={"column " + (this.nWise === 4 ? "maxHeighter halfHeighter" : "") + " col-" + (this.state.swapped ? "6" : "12")}>
                                <ViewSelector onVisibilityChanged={(v) => {
                                    this.state.swapped !== v && this.setState({swapped: v})
                                }}
                                              visibile={this.state.additionalAttributes[0].visible}
                                              additionalAttributes={this.state.additionalAttributes[0]}


                                              currentlySelectedView={this.viewNames[0]}/>
                            </div>}
                        {this.state.swapped && this.state.shouldShow === 1 ?
                            <div
                                className={"column col-6" + (this.state.showAddTooling ? "maxHeighter-with-buttons " : "maxHeighter ")}>
                                <ViewSelector onVisibilityChanged={(v) => {
                                    if ((v[1] === true || typeof v[1] === 'undefined') && this.state.swapped !== v[0]) this.setState({swapped: v[0]});
                                    let tmpAdditionalAttributes = this.state.additionalAttributes;

                                    tmpAdditionalAttributes[1]["visible"] = v[0];
                                    this.setState({additionalAttributes: tmpAdditionalAttributes})
                                }}
                                              visible={true}
                                              additionalAttributes={this.state.additionalAttributes[1]}
                                              currentlySelectedView={this.viewNames[1]}/>
                            </div> : <></>}

                            {this.state.swapped && this.state.shouldShow === 2 ?
                            <div
                                className={"column col-6" + (this.state.showAddTooling ? "maxHeighter-with-buttons " : "maxHeighter ")}>
                                <ViewSelector onVisibilityChanged={(v) => {
                                    if ((v[1] === true || typeof v[1] === 'undefined') && this.state.swapped !== v[0]) this.setState({swapped: v[0]});
                                    let tmpAdditionalAttributes = this.state.additionalAttributes;

                                    tmpAdditionalAttributes[1]["visible"] = v[0];
                                    this.setState({additionalAttributes: tmpAdditionalAttributes})
                                }}
                                              visible={true}
                                              additionalAttributes={this.state.additionalAttributes[1]}
                                              currentlySelectedView={this.viewNames[2]}/>
                            </div> : <></>}

                    </div>

                </div>
            </>
        )
    }
}

export default withRouter(MultiViewHandler)
