import React from "react";
import {AxiosError} from "axios";
import {GetCodeClasses} from '../utils/NastaApiClient';
import {useTranslation} from 'react-i18next';
import {Link} from "react-router-dom";
import {getCodeText} from '../utils/CodeHelpers'

import { useSelector, useDispatch, useStore} from 'react-redux';
import * as codesSelector from '../mapViewOl/codesSelector';
import {IconButton, Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import UploadingNotification from "../widgets/UploadingNotification/UploadingNotification";
import moment from "moment-timezone/moment-timezone-utils";

const withRouter = WrappedComponent => props => {
    const {t} = useTranslation('addCalcPlace', 'common')
    const codes = useSelector((state) => state.codes);
    const dispatch = useDispatch();
    const storeHelper = useStore();


    return (
        <WrappedComponent
            t={t}
            {...props}
            dispatch={dispatch}
            storeHelper={storeHelper}
            codes={codes}
        />
    );
};

class ViewCalcPlace extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            choices: [             
            ],
            currentSearchValue: "",
            codeClassesLoaded: false,
            codeClasses: [],
            error: ""
        }

        this.genInfoRow=this.genInfoRow.bind(this);
    }

    async componentDidMount() {
        console.log('Site', this.props, this.state);
        try{
            if(!this.props.storeHelper.getState().codesSelector.codes.codeClassesLoaded){
                let response = await GetCodeClasses();

                if (response instanceof AxiosError) {
                    this.setState({error: response});
                    this.props.dispatch(codesSelector.changeCodeClassesLoaded(false));
                } else {
                    this.props.dispatch(codesSelector.fillCodeClasses(response));
                    this.props.dispatch(codesSelector.changeCodeClassesLoaded(true));
                }
            }
        }
        catch(error){
            console.log(error);
        }
    }

    genInfoRow(props) {
        let {t} = this.props;
        let {name, tooltip, inputValue, valueIfPresent} = props

        return (
            <dl className={"column col-12"}>
                <div className={"columns"} style={{ borderBottom: "2px solid #D8D8D8"}}>
                    <dt className={"column col-6"}>
                        {t(name)}
                        {(typeof tooltip!=='undefined' && t(tooltip)!==null && t(tooltip)!=='') &&
                        <Tooltip title={t(tooltip)}>
                            <IconButton>
                                <InfoIcon/>

                            </IconButton>
                        </Tooltip>
                        }
                        :
                    </dt>

                    <dd style={{wordWrap:"break-word"}} className={"column col-6"}>
                        {typeof props.linkTo !== 'undefined' ? <Link to={props.linkTo}>
                                {typeof inputValue === 'undefined' ? '' : (typeof valueIfPresent !== 'undefined' ? valueIfPresent : inputValue)}
                            </Link> :
                            typeof inputValue === 'undefined' ? '' : (typeof valueIfPresent !== 'undefined' ? valueIfPresent : inputValue)
                        }

                    </dd>

                </div>
            </dl>)
    }

    render() {
        let {t} = this.props;
        return (
            <>

                <UploadingNotification showModal={typeof this.props.siteName === 'undefined' || this.props.siteName===''} errors={this.state.errors} />
                {
                    (typeof this.props !== 'undefined') && <>
                        <this.genInfoRow name={t('site_id')} tooltip={t('tooltip_site_id')} inputValue={typeof this.props.siteId !== 'undefined' ? this.props.siteId : ''} />
                        <this.genInfoRow name={t('pave_site_id')} tooltip={t('tooltip_pave_site_id')} inputValue={typeof this.props.paVeSiteId !== 'undefined' ? this.props.paVeSiteId : ''} />
                        <this.genInfoRow name={t('ecovision_id')} tooltip={t('tooltip_ecovision_id')} inputValue={typeof this.props.ecoVisionId !== 'undefined' ?this.props.ecoVisionId : ''} />
                        <this.genInfoRow name={t('site_name')} tooltip={t('tooltip_site_name')} inputValue={typeof this.props.siteName !== 'undefined' ? this.props.siteName : ''} />

                        <b>{t('destination_id')}</b>:
                    <ul  style={{listStyle:"none"}}>
                        {typeof this.props.destinations !== 'undefined' && this.props.destinations.sort((a,b)=>a.Id<b.Id?1:-1).map((destination) =>
                            <li>{typeof destination !== 'undefined' ? destination.Id + ' ' + destination.Name : ''} </li>
                        )}
                    </ul>

                        <this.genInfoRow name={t('status')} tooltip={t('tooltip_status')} inputValue={typeof this.props.statusCodeId !== 'undefined' &&  typeof this.props.statusCodeClassId !== 'undefined' ? this.props.statusCodeId + ' ' + getCodeText(this.props.statusCodeClassId, this.props.statusCodeId, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses) : ''} />
                        <this.genInfoRow name={t('responsible_name')} tooltip={t('tooltip_responsible_name')} inputValue={typeof this.props.responsibleName !== 'undefined' ? this.props.responsibleName : ''} />
                        <this.genInfoRow name={t('additional_info')} tooltip={t('tooltip_additional_info')} inputValue={typeof this.props.description !== 'undefined' ? this.props.description : ''} />
                        <this.genInfoRow name={t('land_use_unit_id')} tooltip={t('tooltip_land_use_unit_id')} inputValue={typeof this.props.landUseUnitId !== 'undefined' && typeof this.props.landUseUnitName !== 'undefined' && this.props.landUseUnitId !== null && this.props.landUseUnitName !== null && this.props.landUseUnitId !== -1 && this.props.landUseUnitName !== '' ? this.props.landUseUnitId + ' ' + this.props.landUseUnitName : ''} />
                        <this.genInfoRow name={t('land_use_unit_type')} tooltip={t('tooltip_land_use_unit_type')} inputValue={typeof this.props.landUseUnitTypeCodeId !== 'undefined' && this.props.landUseUnitTypeCodeId !== null && this.props.landUseUnitTypeCodeId !== -1 && typeof this.props.landUseUnitTypeCodeClassId !== 'undefined' ? this.props.landUseUnitTypeCodeId + ' ' + getCodeText(this.props.landUseUnitTypeCodeClassId, this.props.landUseUnitTypeCodeId, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses) : ''} />
                        <this.genInfoRow name={t('entity')} tooltip={t('tooltip_status')} inputValue={typeof this.props.entityTypeCodeId !== 'undefined' && typeof this.props.entityCodeClassId !== 'undefined' ? this.props.entityTypeCodeId + ' ' + getCodeText(this.props.entityCodeClassId, this.props.entityTypeCodeId, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses) : ''} />
                        <this.genInfoRow name={t('park_district')} tooltip={t('tooltip_park_district')} inputValue={typeof this.props.parkDistrictTypeCodeId !== 'undefined' && typeof this.props.parkDistrictTypeCodeClassId !== 'undefined' ? this.props.parkDistrictTypeCodeId + ' ' + getCodeText(this.props.parkDistrictTypeCodeClassId, this.props.parkDistrictTypeCodeId, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses) : ''} />
                        <this.genInfoRow name={t('regional_unit')} tooltip={t('tooltip_regional_unit')} inputValue={typeof this.props.regionalUnitTypeCodeId !== 'undefined' && typeof this.props.regionalUnitClassCodeId !== 'undefined' ? this.props.regionalUnitTypeCodeId + ' ' + getCodeText(this.props.regionalUnitClassCodeId, this.props.regionalUnitTypeCodeId, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses):''} />
                        <this.genInfoRow name={t('municipality')} tooltip={t('tooltip_municipality')} inputValue= {typeof this.props.municipalityCodeId !== 'undefined' ? this.props.municipalityCodeId + ' ' + getCodeText(280, this.props.municipalityCodeId, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses) : ''} />
                        <this.genInfoRow name={t('county')} tooltip={t('tooltip_county')} inputValue= {typeof this.props.countyCodeId !== 'undefined' ? this.props.countyCodeId + ' ' + getCodeText(1005, this.props.countyCodeId, this.props.t('common:GMLLanguage'), this.props.storeHelper.getState().codesSelector.codes.codeClasses) : ''} />
                        <this.genInfoRow name={t('coordinate_X')} tooltip={t('tooltip_coordinate_X')} inputValue={typeof this.props.coordinate !== 'undefined' && typeof this.props.coordinate.x_Coordinate !== 'undefined' ? parseInt(this.props.coordinate.x_Coordinate) : ''} />
                        <this.genInfoRow name={t('coordinate_Y')} tooltip={t('tooltip_coordinate_Y')} inputValue={typeof this.props.coordinate !== 'undefined' && this.props.coordinate.y_Coordinate !== 'undefined'? parseInt(this.props.coordinate.y_Coordinate) : ''} />
                        <this.genInfoRow name={t('coordinate_N')} tooltip={t('tooltip_coordinate_N')} inputValue={typeof this.props.coordinate !== 'undefined' && this.props.coordinate.n_Coordinate !== 'undefined'? parseFloat(this.props.coordinate.n_Coordinate).toFixed(8) : ''}/>
                        <this.genInfoRow name={t('coordinate_E')} tooltip={t('tooltip_coordinate_E')} inputValue= {typeof this.props.coordinate !== 'undefined' && this.props.coordinate.e_Coordinate !== 'undefined'? parseFloat(this.props.coordinate.e_Coordinate).toFixed(8) : ''} />
                        <this.genInfoRow name={t('site_creator')} tooltip={t('tooltip_site_creator')} inputValue={typeof this.props.createdBy !== 'undefined' ? this.props.createdBy:''} />
                        <this.genInfoRow name={t('createdTime')} tooltip={t('tooltip_createdTime')} inputValue={typeof this.props.created !== 'undefined' ? moment(this.props.created).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(process.env.REACT_APP_NASTA_DATE_FORMAT) : ''} />
                        <this.genInfoRow name={t('site_modifier')} tooltip={t('tooltip_site_modifier')} inputValue={typeof this.props.modifiedBy !== 'undefined' ? this.props.modifiedBy : ''} />
                        <this.genInfoRow name={t('modifiedTime')} tooltip={t('tooltip_modifiedTime')} inputValue={typeof this.props.modified !== 'undefined' ? moment(this.props.modified).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(process.env.REACT_APP_NASTA_DATE_FORMAT) : ''} />

                    </>
                }


            </>
        )
    }
}

export default withRouter(ViewCalcPlace);
