import React from "react";
import {useTranslation} from "react-i18next";
import {
    Box,
    Modal,
    Button,
    Typography,
    useTheme,
   Paper,
   Divider,
   TableBody,
   TableContainer, 
   Table, 
   TableRow, 
   TableHead, 
   TableCell,
} from "@mui/material";
import {useNavigate, Link} from "react-router-dom";
import moment from "moment-timezone";
import 'moment/locale/fi'

const withRouter = WrappedComponent => props => {

    const {t} = useTranslation('overlappingCountingErrorDialog', 'common');
    const navigate = useNavigate();

    const theme = useTheme();
    return (
        <WrappedComponent
            t={t}
            {...props}
            theme={theme}
            navigate={navigate}
        />
    );
};


class OverlapCountingErrorDialog extends React.Component {
    selectedModalType = undefined;

    constructor(props) {
        super(props);
        
        this.state = {
            countings: props.overlappingCountings !== undefined ? props.overlappingCountings : [],
            title: props.currentTitle,
            message: props.currentMessage,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(JSON.stringify(prevProps.countings) !== JSON.stringify(this.props.countings)){
            this.setState({countings: this.props.countings});
        }
        if(prevProps.title !== this.props.title){
            this.setState({title: this.props.title});
        }
        if(prevProps.message !== this.props.message){
            this.setState({message: this.props.message});
        }
    }

    //We are in same page need to force reload
    handleLinkClick = (event, id) => {
        event.preventDefault(); // Prevent the default link behavior
        this.navigate('/counting/view/'+id);
        window.location.reload(); // Force a page reload
      };

    render() {
        const {t} = this.props
        return (
            <>

                <Modal
                    open={this.props.showModal}
                    onClose={() => this.props.onModalCancel()}
                    aria-labelledby={this.props.title}
                    aria-describedby={this.props.content}>
                    <Box className={"modal-box-container"} style={{width:'1000px'}}>

                        <Typography variant={"h6"}>{this.state.title}</Typography>
                        <Typography variant={"h6"}>{this.state.message}</Typography>
                        <Divider/>

                <TableContainer component={Paper} style={{ maxHeight: 500 }}>
                    <Table sx={{}} aria-label={t("countingErrorDialog:flow_list")}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {t("overlappingCountingErrorDialog:countingId_label")}
                                </TableCell>
                                <TableCell>
                                    {t("overlappingCountingErrorDialog:counting_beginTime")}
                                </TableCell>
                                <TableCell>
                                    {t("overlappingCountingErrorDialog:counting_endTime")}
                                </TableCell>
                                <TableCell>
                                    {t("overlappingCountingErrorDialog:counting_ready")}
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {this.state.countings.map(counting => {
                                return <>
                                    <TableRow key={counting.countingId}>
                                        <TableCell>
                                            {typeof counting.countingId !== 'undefined' ?
                                                <Link to={"/counting/view/" + counting.countingId} onClick={() => this.handleLinkClick(counting.countingId)}>
                                                    <td className="c-hand"> {counting.countingId}</td>
                                                </Link> : <td></td>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {moment(counting.countingBeginTime).tz("Europe/Helsinki").format(process.env.REACT_APP_NASTA_DATE_FORMAT)}
                                        </TableCell>
                                        <TableCell>
                                            {moment(counting.countingEndTime).tz("Europe/Helsinki").format(process.env.REACT_APP_NASTA_DATE_FORMAT)}
                                        </TableCell>
                                        <TableCell>
                                            {counting.ready === true ? t('overlappingCountingErrorDialog:ready') : t('overlappingCountingErrorDialog:not_ready')}
                                        </TableCell>
                                    </TableRow>
                                </>
                            })}
                        </TableBody>

                    </Table>
                </TableContainer>
                        

                        <Divider/>
                        <br/>
                            
                        <Button size={"small"} variant={"contained"} className="float-right"
                                onClick={() => this.props.onModalCancel()}>{t('overlappingCountingErrorDialog:close_button_text')}
                        </Button>
                    </Box>
                </Modal>


            </>
        )
    }
}

export default withRouter(OverlapCountingErrorDialog)