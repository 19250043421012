import { createSlice } from '@reduxjs/toolkit'

export const countingDropdownDataSelector = createSlice({
  name: 'countingSelector',
  initialState: {
    common:{
      destinations: [],
      destination:{},
      selectedCountingRule: {},
      countingRules: [],
      listViewDestinations: [],
      listViewDestination:{},
    }
  },
  reducers: {
    changeDestination: (state, action) => {
        state.common.destination = action.payload;
    },
    changeDestinationList: (state, action) => {
        state.common.destinations = action.payload;
    },
    changeCountingRule: (state, action) => {
        state.common.selectedCountingRule = action.payload;
    },
    changeCountingRuleList: (state, action) => {
        state.common.countingRules = action.payload;
    },
    changeListViewDestination: (state, action) => {
      state.common.listViewDestination = action.payload;
    },
    changeListViewDestinationList: (state, action) => {
      state.common.listViewDestinations = action.payload;
    },
    clearAll: (state) => {  
      state.common.destination = {};
      state.common.selectedCountingRule = {};
      state.common.countingRules = [];
    },
    clearRuleSelection: (state) => {  
      state.common.selectedCountingRule = {};
    },
    clearListView: (state) => {
      state.common.listViewDestination = {};
    }
  },
})

// Action creators are generated for each case reducer function
export const {changeDestination, changeDestinationList, clearAll, changeCountingRule, changeCountingRuleList, changeListViewDestination, changeListViewDestinationList, clearListView, clearRuleSelection} = countingDropdownDataSelector.actions

export default countingDropdownDataSelector.reducer